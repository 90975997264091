import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Image from "mui-image";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Text, { Bold, SemiBold } from "../App/Text";
import { Button, Grid, Stack } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { colors, regularButton } from "../../common/theme.mjs";
import {
    convertTimeStampToDate,
    getImageFullUrl,
    numberWithSpace,
} from "../../common/utils.mjs";
import useApplyJob from "../../api/mutation/useApplyJob";
import useGetCV from '../../api/query/useGetCV';
import SignIn from "../User/SignIn";
import { toast } from "react-toastify";
// icons
import CheckIcon from '@mui/icons-material/Check';
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";

const ItemDesktop = (props) => {
    const { isMobile, t, appLanguage, getAddressById, isLogin } = useContext(AppContext);
    const { item } = props;
    const [isHover, setIsHover] = useState(false);
    const [signInState, setSignInState] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    const cardStyle = {
        width: "100%",
        backgroundColor: isHover ? colors.WHITE : colors.NOT_ACTIVE_BLUE,
        cursor: "pointer",
        padding: "16px",
        paddingBottom: "25px",
        borderRadius: "10px",
        transition: "0.5s",
        boxShadow: isHover ? `0px 0px 20px rgba(192, 192, 192, 0.25)` : "",
        position: 'relative'
    };

    const navigate = useNavigate();

    function changeRoute(path) {
        navigate(path);
    }

    // query
    const {
        data: userCV,
    } = useGetCV();
    // mutation
    const {
        mutate: applyJob,
        isLoading: isApplingToJob
    } = useApplyJob();

    const handleSubmitJob = (event) => {
        event?.stopPropagation();

        if (isLogin) {
            if (userCV) {
                applyJob({
                    to: item?.job.id
                })
                toast.success(t('applied'))
            }
            else
                toast.info(t('noCV'))
        }
        else {
            setSignInState(true)
        }
    }

    const isAppliedJob = true

    return (
        <>
            {
                signInState &&
                <SignIn
                    open={signInState}
                    onClose={() => setSignInState(false)}
                />
            }
            <div
                style={{ ...cardStyle }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => changeRoute(`/view-job/${item?.job.uuid}`)}
            >
                {
                    item?.status !== "PENDING" ?
                        <ThumbUpAltIcon
                            sx={{
                                color: colors.GREEN_2,
                                position: 'absolute',
                                top: 15,
                                right: 15
                            }}
                            fontSize="large"
                        />
                        :
                        <img
                            style={{
                                position: 'absolute',
                                top: 20,
                                right: 20
                            }}
                            src={'/images/icon/received_plane.svg'}
                        />
                }
                <Stack spacing={2}>
                    <Grid container sx={{ width: "100%" }}>
                        <Grid item xs={2}>
                            <Image
                                showLoading={
                                    <Skeleton
                                        sx={{ width: "52px", height: "52px" }}
                                        variant={"rounded"}
                                        animation={"wave"}
                                    />
                                }
                                src={getImageFullUrl(item?.job.imageUrl)}
                                fit={"cover"}
                                style={{
                                    width: "52px",
                                    height: "52px",
                                    borderRadius: "6px",
                                }}
                                wrapperStyle={{ height: "52px", width: "52px" }}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <SemiBold
                                value={
                                    appLanguage === "ru" ? item?.job.category?.nameRu : item?.job.category?.name
                                }
                                sx={{ fontSize: "18px", fontWeight: 'bold' }}
                            />
                            <Stack
                                direction={"row"}
                                spacing={1}
                                alignItems={"center"}
                            >
                                <LocationOnIcon
                                    sx={{
                                        width: "18px",
                                        color: "custom.notActive",
                                    }}
                                />
                                <Text
                                    value={getAddressById(item?.job.locationId)}
                                    sx={{
                                        fontSize: "16px",
                                        color: "custom.notActive",
                                    }}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Bold
                        value={
                            appLanguage === "ru"
                                ? item?.job.professionRu
                                : item?.job.profession
                        }
                        sx={{ mt: 2, fontSize: "30px" }}
                    />

                    <Stack direction={"row"} alignItems={"center"} spacing={4}>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <BusinessCenterIcon
                                sx={{ width: "18px", color: "custom.notActive" }}
                            />
                            <Text
                                value={item?.job.workday_hours}
                                sx={{ fontSize: "16px", color: "custom.notActive" }}
                            />
                        </Stack>

                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <AccessTimeFilledIcon
                                sx={{ width: "18px", color: "custom.notActive" }}
                            />
                            <Text
                                value={convertTimeStampToDate(item?.job.createdAt, appLanguage)}
                                sx={{ fontSize: "16px", color: "custom.notActive" }}
                            />
                        </Stack>
                    </Stack>

                    <Text
                        value={
                            appLanguage ?
                                item?.job.conditionsRu
                                :
                                item?.job.conditions
                        }
                        sx={{
                            fontSize: "16px",
                            color: "custom.notActive",
                            height: "100%",
                        }}
                        className='oneLine'
                    />

                    <br />
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                            width: "100%",
                            mt: 2,
                        }}
                    >
                        <SemiBold
                            value={`${numberWithSpace(item?.job.salary)} TMT`}
                            sx={{ fontSize: "20px", color: "primary.main" }}
                        />
                        <Button
                            variant={"contained"}
                            color={isAppliedJob ? "success" : undefined}
                            sx={{
                                ...regularButton,
                                color: "#FFFFFF",
                                pointerEvents: isAppliedJob ? 'none' : undefined
                            }}
                            startIcon={
                                !isAppliedJob ?
                                    <img
                                        src="/images/icon/paper_plane.svg"
                                        width={15}
                                    />
                                    :
                                    <CheckIcon />
                            }
                            onClick={handleSubmitJob}
                            disabled={isApplingToJob}
                        >
                            {t("submit_job")}
                        </Button>
                    </Stack>
                </Stack>
            </div>
        </>
    );
};

export default ItemDesktop;
