import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LocalStorage from "./storage.js";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  ru: {
    translation: {
      home_page: "Главная",
      find_jobs: "Открытые вакансии",
      find_worker: "Ищущие работу",
      sign_up: "Регистрация",
      add_job: "Для работодателя",
      events: "Действия",
      favs: "Избранные",
      anceta: "Анкета",
      sign_in: "Войти",
      ru: "Русский",
      tm: "Türkmençe",
      lng: 'Русский',
      categories: "Категории",
      home_title: "Найти работу, Привлечь опытных специалистов",
      home_desc:
        "Работа найдется для каждого! Быстрый и надёжный способ достичь успеха. Здесь и сейчас.",
      start: "Начать",
      sponsors: "Нам доверяют",
      job_name: "Название вакансии",
      location: "Расположение",
      search: "Поиск",
      search_by_category: "Поиск по категориям",
      see_all: "Показать все",
      see_more: "Количество уведомлений",
      today_jobs: "Вакансии дня",
      more: "Подробнее",
      left_minute: " минуты назад",
      app_title: "Wakant - Скачай мобильное приложение",
      app_desc: 
      "Полнофункциональный портал вакансий доступен и на мобильном устройстве. Разместить вакансию и найти работу теперь просто и удобно с помощью мобильного приложения. Соискатели смогут легко подобрать подходящую вакансию и отправить свое резюме. Работодатели получат доступ к обширной аудитории и профессиональным услугам ведущих кадровых агентств.",
      immediately: "Офисные работы",
      state_1_title: "Сообщите, что ищете работу",
      state_1_desc:
        "Вы можете отправить свою ссылку для присоединения к работе, выбрав подходящую вам в уведомлениях.",
      state_2_title: "Заполните свое резюме",
      state_2_desc:
        "Если вы укажете подробную информацию о себе и своей профессии, работодателю будет проще вас выбрать.",
      state_3_title: "Работа",
      state_3_desc:
        "С легкостью найдите работу, которую вы ищете, и подайте заявку на работу по вашему выбору",
      user_comment: "Комментарии пользователей",
      user_comment_desc:
        "Тысячи сотрудников получают идеальную работу и вернитесь к нам!",
      contact: "Связаться",
      fullname: "Введите ваше имя",
      company: "Компания (необязательно)",
      email: "Ваша электронная почта",
      phone_number: "Номер телефона",
      your_message: "Расскажите нам о себе",
      our_address: "Адрес местоположения",
      our_address_value:
        " Наш адрес: г. Ашхабад, Копетдагский этрап, 62 Парахат 1/1",
      our_email: "Электронная почта",
      our_email_value: "wakant_info@gmail.com",
      number: "Контакты",
      our_numbers: "+993 00 000000,+993 00 000000",
      send: "Отправить",
      docs: "Нормативные документы",
      terms_of_use: "Пользовательское соглашение",
      privacy_policy: "Кнопка откликнутся",
      terms_of_comment: "Соглашение о комментариях",
      all_rights: "Copyright © {{value}} Все права защищены",
      mobile_apps: "Мобильное проложение",
      "home_contact_desc":
        "Ваши положительные отзывы о нас помогают другим людям быть уверенными в своем выборе профессии. Можете ли вы выделить 60 секунд, чтобы поделиться своим успехом? Мы будем вечно благодарны за вашу помощь.",

      //  JOBS PAGE
      jobb: "вакансии",
      av_jobs: "доступны сейчас",
      jobb_desc: "Выполните поиск по тому, что важно для вас, и найдите тот, который подходит именно вам.",
      per_filter: "Расширенный фильтр",
      page: "Страницы",
      all: "Все",
      category: "Категории",
      job_price: "Диапазон заработной платы",
      job_gender: "Ищете работу",
      job_firm: "Агентства",
      submit_job: "Откликнуться",
      filter: "Фильтр",
      sort: "организовать",
      man_women: "Мужской / женский",
      man: "Мужской",
      women: "Женщина",
      price_asc: "Цена по возрастанию",
      price_desc: "Цена по убыванию",
      newest_first: "Новички вперед",
      oldest_first: "Сначала старые",
      jobs_count: "Общее количество рабочих мест:",
      similarJobs: "Похожие вакансии",
      saveJob: "Сохранить объявление",
      requiredField: "Обязательное поле",
      //  JOBS PAGE




      // View job
      one_bt: "Сохранить",
      about_job: "О работе",
      title_category: "Категория:",
      title_job_req: "Ищущие работу:",
      title_submit: "Возраст:",
      title_time: "График работы:",
      title_number: "Номер телефона:",
      title_address: "Адрес:",
      title_email: "Электронной почты:",
      similar: "Похожие посты",
      owner: "Работодатель",
      phone: "Телефон",
      comments: "Комментарии",
      comment: "Комментария",
      // View job
      sponseredByText:
        "Developed by USAID",
      // ADD JOB
      job_times_saturday: "Суббота: ",
      job_times_sunday: "Воскресенье: ",
      job_mail: "Адрес электронной почты",
      job_name_add: "Название работы",
      job_hours: "Рабочее время: В течение недели",
      job_expreience: "Опыт работы",
      job_age: "Возраст применения",
      job_phone: "993778899",
      job_place_document: "Требуемый адрес",
      job_type: "Тип рабочего времени: ",
      job_address: "Адрес",
      job_salary: "Зарплата",
      job_condition: "Рабочие условия",
      job_about: "О работодателе",
      job_gender_add: "Потребности в работе:",
      job_read_1: "Я прочитал и принимаю соглашение",
      job_read_2: "Скрыть контактные данные",
      job_read_3: "Пользователи могут оставлять комментарии",
      accept_use_terms: "Примите пользовательские соглашение",
      add_job_button: "Разместить вакансию",
      start_date: "Время начала",
      end_date: "Время окончания",
      ok: "OK",
      no_job: "Отдых",
      no_selection: "Ни один из них",
      add_comment: 'Добавить комментарий',
      remove_comment: 'Oтменить',
      just_add_comment: 'Добавить',
      response_comment: 'Отвечать',
      // ADD JOB


      // Profile

      profile: "Профиль",
      fullDisplay: "Полное отображение",
      salesConsultant: "консультант по продажам",
      personalInformation: "ПЕРСОНАЛЬНАЯ ИНФОРМАЦИЯ",

      // sign_in page
      sign_in_2: "Войти",
      sign_in_to: "Войти",
      sign_in_desc:
        "Войдя в систему, вы сможете откликнуться на вакансии также разместив вакансию сможете просмотреть резюме соискателей откликнувшихся на ваши вакансии.",
      your_phone: "Ваш номер телефона",
      phone_error_1: "Номер телефона должен начинаться с +9936",
      phone_error_2: "Недопустимая длина номера телефона.",
      next: "Следующий",
      accept_sign_in:
        "Соглашаюсь с правилами пользования",
      confirm_code: "Проверочный код",
      confirm_desc: "Введите код, отправленный на ваш номер телефона",
      error: "Произошла ошибка",
      success: "Успешно завершено",
      writeValidPhoneNumber: "Пишите валидный номер телефона",
      numberRequired: "Только число",
      alreadySaved: "Уже сохранено",
      savedJobs: "Сохраненные работы",
      noCV: "Пожалуйста, заполните свою анкету",
      applied: "Ваша анкета отправлена",
      deleteFromSaved: "Cохранено.",
      selectLocation: "Выберите местоположение",
      anotherExperienceText: "Напишите ваш опыт работы",
      male: 'Мужской',
      female: 'Женский',
      deleteOwnJob: "Удалить работу",
      pending: "Ожидается",
      active: "Активный",
      fixAnceta: "Редактировать анкету",
      delete: "Удалить",
      next: "Далее",
      back: "Назад",
      selectLanguage: "Выберите язык",
      selectLevelOfLanguage: "Выберите уревень владения",
      createAnceta: "Создать резюме",
      personalData: "Персональные данные",
      contactData: "Контактные данные",
      skils: "Личные качества и навыки",
      foreignLanguages: "Знание языков",
      education: "Образование",
      experience: "Опыт работы",
      diplomaAndCertification: "Диплом, сертификат и т.д",
      firstName: "Имя",
      lastName: "Фамилия",
      profession: "Профессия",
      gender: "Пол",
      birthDay: "Дата рождения",
      address: "Адрес",
      speciality: "Специальность",
      educationStartDate: "Начало учебы",
      educationEndDate: "Конец учебы",
      add: "Добавить еще",
      schoolName: "Имя учебного заведения",
      organizationName: "Имя организации",
      jobTitle: "Должность",
      jobStartDate: "Дата начало работы",
      jobEndDate: "Дата конца работы",
      reset: "Заполнить заново",
      canBeProcessed: "Может быть обработан",
      logout: "Выйти из системы",
      fillAnceta: "У вас еще не заполнена анкета. Заполните вашу анкету",
      yokaryBilimli: "Высшая образования",
      yoriteOrtaBilim: "Специальная среднее образование",
      ortaBilim: "Среднее образование",
      eventsTitle: "Свежие вакансии, ответы на отклики и доступ к резюме — всегда у вас под рукой. Просто нажмите кнопку откликнуться чтобы увидеть действия",
      acceptedToJob: "Принят на работу",
      isheChagyrmak: "Пригласить на собеседование",
      gerekliPropiska: "Прописка",
      propiskaHokmanDal: "Не имеет значение",
      sinceToday: "д.с. пор",
      putVacancy: "Разместить вакансию",
      noActiveEvents: "Активных действий нету !",
      noSavedJobs: "Нет сохраненных вакансий !",
      mailAddress: "Электронная почта",
      your_mail: "Ваша электронная почта",
      mail_error: "Введите валидную почту",
      confirm_descMail: "Введите код, отправленный на вашу электронную почту",
    },
  },
  tm: {
    translation: {
      home_page: "Baş sahypa",
      find_jobs: "Iş orunlar",
      find_worker: "Iş hödürlemek",
      sign_up: "Agza bolmak",
      add_job: "Iş hödürlemek",
      sign_in: "Ulgama girmek",
      anceta: "Anketam",
      events: "Hereketler",
      favs: "Ýatda saklananlar",
      categories: "Kategoriýalar",
      ru: "Русский",
      tm: "Türkmençe",
      lng: 'Türkmençe',
      home_title: "Iş orny tapmak. Tejribeli \n" + "hünärmenleri saýlamak",
      home_desc:
        "Iş maksatlaryňyz üçin uzak möhletli üstünlik gazanmagyň çalt we ygtybarly usuly. Shu wagt. \n" +
        "Edil şu ýerde.",
      start: "Başla",
      sponsors: "Biziň hyzmatdaşlarymyz",
      job_name: "Iş ornunyň ady",
      location: "Ýerleşýän ýeri",
      search: "Gözle",
      search_by_category: "Saýlanan kategoriýalar",
      see_all: "Beýlekiler",
      see_more: "Bildirişleriň sany",
      today_jobs: "Şu günkiler",
      more: "Doly görmek",
      left_minute: " минуты назад",
      app_title: "Wakant - Mobile goşyndusyny telefonyňyza ýükläň",
      app_desc:
        "Ykjam funksiýaly Wakant mobile goşundysyny telefonyňyzda hem ulanyp bilersiňiz. Iş gözlemeklik ykjam mobile goşundusy arkaly indi örän aňsat! Gözleýän işiňizi tapmak bilen öz anketaňyzy saýlan iş ornuňyza aňsatlyk bilen ugradyp bilersiňiz. Wakant iş berijiler we iş gözleýänler üçin mobile goşundysy ulanyjylary üçin özboluşly tejribe hödürleýär.",
      immediately: "Ofis işleri",
      state_1_title: "Iş gözleýändigiňizi bildiriş etmek",
      state_1_desc:
        "Bildirişleriň içinde size gabat gelýänini saýlap işe girmeklik üçin anketaňyzy ugradyp bilersiňiz.",
      state_2_title: "Anketaňyzy dolduryň",
      state_2_desc:
        "Özüňiz we hünäriňiz hakynda giňişleýin maglumat girizseňiz iş berijä sizi saýlamaklyga ýeňillik döredip bilersiňiz.",
      state_3_title: "Saýlan işeňize ýerleşmeklik",
      state_3_desc:
        "Gözleýän işiňize aňsatlyk bilen ýeňillik bilen tapyň we saýlan işiňize teklip bildiriň.",
      user_comment: "Ulanyjylaryň teswirleri",
      user_comment_desc:
        "Müňlerçe hünärmenleriň iş orny tapmaklygyna ýardam berýänligimiz üçin bize ynam bildirýärler!",
      contact: "Habarlaşmak",
      contact_desc:
        "Siziň biz hakyndaky oňyn teswirleriňiz beýleki adamlara öz saýlajak kärinde ynamly bolmaga kömek edýär. Üstünligiňizi paýlaşmak üçin 60 sekunt sarp edip bilersiňizmi? Siziň kömegiňiz üçin biziň minnetdarlygymyz baky bolar.",
      fullname: "Adyňyzy ýazyň",
      company: "Kärhana ady (boş geçip bilersiňiz)",
      email: "Siziň elektron poçtaňyz",
      phone_number: "Telefon belgiňiz",
      your_message: "Biz hakynda teswirleriňizi ýazyň...",
      our_address: "Ýerleşýän ýeriniň salgysy",
      our_address_value:
        "Adresimiz: ş. Aşgabat, Köpetdag etraby, 62 Parahat 1/1",
      our_email: "Mail adresi",
      our_email_value: "wakant_team@gmail.com",
      number: "Telefon belgiler",
      our_numbers: "+993 00 000000, +993 00 000000",
      send: "Ugratmak",
      docs: "Düzgünnamalar",
      terms_of_use: "Ulanyjy ylalaşygy",
      privacy_policy: "Işe girmek düwmesi",
      terms_of_comment: "Teswir ýazmagyň düzgünleri",
      all_rights: "Copyright © {{value}} Hemme hukuklar goragly",
      mobile_apps: "Telefon goşundysy",
      "home_contact_desc":
        "Siziň biz hakyndaky oňyn teswirleriňiz beýleki adamlara öz saýlajak kärinde ynamly bolmaga kömek edýär. Üstünligiňizi paýlaşmak üçin 60 sekunt sarp edip bilersiňizmi? Siziň kömegiňiz üçin biziň minnetdarlygymyz baky bolar.",

      //  JOBS PAGE
      jobb: " bildiriş ",
      av_jobs: "şu gün täze goýlan",
      jobb_desc: "Özüňiz üçin möhüm bolan zady gözläň we özüňize laýyk birini tapyň.",
      per_filter: "Giňişleýin filtirlemek",
      page: "Sahypa",
      all: "Ählisi",
      category: "Kategoriýalar",
      job_price: "Iş haky aralygyny saýlamak",
      job_gender: "Işe soralýan",
      job_firm: "Agenstwolar",
      submit_job: "Işe girmek",
      filter: "Filtr",
      sort: "Sort",
      price_asc: "Baha artýan",
      price_desc: "Baha kemelýän",
      newest_first: "Täzeden könä",
      oldest_first: "Köneden täzä",
      jobs_count: "Jemi iş orunlar sany:",
      similarJobs: "Degişli iş orunlary",
      saveJob: "Bildirişi ýatda saklamak",
      requiredField: "Hökmany meýdança",
      //  JOBS PAGE

      // View job
      one_bt: "Ýatda saklamak",
      about_job: "Iş şertleri",
      title_category: "Kategoriýasy:",
      title_job_req: "Işe soralýan:",
      title_submit: "Ýaşy:",
      title_time: "Iş grafigi:",
      title_number: "Номер телефона:",
      title_address: "Adresi:",
      title_email: "Mail adresi:",
      similar: "Похожие посты",
      owner: "Iş beriji",
      phone: "Telefon",
      comments: "Teswirler",
      comment: "Teswir",
      add_comment: 'Teswir goşmak',
      remove_comment: 'Ýatyrmak',
      just_add_comment: 'Goşmak',
      response_comment: 'Jogaplamak',
      // add job
      job_category: "Iş kategoriýasy",
      job_times_saturday: "Şenbe:  ",
      job_times_sunday: "Ýekşenbe: ",
      job_mail: "Mail adresi",
      job_name_add: "Işiň ady",
      job_hours: "Iş sagatlary: Hepde içi",
      job_expreience: "Iş tejribesi",
      job_age: "Işe soralýan ýaşy",
      job_phone: "99364998877",
      job_place_document: "Gerekli propiskasy",
      job_type: "Iş wagt görnüşi: ",
      job_address: "Adresi",
      job_salary: "Iş haky",
      job_condition: "Iş şertleri",
      job_about: "Iş beriji hakynda",
      job_gender_add: "Işe soralýan:",
      job_read_1: "Dügünnamany okadym we kabul etdim",
      job_read_2: "Telefon belgim gizlin saklansyn",
      job_read_3: "Ulanyjylar teswir goýup bilýärler",
      accept_use_terms: "Ullanmak düzgünnamasyny kabul ediň",
      add_job_button: "BILDIRIŞ GOŞMAK",
      start_date: "Başlanýan wagty",
      end_date: "Gutarýan wagty",
      ok: "OK",
      no_job: "Dynç",
      no_selection: "Hiçisi",
      add_job: "Iş goşmak",

      // sign page
      sign_in_to: "Ulagama girmek",
      sign_in_desc:
        "Ulgama girmek bilen siz öz halan iş ornuňyza anketaňyzy ugradyp bilersiňiz şeýle-hem iş ornuny goşup tejribeli hünärmenleriň anketasyny görüp bilersiňiz",
      your_phone: "Telefon belgiňiz",
      phone_error_1: "Telefon belgisi +9936 bilen başlamaly",
      phone_error_2: "Telefon belgisiniň uzunlygy nädogry",
      next: "Indiki",
      accept_sign_in:
        "Düzgünnamalary okadym we kabul etdim",
      confirm_code: "Tassyklaýjy kod",
      confirm_desc: "Telefon belgiňize gelen kody giriziň",
      code: "Kod",
      code_not_received: "Tassyklaýjy kod gelmedi",
      error: "Ýalňyşlyk ýüze çykdy",
      success: "Üstünlikli tamamlandy",
      sign_in_2: "Ulgama giriş",

      //catgeory
      back: "Yza dolan",
      sponseredByText:
        "Developed by USAID",
      // Profile
      profile: "Profil",
      fullDisplay: "Doly görkezmek",
      salesConsultant: "satyjy konsultant",
      personalInformation: "ŞAHSY MAGLUMAT",
      writeValidPhoneNumber: "Dogry telefon nomeri giriziň",
      numberRequired: "Diňe san bolmaly",
      alreadySaved: "Öň ýatda saklanan",
      savedJobs: "Ýatda saklanan işler",
      noCV: "Anketaňyzy doldyryň",
      applied: "Siziň anketaňyz ugradyldy",
      deleteFromSaved: "Ýatda saklandy",
      selectLocation: "Ýerleşýän ýerini saýlaň",
      anotherExperienceText: "Öz tejribe ýylyňyzy ýazyň",
      male: 'Erkek',
      female: 'Zenan',
      deleteOwnJob: "Bildirişi aýyrmak",
      pending: "Garaşylýar",
      active: "Goýuldy",
      fixAnceta: "Düzediş etmek",
      delete: "Pozmak",
      next: "Indiki",
      back: "Yza",
      selectLanguage: "Dil saýlaň",
      selectLevelOfLanguage: "Derejäňizi saýlaň",
      createAnceta: "Anketa döretmek",
      personalData: "Şahsy maglumatlar",
      contactData: "Habarlaşmak maglumatlar",
      skils: "Şahsy häsiýetler we başarnyklar",
      foreignLanguages: "Daşary ýurt diller",
      education: "Bilim",
      experience: "Iş tejribesi",
      diplomaAndCertification: "Diplom, sertifikat we ş.m",
      firstName: "Adyňyz",
      lastName: "Familiýaňyz",
      profession: "Hünariňiz",
      gender: "Jyns",
      birthDay: "Doglan senäňiz",
      address: "Adres",
      speciality: "Hünäri",
      educationStartDate: "Okuw başlan wagty",
      educationEndDate: "Okuw gutaran wagty",
      add: "Ýene goşmak",
      schoolName: "Okuw jaýynyň ady",
      organizationName: "Kärhananyň ady",
      jobTitle: "Wezipäňiz",
      jobStartDate: "Işe başlan senäňiz",
      jobEndDate: "Işden çykan senäňiz",
      reset: "Gaýtadan doldurmak",
      canBeProcessed: "Maglumatlarym gaytadan işlenip bilner",
      logout: "Ulgamdan çykmak",
      fillAnceta: "Sizde entäk anketa doldurylgy däl. Anketaňyzy dolduryň",
      yokaryBilimli: "Ýokary bilimli",
      yoriteOrtaBilim: "Ýörite orta bilim",
      ortaBilim: "Orta bilim",
      eventsTitle: "Her gün dürli ulgamlardaky Hususy Kärhanalar we iş beriji Telekeçiler ajaýyp işleri ýerine ýetirmek üçin bu ýere ýygnanýarlar.",
      acceptedToJob: "Işe kabul edildi",
      isheChagyrmak: "Işe çagyrmak",
      gerekliPropiska: "Gerekli propiska",
      propiskaHokmanDal: "Hökman däl",
      sinceToday: "ş.w. çenli",
      putVacancy: "Wakansiýany ýerleşdirmek",
      noActiveEvents: "Aktiw hereket ýok !",
      noSavedJobs: "Ýatda saklanan wakansiýa ýok !",
      mailAddress: "Mail adres",
      your_mail: "Elektron poçtaňyz",
      mail_error: "Dogry elektron poçtany ýazyň",
      confirm_descMail: "Elektron poçtaňyza gelen kody giriziň",
    },
  },
};

const storage = LocalStorage.getInstance();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: storage.getItem('lang') || "tm",
    fallbackLng: 'tm',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
