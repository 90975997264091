class LocalStorage {
   static instance;

   static getInstance() {
      if (!LocalStorage.instance) {
         LocalStorage.instance = new LocalStorage();
      }
      return LocalStorage.instance;
   }

   getItem(key) {
      const item = localStorage.getItem(key);

      if (item === null) return null;
      if (item === 'null') {
         localStorage.setItem(key);
         return null;
      }
      if (item === 'undefined') {
         localStorage.setItem(key);
         return null;
      }
      if (item)
         try {
            return JSON.parse(item);
         } catch (e) {
            console.error(e);
         }
      else
         return null

      return item;
   }

   setItem(key, value) {
      if (value === undefined) {
         localStorage.removeItem(key);
      } else {
         localStorage.setItem(key, JSON.stringify(value));
      }
   }
}

export default LocalStorage;
