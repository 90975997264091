import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Stack,
} from "@mui/material";
import { colors } from "../../common/theme.mjs";
import Text, { Bold } from "../App/Text";
import { AppContext } from "../../App";
import { Fonts } from "../../common/fonts.mjs";
import { getImageFullUrl, isNull } from "../../common/utils.mjs";
// styles
import styles from './Category.module.scss';
import Image from "mui-image";
import { useNavigate } from "react-router-dom";

export const cardStyle = {
  background: colors.NOT_ACTIVE_BLUE,
  cursor: "pointer",
  "&:hover": {
    background: colors.WHITE,
    boxShadow: `0px 0px 20px rgba(192, 192, 192, 0.25)`,
  },
};
const Category = (props) => {
  const { isMobile, t, appLanguage } = useContext(AppContext);
  const navigate = useNavigate();

  const fullUrlOfImage = useMemo(() => getImageFullUrl(props?.item.imageUrl), [props?.item.imageUrl]);

  return (
    <Card
      sx={{ ...cardStyle }}
      elevation={0}
      title={props?.item.descriptionRu || ''}
    >
      <CardContent>
        <Stack spacing={isMobile ? 2 : 4}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={2}
          >
            {/* <Avatar
              alt={title}
              src={getImageFullUrl(props.item.imageUrl)}
            /> */}
            <Bold
              value={appLanguage === "ru" ? props.item.nameRu : props.item.name}
              sx={{
                fontSize: isMobile ? "18px" : "22px",
              }}
              className={styles.categoryTitle}
            />
          </Stack>
          {
            (appLanguage === "ru" && !(isNull(props?.item.descriptionRu) || props?.item.descriptionRu === 'null')) ||
              (appLanguage === "tk" && !(isNull(props?.item.description) || props?.item.description === 'null')) ?
              <Text
                value={
                  appLanguage === "ru"
                    ? props.item.descriptionRu
                    : props.item.description
                }
                sx={{
                  fontSize: isMobile ? "14px" : "16px",
                  innerHeight: '100px'
                }}
                className={styles.categoryTxt}
              />
              :
              <Image
                src={fullUrlOfImage}
                className={styles.categoryImage}
              />
          }
          <Button
            sx={{
              color: "custom.alwaysWhite",
              fontSize: isMobile ? "12px" : "14px",
              fontFamily: Fonts.REGULAR,
              textTransform: "none",
            }}
            variant={"contained"}
            onClick={() => navigate(`/big-category-jobs/${props.item.uuid}`)}
          >
            {`${t("see_more")} (${props.item?.vacancyCount})`}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Category;
