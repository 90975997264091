import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useGetJobs = ({
   searchQuery,
   selectedLocation,
   genderIds,
   categoryIds,
   agenstwoIds,
   selectedDate,
   page
}) => {
   return useQuery(
      [
         "jobs",
         searchQuery,
         selectedLocation,
         genderIds,
         categoryIds,
         agenstwoIds,
         selectedDate,
         page
      ],
      () => 
      AxiosInstance.get('public/jobs/all', {
        params: {
          searchQuery,
          selectedLocation,
          categoryIds,
          genderIds,
          agenstwoIds,
          selectedDate,
          page
        }
      }).then(res => res.data),
   );
};

export default useGetJobs;