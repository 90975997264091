import React, { useContext, useEffect, useState } from "react";
import { colors } from "../../common/theme.mjs";
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Fonts } from "../../common/fonts.mjs";
import SearchIcon from "@mui/icons-material/Search";
import EventsCard from "./EventsCard.jsx";
import EventJobCard from './EventJobCard.jsx';
import { useTranslation } from "react-i18next";
import styles from './Events.module.scss';
import { AppContext } from "../../App.js";
import useGetLinks from "../../api/query/useGetLinks.js";


const searchContainer = {
  background: "#fff",
  boxShadow: "0px 0px 30px rgba(192, 192, 192, 0.25)",
  borderRadius: "150px",
  p: 4,
  width: "90%",
  height: "50px",
  display: "flex",
  alignItems: "center",
};

const Events = () => {
  const { appLanguage } = useContext(AppContext);
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const {
    data: send,
    isLoading: sendLoading
  } = useGetLinks();

  const [search, setSearch] = useState('');
  const [sendArray, setSendArray] = useState(send?.data || []);

  useEffect(() => {
    if (!sendLoading && send) {
      setSendArray(send.data);
    }
  }, [sendLoading])

  const cardStyle = {
    backgroundColor: isHover ? colors.WHITE : colors.NOT_ACTIVE_BLUE,
    cursor: "pointer",
    padding: "16px",
    borderRadius: "10px",
    transition: "0.5s",
    boxShadow: isHover ? `0px 0px 20px rgba(192, 192, 192, 0.25)` : "",
    marginTop: "20px",
    paddingBottom: 0,
  };

  const onChangeSearch = (search) => {
    setSearch(search);
    if (search.trim() == '')
      setSendArray(send.data);
    else
      setSendArray(send?.data?.filter(item => {
        if (item?.type != "RECEIVED") {
          return appLanguage === "ru" ?
            item?.job.category?.nameRu.toLowerCase().includes(search.toLowerCase())
            :
            item?.job.category?.name.toLowerCase().includes(search.toLowerCase())
        } else {
          return appLanguage === "ru" ?
            item?.questionnaire.professionRu.toLowerCase().includes(search.toLowerCase())
            :
            item?.questionnaire.profession.toLowerCase().includes(search.toLowerCase())
        }
      }))
  }

  return (
    <>
      <Stack mt={5}></Stack>
      <div
        style={{ ...cardStyle }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Stack alignItems="center" mt={1}>
          <Typography
            sx={{
              color: colors.TEXT_COLOR,
              fontWeight: "500",
              fontFamily: Fonts.REGULAR,
              width: { md: "50%", sm: "70%", xs: "90%" },
              textAlign: "center",
            }}
          >
            {t('eventsTitle')}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          mt={10}
          alignItems="center"
          spacing={5}
        >
          <Box sx={{ width: "180px" }}>
            <img
              style={{ width: "100%" }}
              src="/images/dfa1erc1uncnl1po7l2v7yawd 1.png"
              alt="dfa1erc1uncnl1po7l2v7yawd 1.png"
            />
          </Box>
          <Box
            sx={{
              ...searchContainer,
              display: { md: "flex", sm: "none", xs: "none" },
            }}
          >
            <TextField
              sx={{ "& fieldset": { border: "none", height: '20px' } }}
              variant="outlined"
              placeholder={t("search")}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={search}
              onChange={({ currentTarget: { value } }) => onChangeSearch(value)}
            />
          </Box>
          <Box sx={{ width: "250px" }}>
            <img
              style={{ width: "100%" }}
              src="/images/dxf91zhqd2z6b0bwg85ktm5s4 1.png"
              alt="dxf91zhqd2z6b0bwg85ktm5s4 1.png"
            />
          </Box>
        </Stack>
        <Box
          sx={{
            ...searchContainer,
            display: { md: "none", sm: "flex", xs: "flex" },
            mb: 3,
          }}
        >
          <TextField
            sx={{ "& fieldset": { border: "none" } }}
            variant="outlined"
            placeholder="Şu ýerde gözle"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </div>
      {/* <Stack mt={2} mb={2} spacing={1}>
        <Typography
          sx={{
            color: colors.TEXT_COLOR,
            fontFamily: Fonts.REGULAR,
            fontWeight: "400",
            mt: 3,
          }}
        >
          Sahypalar 1-89 jemi 944 hünärmenler
        </Typography>
        <img src="/images/Rectangle 331.png" alt="Rectangle 331.png" />
      </Stack> */}
      {
        sendArray?.length ?
          <div
            className={styles.category}
          >
            {
              sendArray && sendArray?.map(item => {
                if (
                  item.type == 'RECEIVED'
                )
                  return (
                    <div
                      className={styles.category__item}
                    >
                      <EventsCard item={item} />
                    </div>
                  )
                else
                  return (
                    <div
                      className={styles.category__item}
                    >
                      <EventJobCard item={item} />
                    </div>
                  )
              })
            }
          </div>
          :
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '100px'
            }}
          >
            <img
              src="/images/icon/paper_plane_grey.svg"
              width={300}
            />
            <h3 style={{ fontFamily: 'Helvetica', fontWeight: 'normal' }}>
              {t('noActiveEvents')}
            </h3>
          </div>
      }
    </>
  );
};

export default Events;
