import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AddJob from "./page/Jobs/AddJob";
import Category from "./page/Category/Category";
import Events from "./component/Events/Events.jsx";
import Home from "./page/Home/Home";
import Index from "./page/Index/Index";
import Jobs from "./page/Jobs/Jobs";
import NewCardAddJob from "./page/Jobs/NewCardAddJob";
import Profile from "./page/Profile/Profile";
import ProfileContent from "./component/Profile/ProfileContent";
import ProfileStepper from "./page/Profile/ProfileStepper";
import ViewJob from "./page/Jobs/ViewJob";
import SavedJobs from "./page/Jobs/SavedJobs";
import BigCategoryJobs from "./page/Category/BigCategoryJobs";
import Workers from "./page/Jobs/Workers";
import { createContext, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { AxiosInstance } from "./api/AxiosInstance.mjs";
import { phoneSizes } from "./common/size.mjs";
import { actionCreators } from "./state/index";

import {
  Box,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Resume from "./page/Profile/Resume";
// Theme
import { lightTheme } from './common/theme.mjs';
// storage controller
import LocalStorage from './common/storage';
// react-query
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

export const AppContext = createContext();
// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});

export function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

const storage = LocalStorage.getInstance();

function App() {
  // console.log = () => {};
  console.error = () => { };
  console.warning = () => { };
  console.warn = () => { };
  console.info = () => { };
  const wwidth = useWidth();
  const checker = (w) => {
    return phoneSizes.includes(w);
  };
  const [isMobile, setIsMobile] = useState(checker(wwidth));
  const [appLanguage, setLanguage] = useState(storage.getItem('lang') || 'tm');
  const [params, setParams] = useState();

  function getParams() {
    AxiosInstance.get(`/public/get-params`)
      .then((response) => {
        setParams(response.data);
      })
      .catch((err) => { });
  }

  useEffect(() => {
    getParams();
  }, []);

  function getAddressById(id) {
    try {
      let found = params.addressList.find(it => it.id == id);
      if (appLanguage == 'ru') {
        return found.nameRu;
      }
      return found.name;
    } catch (err) {
      return console.log(err);
    }
  }

  function getGenderById(id) {
    try {
      let found = params.genderList.find(it => it.id == id);
      if (appLanguage == 'ru') {
        return found.nameRu;
      }
      return found.name;
    } catch (err) {
      return "";
    }
  }

  useEffect(() => {
    // try {
    setIsMobile(checker(wwidth));
    // } catch (err) {
    // }
  }, [wwidth]);

  const { t, i18n } = useTranslation();

  const [isLogin, setIsLogin] = useState(
    window.localStorage.getItem("token") !== null
  );

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    storage.setItem('lang', lng)
    setLanguage(lng);
  };

  const count = useSelector((state) => state.counter);

  const dispatch = useDispatch();
  const { addCounter, minusCounter } = bindActionCreators(
    actionCreators,
    dispatch
  );

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider
        value={{
          isMobile: isMobile,
          t: t,
          changeLanguage: changeLanguage,
          appLanguage: appLanguage,
          isLogin: isLogin,
          setIsLogin: setIsLogin,
          getAddressById: getAddressById,
          getGenderById: getGenderById,
          setLanguage: setLanguage,
        }}
      >
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <Box
            sx={{
              fontFamily: "AppBold",
              position: 'relative'
            }}
          >
            <ToastContainer />
            <BrowserRouter>
              <Routes>
                <Route path={"/"} element={<Index />}>
                  <Route index element={<Home />} />
                  <Route path={"/jobs"} element={<Jobs />} />
                  <Route path={"/add-job"} element={<AddJob />} />
                  <Route path={"/add-job-new-card"} element={<NewCardAddJob />} />
                  <Route path={"/view-job/:uuid"} element={<ViewJob />} />
                  <Route path={"/big-category-jobs/:uuid"} element={<BigCategoryJobs />} />
                  <Route path={"/category"} element={<Category />} />
                  <Route path={"/events"} element={<Events />} />
                  <Route path={"/saved-jobs"} element={<SavedJobs />} />
                  <Route path={"/profile"} element={<Profile />}>
                    <Route index element={<ProfileContent />} />
                    <Route path="anceta" element={<ProfileStepper />} />
                  </Route>
                </Route>
                {/* <Route path="/resume" element={<Resume />} /> */}
              </Routes>
            </BrowserRouter>
          </Box>
        </ThemeProvider>
      </AppContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
