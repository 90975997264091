import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useGetNotApprovedCV = () => {
    return useQuery(
        ["userCVNotApproved"],
        () => AxiosInstance.get('users/questionnaires').then(res => res.data),
        {
            enabled: !!window.localStorage.getItem("token")
        }
    );
};

export default useGetNotApprovedCV;
