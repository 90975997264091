import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useGetCV = () => {
	return useQuery(
		["userCV"],
		() => AxiosInstance.get('users/questionnaires/my').then(res => res.data),
		{
			enabled: !!window.localStorage.getItem("token")
		}
	);
};

export default useGetCV;
