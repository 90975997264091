import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useAddComment = () => {
   const queryClient = useQueryClient();

   return useMutation(
      (body) => AxiosInstance.post('users/comments/add', {
         ...body
      }),
      {
         onSuccess: () => {
            queryClient.invalidateQueries(["jobComment"]);
         },
      },
   );
};

export default useAddComment;
