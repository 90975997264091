import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useApplyJob = (getJobData) => {
   const queryClient = useQueryClient();

   return useMutation(
      (body) => AxiosInstance.post('users/links/apply', {
         ...body
      }),
      {
         onSuccess: () => {
            queryClient.invalidateQueries(["appliedJobs"]);
            queryClient.invalidateQueries(["savedList"]);
            queryClient.invalidateQueries(["jobs"]);
         },
      }
   );
};

export default useApplyJob;
