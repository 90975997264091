import React, { useContext, useState } from "react";
import { colors } from "../../common/theme.mjs";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { Fonts } from "../../common/fonts.mjs";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MaleIcon from "@mui/icons-material/Male";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { AppContext } from "../../App";
import { convertTimeStampToDate } from "../../common/utils.mjs";
import useAcceptToWork from "../../api/mutation/useAcceptToWork";
import ResumeViewer from './ResumeViewer';

const privateButton = {
  // background: "rgba(229, 229, 229, 0.69)",
  borderRadius: "5px",
  textTransform: "none",
  color: colors.WHITE,
  fontFamily: Fonts.REGULAR,
  fontWeight: "400",
  fontSize: "14px",

  // "&:hover": {
  //   background: "rgba(229, 229, 229, 0.69)",
  // },
};

const EventsCard = (props) => {
  const {
    item
  } = props;
  const { appLanguage, t } = useContext(AppContext);
  const [isHover, setIsHover] = useState(false);
  const [open, setOpen] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const cardStyle = {
    backgroundColor: isHover ? colors.WHITE : colors.NOT_ACTIVE_BLUE,
    cursor: "pointer",
    padding: "16px",
    borderRadius: "10px",
    transition: "0.5s",
    boxShadow: isHover ? `0px 0px 20px rgba(192, 192, 192, 0.25)` : "",
    paddingBottom: 0,
  };

  const {
    mutate: acceptToJob,
    isLoading: acceptingToJob
  } = useAcceptToWork();

  return (
    <>
      {
        open && item.questionnaire &&
        <ResumeViewer
          notApprovedCV={item.questionnaire}
          open={open}
          setOpen={setOpen}
        />
      }
      <div
        style={{ ...cardStyle }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => setOpen(true)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack spacing={1}>
            <Typography
              sx={{
                color: colors.TEXT_COLOR,
                fontFamily: Fonts.REGULAR,
                fontWeight: "700",
                fontSize: "26px",
              }}
            >
              {
                appLanguage == 'ru' ?
                  item.questionnaire.professionRu : item.questionnaire.profession
              }
            </Typography>
            <Typography
              sx={{
                color: colors.TEXT_COLOR,
                fontFamily: Fonts.REGULAR,
                fontWeight: "600",
              }}
            >
              {
                appLanguage == 'ru' ?
                  `${item.questionnaire.nameRu || ''} ${item.questionnaire.surnameRu || ''}`
                  :
                  `${item.questionnaire.name || ''} ${item.questionnaire.surname || ''}`
              }
            </Typography>
            <Stack direction="row" pt={1} spacing={1} alignItems="center">
              <LocationOnIcon sx={{ color: colors.NOT_ACTIVE }} />
              <Typography
                sx={{
                  color: colors.NOT_ACTIVE,
                  fontFamily: Fonts.REGULAR,
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {
                  appLanguage == 'ru' ?
                    item.questionnaire.addressRu
                    :
                    item.questionnaire.address
                }
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <img src={
              item?.questionnaire?.sex == 2 ?
                "/images/Woman.png"
                :
                "/images/ef0f7e6e-5a0d-4a74-8607-2709d48614a2 1.png"
            } width={120} />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <MaleIcon sx={{ color: colors.NOT_ACTIVE }} />
            <Typography
              sx={{
                color: colors.NOT_ACTIVE,
                fontFamily: Fonts.REGULAR,
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              {item?.questionnaire?.sex == 1 ? t('male') : item?.questionnaire?.sex == 2 ? t('female') : null}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <CalendarMonthIcon sx={{ color: colors.NOT_ACTIVE }} />
            <Typography
              sx={{
                color: colors.NOT_ACTIVE,
                fontFamily: Fonts.REGULAR,
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              {
                appLanguage == 'ru' ?
                  item?.questionnaire.birth_dateRu : item?.questionnaire.birth_date
              }
            </Typography>
          </Stack>
          {/* <Typography
          pr={{ md: 50, xs: 0 }}
          sx={{
            color: "#2058D4",
            fontFamily: Fonts.REGULAR,
            fontWeight: "600",
            fontSize: "14px",
            display: { md: "block", xs: "none" },
          }}
        >
          Ýörite-orta bilimli
        </Typography> */}
          <Stack pr={5} pt={1}>
            {
              item?.status !== "PENDING" ?
                <ThumbUpAltIcon
                  sx={{ color: colors.GREEN_2 }}
                  fontSize="large"
                />
                :
                <img src={'/images/icon/received_plane.svg'} />
            }
          </Stack>
        </Stack>
        {/* <Typography
        pr={{ md: 50, xs: 0 }}
        sx={{
          color: "#2058D4",
          fontFamily: Fonts.REGULAR,
          fontWeight: "600",
          fontSize: "14px",
          marginTop: 1,
          display: { md: "none", xs: "block" },
        }}
      >
        Ýörite-orta bilimli
      </Typography> */}
        <Typography
          pt={2}
          sx={{
            color: colors.NOT_ACTIVE,
            fontFamily: Fonts.REGULAR,
            fontWeight: "400",
            fontSize: "14px",
          }}
          className="oneLine"
        >
          {
            appLanguage == 'ru' ?
              item?.questionnaire?.skillsRu : item?.questionnaire?.skills
          }
        </Typography>
        <Stack
          mt={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          pb={3}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <AccessTimeIcon sx={{ color: colors.NOT_ACTIVE }} />
            <Typography
              sx={{
                color: colors.NOT_ACTIVE,
                fontFamily: Fonts.REGULAR,
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              {convertTimeStampToDate(item?.createdAt)}
            </Typography>
          </Stack>
          <Button
            sx={privateButton}
            variant="contained"
            disableElevation
            disabled={item?.status !== "PENDING" || acceptingToJob}
            onClick={(e) => {
              e.stopPropagation();
              acceptToJob(item?.uuid);
            }}
          >
            {item?.status !== "PENDING" ? t('acceptedToJob') : t('isheChagyrmak')}
          </Button>
        </Stack>
      </div>
    </>
  );
};

export default EventsCard;
