import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Image from "mui-image";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Text, { Bold, SemiBold } from "./Text";
import { Button, Card, CardContent, Grid, IconButton, Stack } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { colors, regularButton } from "../../common/theme.mjs";
import {
	convertTimeStampToDate,
	getImageFullUrl,
	numberWithSpace,
} from "../../common/utils.mjs";
import useGetAppliedJobs from "../../api/query/useGetAppliedJobs";
import useGetCV from "../../api/query/useGetCV";
import useApplyJob from "../../api/mutation/useApplyJob";
import { toast } from "react-toastify";
import CheckIcon from '@mui/icons-material/Check';
import SignIn from "../User/SignIn";
import useGetSavedJobs from "../../api/query/useGetSavedJobs";
import useAddSavedJob from "../../api/mutation/useAddSavedJob";
import useDeleteSavedJob from "../../api/mutation/useDeleteSavedJob";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const cardStyle = {
	background: colors.NOT_ACTIVE_BLUE,
	cursor: "pointer",
	"&:hover": {
		background: colors.WHITE,
		boxShadow: `0px 0px 20px rgba(192, 192, 192, 0.25)`,
	},
};
const ItemMobile = (props) => {
	const { isMobile, t, appLanguage, getAddressById, isLogin } = useContext(AppContext);
	const { item, onClick } = props;
	const [isHover, setIsHover] = useState(false);
	const [signInState, setSignInState] = useState(false);

	const [isSlider, setIsSlider] = useState(
		typeof props.isSlider === "undefined" || props.isSlider === null
			? false
			: true
	);

	const handleMouseEnter = () => {
		setIsHover(true);
	};
	const handleMouseLeave = () => {
		setIsHover(false);
	};

	const cardStyle = {
		width: '100%',
		backgroundColor: isHover ? colors.WHITE : colors.NOT_ACTIVE_BLUE,
		cursor: "pointer",
		padding: "16px",
		borderRadius: "10px",
		transition: "0.5s",
		boxShadow: isHover ? `0px 0px 20px rgba(192, 192, 192, 0.25)` : "",
		position: 'relative'
	};
	const navigate = useNavigate();
	function changeRoute(path) {
		navigate(path);
	}
	// query
	const {
		data: appliedJobs,
	} = useGetAppliedJobs();
	const {
		data: userCV,
	} = useGetCV();
	// mutation
	const {
		mutate: applyJob,
		isLoading: isApplingToJob
	} = useApplyJob();

	// query
	const {
		data: savedJobs,
	} = useGetSavedJobs();
	// mutation
	const {
		mutate: saveJob,
		isLoading: savingJob
	} = useAddSavedJob();
	const {
		mutate: deleteSavedJob,
		isLoading: deletingSavedJob
	} = useDeleteSavedJob();

	function makeFav(e) {
		e.stopPropagation();
		if (isLogin)
			if (isSavedJob)
				deleteSavedJob({ jobId: item?.id }, {
					onSuccess: () => {
						toast.success(t('success'));
					}
				});
			else
				saveJob({ jobId: item?.id }, {
					onSuccess: () => {
						toast.success(t('success'));
					}
				});
		else
			setSignInState(true)
	}
	const handleSubmitJob = (event) => {
		event?.stopPropagation();

		if (isLogin) {
			if (userCV) {
				applyJob({
					to: item.id
				})
				toast.success(t('applied'))
			}
			else
				toast.info(t('noCV'))
		}
		else {
			setSignInState(true)
		}
	}
	const isAppliedJob = item?.apply;
	const isSavedJob = savedJobs?.data && savedJobs?.data?.find(saved => saved.id === item?.id)

	return (
		<>
			{
				signInState &&
				<SignIn
					open={signInState}
					onClose={() => setSignInState(false)}
				/>
			}
			<div
				style={{ ...cardStyle }}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={() => {
					changeRoute(`/view-job/${item.uuid}`);
					onClick(item.uuid);
				}}
			>
				<Stack spacing={1}>
					<Grid container sx={{ width: "100%" }}>
						<Grid item xs={3}>
							<Image
								showLoading={
									<Skeleton
										sx={{ width: "52px", height: "52px" }}
										variant={"rounded"}
										animation={"wave"}
									/>
								}
								src={getImageFullUrl(item.agenstwo?.imageUrl || item.imageUrl)}
								fit={"cover"}
								style={{
									width: "52px",
									height: "52px",
									borderRadius: "6px",
								}}
								wrapperStyle={{ height: "52px", width: "52px" }}
							/>
						</Grid>
						<Grid item xs={9}>
							<SemiBold
								value={
									appLanguage === "ru" ? item?.professionRu : item?.profession
								}
								sx={{ fontSize: "18px", fontWeight: 'bold' }}
								className="oneLine"
							/>
							<Stack
								direction={"row"}
								spacing={1}
								alignItems={"center"}
							>
								<LocationOnIcon
									sx={{
										width: "18px",
										color: "custom.notActive",
									}}
								/>
								<Text
									value={getAddressById(item.locationId)}
									sx={{
										fontSize: "16px",
										color: "custom.notActive",
									}}
								/>
							</Stack>
						</Grid>
					</Grid>

					<SemiBold
						value={
							appLanguage === "ru"
								? item?.category?.nameRu
								: item?.category?.name
						}
						sx={{ mt: 2, fontSize: "18px" }}
						style={{marginTop: '20px'}}
					/>

					<Stack direction={"row"} spacing={1} alignItems={"center"}>
						<Text
							value={item?.gender == 1 ? t('male') : item?.gender == 2 ? t('female') : `${t('male')} / ${t('female')}`}
							sx={{ fontSize: "16px", color: "custom.notActive" }}
						/>
					</Stack>

					<Stack direction={"row"} spacing={1} alignItems={"center"}>
						<AccessTimeFilledIcon
							sx={{ width: "18px", color: "custom.notActive" }}
						/>
						<Text
							value={convertTimeStampToDate(item.createdAt, appLanguage)}
							sx={{ fontSize: "16px", color: "custom.notActive" }}
						/>
					</Stack>

					<Text
						value={appLanguage == 'ru' ? item.conditionsRu : item.conditions}
						sx={{
							fontSize: "16px",
							color: "custom.notActive",
							height: "100%",
						}}
						className='fourLine'
						style={{marginTop: '20px'}}
					/>
					<br />
					<Stack
						direction={isSlider ? "column" : "row"}
						alignItems={isSlider ? "flex-start" : "center"}
						spacing={isSlider ? 2 : 0}
						justifyContent={"space-between"}
						sx={{
							width: "100%",
						}}
					>
						<SemiBold
							value={`${numberWithSpace(item.salary)} TMT`}
							sx={{
								fontSize: "20px",
								color: "primary.main",
								mt: isSlider ? 2 : 0,
							}}
						/>
						<Button
							variant={"contained"}
							color={isAppliedJob ? "success" : undefined}
							sx={{
								...regularButton,
								color: "#FFFFFF",
								pointerEvents: isAppliedJob ? 'none' : undefined
							}}
							startIcon={
								!isAppliedJob ?
									<img
										src="/images/icon/paper_plane.svg"
										width={15}
										style={{
											maxWidth: '15px'
										}}
									/>
									:
									<CheckIcon />
							}
							onClick={handleSubmitJob}
							disabled={isApplingToJob}
						>
							{t("submit_job")}
						</Button>
					</Stack>
				</Stack>
			</div>
		</>
	);
};

export default ItemMobile;
