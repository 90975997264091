import "../../style/Jobs/jobs.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Divider from "@mui/material/Divider";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Filters from "../../component/Jobs/Filters";
import ItemDesktop from "../../component/App/ItemDesktop";
import Loading from "../../component/State/Loading/Loading";
import MainItemMobile from "../../component/App/MainItemMobile";
import MobTopSec from "../../component/Jobs/MobTopSec";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Sort from "../../component/Jobs/Sort";
import Text, { SemiBold } from "../../component/App/Text";
import TopSection from "../../component/Jobs/TopSection";
import { Box, Button, Grid, IconButton, Pagination, Stack } from "@mui/material";
import { ExpandableBottomSheet } from "material-ui-bottom-sheet-webeetle/lib/components/ExpandableBottomSheet";
import { AppContext } from "../../App";
import { AxiosInstance } from "../../api/AxiosInstance.mjs";
import { Fonts } from "../../common/fonts.mjs";
import { colors } from "../../common/theme.mjs";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
// queries
import useGetAppliedJobs from '../../api/query/useGetAppliedJobs';
import useGetJobs from "../../api/query/useGetJobs";

const Jobs = (props) => {
  const { isMobile, t } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [bottomSheetType, setType] = useState("filter");
  const bottomSheetClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const { search: searchParams } = useLocation();
  const [queryParams] = useSearchParams();

  const searchQuery = queryParams.get('searchQuery');
  const selectedLocation = queryParams.get('selectedLocation');
  const genderIds = queryParams.get('genderIds');
  const categoryIds = queryParams.get('categoryIds');
  const agenstwoIds = queryParams.get('agenstwoIds');
  const selectedDate = queryParams.get('selectedDate');
  const page = parseInt(queryParams.get('page')) || 1;

  const {
    data: jobs,
    isLoading: jobsIsLoading
  } = useGetJobs(
    {
      searchQuery,
      selectedLocation,
      genderIds,
      categoryIds,
      agenstwoIds,
      selectedDate,
      page,
    }
  );

  const totalPageCount = useMemo(() => {
    return Math.ceil(jobs?.totalItems / 20);
  }, [jobs?.totalItems]);

  const pageChange = (page) => {
    const search = {};
    if (queryParams.get("searchQuery"))
      search["searchQuery"] = queryParams.get("searchQuery");
    if (queryParams.get("selectedCategory"))
      search["selectedCategory"] =
        queryParams.get("selectedCategory");

    if (queryParams.get("selectedLocation"))
      search["selectedLocation"] =
        queryParams.get("selectedLocation");

    if (queryParams.get("genderIds"))
      search["genderIds"] = queryParams.get("genderIds");
    if (queryParams.get("categoryIds"))
      search["categoryIds"] = queryParams.get("categoryIds");
    if (queryParams.get("agenstwoIds"))
      search["agenstwoIds"] = queryParams.get("agenstwoIds");

    search['page'] = page;

    navigate(
      {
        pathname: "/jobs",
        search: createSearchParams(search)?.toString(),
      },
      {
        replace: true,
      }
    );
  }

  return (
    <div>
      <Box sx={{ paddingTop: "40px", paddingBottom: "40px" }}>
        {isMobile ? (
          <Stack spacing={3}>
            <MobTopSec />
            <Stack
              direction={"row"}
              spacing={2}
            >
              <Button
                onClick={() => {
                  setType("filter");
                  setOpen(true);
                }}
                startIcon={<FilterAltOutlinedIcon />}
                fullWidth={true}
                variant={"contained"}
                sx={{
                  fontFamily: Fonts.REGULAR,
                  fontSize: "18px",
                  color: colors.WHITE,
                  textTransform: "none",
                }}
              >
                {t("filter")}
              </Button>
              {/* <Button
                startIcon={
                  <img
                    src={"/images/icon/sort.svg"}
                    alt={"sort"}
                  />
                }
                fullWidth={true}
                onClick={() => {
                  setType("sort");
                  setOpen(true);
                }}
                variant={"contained"}
                sx={{
                  fontFamily: Fonts.REGULAR,
                  fontSize: "18px",
                  color: colors.WHITE,
                  textTransform: "none",
                }}
              >
                {t("sort")}
              </Button> */}
            </Stack>
          </Stack>
        ) : (
          <TopSection />
        )}

        <Grid
          container
          mt={5}
          spacing={3}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={3.3}
          >
            {isMobile ? null : (
              <Filters />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8.7}
          >
            <div>
              {
                jobs?.totalItems ?
                  <>
                    <Text
                      value={`${t("jobs_count")} ${jobs?.totalItems}`}
                      sx={{
                        fontSize: "18px",
                        mt: 1,
                        mb: 1.1,
                      }}
                    />
                    <Divider color={colors.PRIMARY} />
                  </>
                  :
                  null
              }
              {/* =================================================================================================== */}
              <Stack
                spacing={4}
                sx={{ mt: 3 }}
              >
                {
                  !jobsIsLoading ?
                    jobs?.jobs?.map((item, i) => {
                      return isMobile ? (
                        <MainItemMobile
                          key={`job-mobile-${i}`}
                          item={item}
                        />
                      ) : (
                        <ItemDesktop
                          key={`job-desktop-${i}`}
                          item={item}
                        />
                      );
                    })
                    :
                    <Stack
                      sx={{ width: "100%", height: "50vh" }}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Loading />
                    </Stack>
                }
              </Stack>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '40px'
                }}
              >
                <Pagination
                  defaultPage={page}
                  count={totalPageCount}
                  color="primary"
                  shape="circular"
                  onChange={(_, page) => pageChange(page)}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <ExpandableBottomSheet
          onRequestClose={bottomSheetClose}
          open={open}
        >
          <Stack
            spacing={3}
            sx={{
              p: 2,
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <SemiBold
                sx={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "20px",
                }}
                value={t(bottomSheetType)}
              />
              <IconButton
                onClick={bottomSheetClose}
                color={"primary"}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Stack>

            {bottomSheetType === "filter" ? <Filters /> : <Sort />}
          </Stack>
        </ExpandableBottomSheet>
      </Box>
    </div>
  );
};

export default Jobs;
