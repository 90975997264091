import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useGetRules = () => {
   return useQuery(
      ["applicationRules"],
      () => AxiosInstance.get('public/get-rules').then(res => res.data)
   );
};

export default useGetRules;
