import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useDeleteSavedJob = () => {
   const queryClient = useQueryClient();

   return useMutation(
      (body) => AxiosInstance.post('users/favorites/delete', {
         ...body
      }),
      {
         onSuccess: () => {
            queryClient.invalidateQueries(["savedList"]);
         },
      },
   );
};

export default useDeleteSavedJob;
