import Divider from "@mui/material/Divider";
import FilterCheck from "./FilterCheck";
import Loading from "../State/Loading/Loading";
import MobTopSec from "./MobTopSec";
import React, { useEffect, useState } from "react";
import Text, { Bold } from "../App/Text";
import { Slider, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";
import { AppContext } from "../../App";
import { AxiosInstance } from "../../api/AxiosInstance.mjs";
import { Fonts } from "../../common/fonts.mjs";
import { colors } from "../../common/theme.mjs";
import {
	useSearchParams,
	useNavigate,
	createSearchParams,
} from "react-router-dom";

const Filters = () => {
	const { isMobile, t, appLanguage } = useContext(AppContext);
	const [params, setParams] = useState();
	const [category, setCategory] = useState();
	const [agents, setAgents] = useState();
	const [loading, setLoading] = useState(true);

	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const scrollToTop = () => {
		window.scrollTo({ top: 375, behavior: "smooth" });
	};

	async function getData() {
		setLoading(true);
		await AxiosInstance.get("public/get-params")
			.then((response) => {
				setParams(response.data);
			})
			.catch((err) => { });
		await AxiosInstance.get("web/agencies").then((response) => {
			setAgents(response.data.data);
		});
		await AxiosInstance.get("public/categories")
			.then((response) => {
				setCategory(response.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	}
	useEffect(() => {
		getData();
	}, []);

	function addFilter(id, item, isEmpty = false) {
		try {
			let ids = searchParams.get(item)
				? searchParams.get(item).split(",").map(Number)
				: [];
			if (isEmpty) {
				ids = [id];
			} else {
				const search = {};
				if (searchParams.get("searchQuery"))
					search["searchQuery"] = searchParams.get("searchQuery");
				if (searchParams.get("selectedCategory"))
					search["selectedCategory"] =
						searchParams.get("selectedCategory");

				if (
					searchParams.get("selectedLocation") &&
					item !== "selectedLocation"
				)
					search["selectedLocation"] =
						searchParams.get("selectedLocation");

				if (searchParams.get("genderIds") && item !== "genderIds")
					search["genderIds"] = searchParams.get("genderIds");
				if (searchParams.get("categoryIds") && item !== "categoryIds")
					search["categoryIds"] = searchParams.get("categoryIds");
				if (searchParams.get("agenstwoIds") && item !== "agenstwoIds")
					search["agenstwoIds"] = searchParams.get("agenstwoIds");
				if (searchParams.get("page") && item !== "page")
					search["page"] = searchParams.get("page");

				search[item] =
					ids.length > 0 ? `${id},${ids.join(",")}` : `${id}`;
				console.log(search);
				navigate(
					{
						pathname: "/jobs",
						search: createSearchParams(search)?.toString(),
					},
					{
						replace: true,
					}
				);
			}
			scrollToTop();
		} catch (err) { }
	}

	function removeFilter(id, item) {
		try {
			let ids = searchParams.get(item)
				? searchParams.get(item).split(",").map(Number)
				: [];
			const search = {};
			if (searchParams.get("searchQuery"))
				search["searchQuery"] = searchParams.get("searchQuery");
			if (searchParams.get("selectedCategory"))
				search["selectedCategory"] =
					searchParams.get("selectedCategory");

			if (
				searchParams.get("selectedLocation") &&
				item !== "selectedLocation"
			)
				search["selectedLocation"] =
					searchParams.get("selectedLocation");

			if (searchParams.get("genderIds") && item !== "genderIds")
				search["genderIds"] = searchParams.get("genderIds");
			if (searchParams.get("categoryIds") && item !== "categoryIds")
				search["categoryIds"] = searchParams.get("categoryIds");
			if (searchParams.get("agenstwoIds") && item !== "agenstwoIds")
				search["agenstwoIds"] = searchParams.get("agenstwoIds");

			search[item] = ids.filter((item) => item !== id).join(",");
			console.log(search);
			navigate(
				{
					pathname: "/jobs",
					search: createSearchParams(search)?.toString(),
				},
				{
					replace: true,
				}
			);
			scrollToTop();
		} catch (err) { }
	}

	const onClickAll = (item) => {
		const search = {};
		if (searchParams.get("searchQuery"))
			search["searchQuery"] = searchParams.get("searchQuery");
		if (searchParams.get("selectedCategory"))
			search["selectedCategory"] = searchParams.get("selectedCategory");

		if (searchParams.get("selectedLocation") && item !== "selectedLocation")
			search["selectedLocation"] = searchParams.get("selectedLocation");
		if (searchParams.get("selectedDate") && item !== "selectedDate")
			search["selectedDate"] = searchParams.get("selectedDate");

		if (searchParams.get("genderIds") && item !== "genderIds")
			search["genderIds"] = searchParams.get("genderIds");
		if (searchParams.get("categoryIds") && item !== "categoryIds")
			search["categoryIds"] = searchParams.get("categoryIds");
		if (searchParams.get("agenstwoIds") && item !== "agenstwoIds")
			search["agenstwoIds"] = searchParams.get("agenstwoIds");

		search[item] = "";

		navigate(
			{
				pathname: "/jobs",
				search: createSearchParams(search)?.toString(),
			},
			{
				replace: true,
			}
		);
		scrollToTop();
	};

	function isChecked(id, item) {
		let ids = searchParams.get(item)
			? searchParams.get(item).split(",").map(Number)
			: [];
		return ids.includes(id);
	}

	function isEmpty(item) {
		const searchItem = searchParams.get(item);
		return !searchItem || searchItem == "null" || searchItem == "undefined";
	}

	return loading ? (
		<Loading />
	) : (
		<Stack
			spacing={3}
			sx={{
				position: "sticky",
				top: 95,
				overflowY: "auto",
				height: "85vh",
				padding: "0 10px",
			}}
		>
			{isMobile ? null : (
				<div>
					<Bold
						value={t("per_filter")}
						sx={{
							fontSize: "20px",
							mb: 1,
						}}
					/>
					<Divider color={colors.PRIMARY} />
				</div>
			)}

			{isMobile ? null : <MobTopSec params={params} />}

			<Bold
				value={t("category")}
				sx={{
					fontSize: "20px",
					mb: 1,
				}}
			/>

			<Stack
				spacing={1}
			>
				<div
					style={{
						maxHeight: '225px',
						overflow: 'auto'
					}}
				>
					<FilterCheck
						count={0}
						label={t("all")}
						checked={isEmpty("categoryIds")}
						onCheckedChange={(checked) => {
							if (checked) {
								onClickAll("categoryIds");
							}
						}}
					/>
					{category.data.map((category, i) => {
						return (
							<FilterCheck
								onCheckedChange={(checked) => {
									if (checked) {
										addFilter(category.id, "categoryIds");
									} else {
										removeFilter(category.id, "categoryIds");
									}
								}}
								checked={isChecked(category.id, "categoryIds")}
								key={`filter-${i}`}
								count={category.job_count}
								label={
									appLanguage === "ru"
										? category.nameRu
										: category.name
								}
							/>
						);
					})}
				</div>
			</Stack>

			<Divider color={colors.PRIMARY} />

			{/*<Bold value={t('job_price')}*/}
			{/*      sx={{*/}
			{/*          fontSize: '20px',*/}
			{/*          mb: 1*/}
			{/*      }}/>*/}

			{/* <Stack
        spacing={2}
        sx={{ width: "100%" }}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Slider
          defaultValue={50}
          aria-label="Default"
          valueLabelDisplay="auto"
        />
        <input
          value={"4000"}
          placeholder={"0"}
          style={{
            width: "30%",
            borderRadius: "5px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "5px",
            fontFamily: Fonts.REGULAR,
            color: colors.TEXT_COLOR,
            textAlign: "center",
            fontSize: "16px",
          }}
        />
      </Stack> */}

			{/* <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text
          value={"0 manat"}
          sx={{ fontSize: "18px" }}
        />
        <Text
          value={"10000 manat"}
          sx={{ fontSize: "18px" }}
        />
      </Stack> */}

			{/* <Divider color={colors.PRIMARY} /> */}

			{/* <Stack spacing={1}>
        <FilterCheck
          count={99}
          label={t("all")}
        />
        <FilterCheck
          count={10}
          label={"0m - 200m"}
        />
        <FilterCheck
          count={5}
          label={"200m - 500m"}
        />
        <FilterCheck
          count={7}
          label={"500m - 1,000m"}
        />
        <FilterCheck
          count={55}
          label={"1,000m - 2,000m"}
        />
        <FilterCheck
          count={55}
          label={"2,000m - 4,000m"}
        />
      </Stack> */}

			<Bold
				value={t("job_gender")}
				sx={{
					fontSize: "20px",
					mb: 1,
				}}
			/>

			<Stack spacing={1}>
				{params.genderList.map((gender, i) => {
					return (
						<FilterCheck
							checked={isChecked(gender.id, "genderIds")}
							onCheckedChange={(checked) => {
								if (checked) {
									addFilter(gender.id, "genderIds");
								} else {
									removeFilter(gender.id, "genderIds");
								}
							}}
							key={`gender-${i}`}
							count={0}
							label={
								appLanguage === "ru"
									? gender.nameRu
									: gender.name
							}
						/>
					);
				})}
			</Stack>

			<Divider color={colors.PRIMARY} />

			<Bold
				value={t("job_firm")}
				sx={{
					fontSize: "20px",
					mb: 1,
				}}
			/>

			<Stack spacing={1}>
				<FilterCheck
					count={0}
					label={t("all")}
					checked={isEmpty("agenstwoIds")}
					onCheckedChange={(checked) => {
						if (checked) {
							onClickAll("categoryIds");
						}
					}}
				/>
				{agents.map((agent, i) => {
					return (
						<FilterCheck
							checked={isChecked(agent.id, "agenstwoIds")}
							onCheckedChange={(checked) => {
								if (checked) {
									addFilter(agent.id, "agenstwoIds");
								} else {
									removeFilter(agent.id, "agenstwoIds");
								}
							}}
							key={`agent-${i}`}
							count={agent.job_count}
							label={
								appLanguage === "ru" ? agent.nameRu : agent.name
							}
						/>
					);
				})}
			</Stack>
		</Stack>
	);
};

export default Filters;
