import React, { useEffect, useState } from 'react';
// components
import { Box, Modal, Stack } from '@mui/material';
import Loading from '../State/Loading/Loading';
// api
import { AxiosInstance } from '../../api/AxiosInstance.mjs';
// hooks
import { useTranslation } from 'react-i18next';
import useGetRules from '../../api/query/useGetRules';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
};
const WriteCommentRuleModal = ({ openReadTermOfUse, setOpenReadTermOfUse }) => {
    const { i18n } = useTranslation();

    const {
        data: termOfUse,
        isLoading,
    } = useGetRules();

    return (
        <Modal
            open={openReadTermOfUse}
            onClose={() => setOpenReadTermOfUse(false)}
        >
            <Box sx={style}>
                {
                    !isLoading ?
                        <div
                            dangerouslySetInnerHTML={{
                                __html: i18n.language === 'tm' ?
                                    termOfUse?.data.send_comment :
                                    termOfUse?.data.send_commentRu
                            }}
                            style={{
                                maxHeight: '700px',
                                overflow: 'auto',
                            }}
                        />
                        :
                        <Stack
                            sx={{ width: "100%", height: "50vh" }}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <Loading />
                        </Stack>
                }
            </Box>
        </Modal>
    )
}

export default WriteCommentRuleModal;