import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Fonts } from "../../common/fonts.mjs";
import { Grid, IconButton, Stack, styled } from "@mui/material";
import MaleIcon from "@mui/icons-material/Male";
import TwitterIcon from "@mui/icons-material/Twitter";
import HomeIcon from "@mui/icons-material/Home";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import SchoolIcon from "@mui/icons-material/School";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LanguageIcon from "@mui/icons-material/Language";
import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { AppContext } from "../../App";
import { Female } from "@mui/icons-material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: "70%", xs: "100%" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    height: "100%",
    display: "block",
    bottom: 0,
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#F2F2F2",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "#414141",
    },
}));

const Resume = ({ notApprovedCV, open, setOpen }) => {
    const { appLanguage } = React.useContext(AppContext);
    const handleClose = () => setOpen(false);

    const { t } = useTranslation();
    const componentRef = React.useRef(null);

    const getDate = (duration) => {
        try {
            const date = JSON.parse(duration);
            const startDate = date.start || '';
            let endDate;
            if (typeof date.end === 'string') {
                endDate = date.end || '';
            } else {
                endDate = appLanguage == 'ru' ? date.end?.ru || '' : date.end?.tk || '';
            }
            return `${startDate}-${endDate}`;
        } catch (error) {
            return '-'
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div ref={componentRef}>
                        <Grid
                            container
                            spacing={5}
                        >
                            <Grid
                                item
                                lg={4}
                                md={4}
                                sm={6}
                                xs={12}
                            >
                                <Stack alignItems="center">
                                    <img
                                        style={{ width: "84px" }}
                                        src="/images/ef0f7e6e-5a0d-4a74-8607-2709d48614a2 1 (1).png"
                                        alt="ef0f7e6e-5a0d-4a74-8607-2709d48614"
                                    />
                                    <Typography
                                        sx={{
                                            color: "#414141",
                                            fontFamily: Fonts.REGULAR,
                                            fontWeight: "300",
                                            fontSize: "20px",
                                        }}
                                    >
                                        {
                                            appLanguage == 'ru' ?
                                                notApprovedCV.nameRu
                                                :
                                                notApprovedCV.name
                                        }
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#414141",
                                            fontFamily: Fonts.REGULAR,
                                            fontWeight: "600",
                                            fontSize: "20px",
                                        }}
                                    >
                                        {
                                            appLanguage == 'ru' ?
                                                notApprovedCV.surnameRu
                                                :
                                                notApprovedCV.surname
                                        }
                                    </Typography>
                                    <Stack
                                        mt={1}
                                        mb={1}
                                    >
                                        <img
                                            src="images/Line 1.png"
                                            alt="Line 1"
                                        />
                                    </Stack>
                                    <Typography
                                        sx={{
                                            textTransform: "uppercase",
                                            fontFamily: Fonts.REGULAR,
                                            fontWeight: "300",
                                        }}
                                    >

                                        {
                                            appLanguage == 'ru' ?
                                                notApprovedCV.professionRu
                                                :
                                                notApprovedCV.profession
                                        }
                                    </Typography>
                                </Stack>
                                <Stack
                                    alignItems="center"
                                    mt={3}
                                >
                                    <Box
                                        sx={{ background: "#d9d9d9", p: 1, width: "74%", mb: -3 }}
                                    >
                                        <Typography
                                            sx={{
                                                textAlign: "center",
                                                fontWeight: "700",
                                                color: "#414141",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            {t("personalInformation")}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Box
                                    sx={{
                                        background: "#7cb8dd",
                                        height: "85%",
                                        pb: 45,
                                    }}
                                >
                                    <Stack
                                        pt={7}
                                        pl={3}
                                        spacing={2}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <IconButton
                                                sx={{
                                                    background: "#fff",
                                                    width: "30px",
                                                    height: "30px",
                                                    color: "#414141",
                                                    "&:hover": { background: "#fff" },
                                                }}
                                            >
                                                {
                                                    notApprovedCV?.sex == 1 ?
                                                        <MaleIcon />
                                                        :
                                                        <Female />
                                                }
                                            </IconButton>
                                            <Typography
                                                sx={{ color: "#414141", fontWeight: "400" }}
                                            >
                                                {
                                                    notApprovedCV?.sex == 1 ?
                                                        t('male')
                                                        :
                                                        t('female')
                                                }
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <IconButton
                                                sx={{
                                                    background: "#fff",
                                                    width: "30px",
                                                    height: "30px",
                                                    color: "#414141",
                                                    "&:hover": { background: "#fff" },
                                                }}
                                            >
                                                <TwitterIcon />
                                            </IconButton>
                                            <Typography
                                                sx={{ color: "#414141", fontWeight: "400" }}
                                            >
                                                {
                                                    appLanguage == 'ru' ?
                                                        notApprovedCV?.birth_dateRu
                                                        :
                                                        notApprovedCV?.birth_date
                                                }
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        alignItems="center"
                                        mt={4}
                                    >
                                        <Box
                                            sx={{
                                                background: "#d9d9d9",
                                                p: 1,
                                                width: "74%",
                                                mb: -3,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    textAlign: "center",
                                                    fontWeight: "700",
                                                    color: "#414141",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {t('contact')}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                    <Stack
                                        pt={7}
                                        pl={3}
                                        spacing={2}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <IconButton
                                                sx={{
                                                    background: "#fff",
                                                    width: "30px",
                                                    height: "30px",
                                                    color: "#414141",
                                                    "&:hover": { background: "#fff" },
                                                }}
                                            >
                                                <HomeIcon />
                                            </IconButton>
                                            <Typography
                                                sx={{ color: "#414141", fontWeight: "400" }}
                                            >
                                                {
                                                    appLanguage == 'ru' ?
                                                        notApprovedCV?.addressRu
                                                        :
                                                        notApprovedCV?.address
                                                }
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <IconButton
                                                sx={{
                                                    background: "#fff",
                                                    width: "30px",
                                                    height: "30px",
                                                    color: "#414141",
                                                    "&:hover": { background: "#fff" },
                                                }}
                                            >
                                                <CallIcon />
                                            </IconButton>
                                            <Typography
                                                sx={{ color: "#414141", fontWeight: "400" }}
                                            >
                                                {
                                                    notApprovedCV?.phone_number
                                                }
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <IconButton
                                                sx={{
                                                    background: "#fff",
                                                    width: "30px",
                                                    height: "30px",
                                                    color: "#414141",
                                                    "&:hover": { background: "#fff" },
                                                }}
                                            >
                                                <MailOutlineOutlinedIcon />
                                            </IconButton>
                                            <Typography
                                                sx={{
                                                    color: "#414141",
                                                    fontWeight: "400",
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >
                                                {
                                                    notApprovedCV?.e_mail
                                                }
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        alignItems="center"
                                        height="90%"
                                        justifyContent="flex-end"
                                    >
                                        <img
                                            src="images/Group (1).png"
                                            style={{ width: "80%" }}
                                            alt="Group (1)"
                                        />
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                lg={8}
                                md={8}
                                sm={8}
                                xs={12}
                                width="100%"
                            >
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    width="100%"
                                    alignItems="center"
                                >
                                    <IconButton sx={{ background: "#D9D9D9" }}>
                                        <GridViewIcon />
                                    </IconButton>
                                    <Stack width="100%">
                                        <Typography
                                            sx={{
                                                textTransform: "uppercase",
                                                color: "#414141",
                                                fontFamily: Fonts.REGULAR,
                                                fontWeight: "700",
                                                fontSiz: "20px",
                                            }}
                                        >
                                            {t('skils')}
                                        </Typography>
                                        <img
                                            src="/images/Line 2.png"
                                            alt="Line 2"
                                        />
                                    </Stack>
                                </Stack>
                                <Stack mt={2}>
                                    <Typography
                                        sx={{
                                            fontWeight: "300",
                                            fontFamily: Fonts.REGULAR,
                                            fontSize: "14px",
                                        }}
                                    >
                                        {
                                            appLanguage == 'ru' ?
                                                notApprovedCV?.skillsRu
                                                :
                                                notApprovedCV?.skills
                                        }
                                    </Typography>
                                </Stack>
                                <Stack mt={10}>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        width="100%"
                                        alignItems="center"
                                    >
                                        <IconButton sx={{ background: "#D9D9D9" }}>
                                            <SchoolIcon />
                                        </IconButton>
                                        <Stack width="100%">
                                            <Typography
                                                sx={{
                                                    textTransform: "uppercase",
                                                    color: "#414141",
                                                    fontFamily: Fonts.REGULAR,
                                                    fontWeight: "700",
                                                    fontSiz: "20px",
                                                }}
                                            >
                                                {t('education')}
                                            </Typography>
                                            <img
                                                src="/images/Line 2.png"
                                                alt="Line 2"
                                            />
                                        </Stack>
                                    </Stack>
                                    {
                                        notApprovedCV?.quest_education.map(item => {
                                            return (
                                                <Stack mt={2}>
                                                    <Typography
                                                        sx={{
                                                            color: "#414141",
                                                            fontFamily: Fonts.REGULAR,
                                                            fontWeight: "700",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        {item.HEI}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "300",
                                                            fontFamily: Fonts.REGULAR,
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {item.major}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "300",
                                                            fontFamily: Fonts.REGULAR,
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {item.location}
                                                    </Typography>
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontWeight: "300",
                                                                fontFamily: Fonts.REGULAR,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            {
                                                                item.education == "1" ?
                                                                    t('yokaryBilimli')
                                                                    :
                                                                    item.education == "2" ?
                                                                        t('yoriteOrtaBilim')
                                                                        :
                                                                        t('ortaBilim')
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontWeight: "300",
                                                                fontFamily: Fonts.REGULAR,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            {
                                                                getDate(item.duration)
                                                            }
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            )
                                        })
                                    }
                                </Stack>
                                <Stack mt={3}>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        width="100%"
                                        alignItems="center"
                                    >
                                        <IconButton sx={{ background: "#D9D9D9" }}>
                                            <BusinessCenterIcon />
                                        </IconButton>
                                        <Stack width="100%">
                                            <Typography
                                                sx={{
                                                    textTransform: "uppercase",
                                                    color: "#414141",
                                                    fontFamily: Fonts.REGULAR,
                                                    fontWeight: "700",
                                                    fontSiz: "20px",
                                                }}
                                            >
                                                {t('job_expreience')}
                                            </Typography>
                                            <img
                                                src="/images/Line 2.png"
                                                alt="Line 2"
                                            />
                                        </Stack>
                                    </Stack>
                                    {
                                        notApprovedCV?.quest_experience?.map(item => {
                                            return (
                                                <Stack mt={2}>
                                                    <Typography
                                                        sx={{
                                                            color: "#414141",
                                                            fontFamily: Fonts.REGULAR,
                                                            fontWeight: "700",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        {
                                                            appLanguage == 'ru' ?
                                                                item.professionRu
                                                                :
                                                                item.profession
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "300",
                                                            fontFamily: Fonts.REGULAR,
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {
                                                            appLanguage == 'ru' ?
                                                                item.office_nameRu
                                                                :
                                                                item.office_name
                                                        }
                                                    </Typography>

                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontWeight: "300",
                                                                fontFamily: Fonts.REGULAR,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            {
                                                                appLanguage == 'ru' ?
                                                                    item.locationRu
                                                                    :
                                                                    item.location
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontWeight: "300",
                                                                fontFamily: Fonts.REGULAR,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            {
                                                                getDate(item.duration)
                                                            }
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            )
                                        })
                                    }
                                </Stack>
                                <Stack mt={5}>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        width="100%"
                                        alignItems="center"
                                    >
                                        <IconButton sx={{ background: "#D9D9D9" }}>
                                            <LanguageIcon />
                                        </IconButton>
                                        <Stack width="100%">
                                            <Typography
                                                sx={{
                                                    textTransform: "uppercase",
                                                    color: "#414141",
                                                    fontFamily: Fonts.REGULAR,
                                                    fontWeight: "700",
                                                    fontSiz: "20px",
                                                }}
                                            >
                                                {t('foreignLanguages')}
                                            </Typography>
                                            <img
                                                src="/images/Line 2.png"
                                                alt="Line 2"
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Grid
                                    mt={3}
                                    container
                                    alignItems="center"
                                >
                                    {
                                        notApprovedCV?.quest_language?.map(item => {
                                            return (
                                                <>
                                                    <Grid
                                                        item
                                                        md={2}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontWeight: "300",
                                                                fontFamily: Fonts.REGULAR,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            {
                                                                appLanguage == 'ru' ?
                                                                    item.languageRu
                                                                    :
                                                                    item.language
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={5}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                        >
                                                            <Box sx={{ width: "100%" }}>
                                                                <BorderLinearProgress
                                                                    variant="determinate"
                                                                    value={
                                                                        item.level == '1' ?
                                                                            33 : item.level == '2' ? 66 : 100
                                                                    }
                                                                />
                                                            </Box>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={5}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="flex-end"
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: "300",
                                                                    fontFamily: Fonts.REGULAR,
                                                                    fontSize: "14px",
                                                                }}
                                                            >
                                                                {
                                                                    appLanguage == 'ru' ?
                                                                        item.levelRu
                                                                        :
                                                                        item.levelTm
                                                                }
                                                            </Typography>
                                                        </Stack>
                                                    </Grid>
                                                </>
                                            )
                                        })
                                    }
                                </Grid>
                                <Stack
                                    direction="row"
                                    mt={5}
                                    justifyContent="flex-end"
                                >
                                    <ReactToPrint
                                        // pageStyle={`@page { size: A4 ${orientation}; margin: 1cm; }`}
                                        trigger={() => (
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    textTransform: "none",
                                                    fontWeight: "600",
                                                    fontFamily: Fonts.REGULAR,
                                                    color: "#414141",
                                                    border: "1px solid #414141",
                                                    borderRadius: "90px",
                                                }}
                                            >
                                                Ýüklemek
                                            </Button>
                                        )}
                                        content={() => componentRef.current}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
};

export default Resume;
