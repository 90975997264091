import Divider from "@mui/material/Divider";
import Loading from "../State/Loading/Loading";
import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Grid, InputBase, Stack } from "@mui/material";
import { AppContext } from "../../App";
import { AxiosInstance } from "../../api/AxiosInstance.mjs";
import { Fonts } from "../../common/fonts.mjs";
import { colors, regularButton } from "../../common/theme.mjs";
import { getImageFullUrl } from "../../common/utils.mjs";
import {
	useNavigate,
	useSearchParams,
	createSearchParams,
	useLocation,
} from "react-router-dom";

const inputStyle = {
	fontFamily: Fonts.REGULAR,
	fontSize: "18px",
};

const selectStyle = {
	fontFamily: Fonts.REGULAR,
	fontSize: "18px",
	width: "100%",
	border: "none",
	outline: "none",
	background: "transparent",
	color: colors.NOT_ACTIVE,
};

const SearchBox = (props) => {
	const { t, isMobile, appLanguage } = useContext(AppContext);
	const [params, setParams] = useState();
	const [category, setCategory] = useState();
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams();

	// states for searhing
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedCategoryImage, setSelectedCategoryImage] = useState(null);

	async function getData() {
		setLoading(true);
		await AxiosInstance.get("public/get-params")
			.then((response) => {
				setParams(response.data);
			})
			.catch((err) => { });
		await AxiosInstance.get("public/categories")
			.then((response) => {
				setCategory(response.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	}
	useEffect(() => {
		getData();
	}, []);
	const getDivider = () => {
		if (!isMobile) {
			return (
				<Divider
					orientation={"vertical"}
					color={colors.PRIMARY}
					flexItem
				/>
			);
		}
		return null;
	};

	const onClickSearch = () => {
		const search = {};
		if (searchQuery) search["searchQuery"] = searchQuery;
		if (selectedLocation) search["selectedLocation"] = selectedLocation;
		if (selectedCategory) search["categoryIds"] = selectedCategory;

		if (searchParams.get("genderIds"))
			search["genderIds"] = searchParams.get("genderIds");
		if (searchParams.get("agenstwoIds"))
			search["agenstwoIds"] = searchParams.get("agenstwoIds");
		if (searchParams.get("selectedDate"))
			search["selectedDate"] = searchParams.get("selectedDate");
		if (searchParams.get("page"))
			search["page"] = searchParams.get("page");

		navigate(
			{
				pathname: "/jobs",
				search: createSearchParams(search)?.toString(),
			},
			{
				replace: location.pathname.match(/jobs/) ? true : false,
			}
		);
	};

	useEffect(() => {
		if (searchParams.get("searchQuery"))
			setSearchQuery(searchParams.get("searchQuery"));
		if (searchParams.get("selectedLocation"))
			setSelectedLocation(searchParams.get("selectedLocation"));
		if (searchParams.get("categoryIds")) {
			setSelectedCategory(searchParams.get("categoryIds"));
			setSelectedCategoryImage(
				category?.data.find(
					(item) => item.id == searchParams.get("categoryIds")
				)?.image
			);
		}
	}, [
		searchParams.get("searchQuery"),
		searchParams.get("selectedLocation"),
		searchParams.get("categoryIds"),
		category,
	]);

	return (
		<div>
			{loading ? (
				<Loading />
			) : (
				<Grid container spacing={3} alignItems={"center"}>
					<Grid item xs={12} sm={12} md={3}>
						<Stack
							sx={{
								width: "100%",
								p: 2,
								borderRadius: "5px",
								backgroundColor: isMobile
									? "custom.notActiveBlue"
									: "transparent",
							}}
							alignItems={"center"}
							direction={"row"}
							spacing={2}
						>
							<img
								src={"/images/icon/category.svg"}
								alt={"category"}
								style={{ width: "26px" }}
							/>
							<InputBase
								sx={{ ...inputStyle }}
								color={"custom.notActive"}
								placeholder={t("job_name")}
								value={searchQuery}
								onChange={({ target: { value } }) =>
									setSearchQuery(value)
								}
								onKeyDown={(e) => {
									if (e.key === 'Enter')
										onClickSearch();
								}}
							/>
						</Stack>
					</Grid>

					<Grid item xs={12} sm={12} md={4}>
						<Stack
							sx={{
								width: "100%",
								p: 2,
								borderRadius: "5px",
								backgroundColor: isMobile
									? "custom.notActiveBlue"
									: "transparent",
							}}
							alignItems={"center"}
							direction={"row"}
							spacing={2}
						>
							{getDivider()}
							<img
								src={"/images/icon/location.svg"}
								alt={"category"}
								style={{ width: "26px" }}
							/>

							<select
								style={{ ...selectStyle }}
								value={selectedLocation}
								onChange={({ target: { value } }) =>
									setSelectedLocation(value)
								}
								onKeyDown={(e) => {
									if (e.key === 'Enter')
										onClickSearch();
								}}
							>
								<option value="">{t("location")}</option>
								{params?.addressList.map((e, i) => {
									return (
										<option
											value={e.id}
											key={`region-${i}`}
										>
											{appLanguage === "ru"
												? e.nameRu
												: e.name}
										</option>
									);
								})}
							</select>
						</Stack>
					</Grid>

					<Grid item xs={12} sm={12} md={3}>
						<Stack
							sx={{
								width: "100%",
								p: 2,
								borderRadius: "5px",
								backgroundColor: isMobile
									? "custom.notActiveBlue"
									: "transparent",
							}}
							alignItems={"center"}
							direction={"row"}
							spacing={2}
						>
							{getDivider()}
							<img
								src={
									selectedCategoryImage == null
										? "/images/icon/category_search.svg"
										: getImageFullUrl(selectedCategoryImage)
								}
								alt={"category"}
								style={{ width: "26px" }}
							/>

							<select
								style={{ ...selectStyle }}
								value={selectedCategory}
								onChange={({ target: { value } }) => {
									setSelectedCategory(value);
									setSelectedCategoryImage(
										category.data.find((c) => c.id == value)
											?.image
									);
								}}
								onKeyDown={(e) => {
									if (e.key === 'Enter')
										onClickSearch();
								}}
							>
								<option
									value=""
								>
									{t('all')}
								</option>
								{category.data.map((e) => {
									return (
										<option
											value={e.id}
											key={`category-${e.id}`}
										>
											{appLanguage === "ru"
												? e.nameRu
												: e.name}
										</option>
									);
								})}
							</select>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={12} md={2}>
						<Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
							<Button
								variant={"contained"}
								fullWidth
								startIcon={<SearchIcon />}
								sx={{
									...regularButton,
									color: "custom.alwaysWhite",
									height: 50,
									fontSize: "16px",
								}}
								onClick={() => onClickSearch()}
							>
								{t("search")}
							</Button>
						</Stack>
					</Grid>
				</Grid>
			)}
		</div>
	);
};

export default SearchBox;
