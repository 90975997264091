import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useGetAppliedJobs = () => {
	return useQuery(
		["appliedJobs"],
		() => AxiosInstance.get('users/links').then(res => res.data),
		{
			enabled: !!window.localStorage.getItem("token")
		}
	);
};

export default useGetAppliedJobs;
