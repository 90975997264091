import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "swiper/css";
import "../../style/Home/home.css";
import CategoryContainer from "../../container/Home/CategoryContainer";
import Comment from "../../component/Home/Comment";
import Divider from "@mui/material/Divider";
import Feature from "../../component/Home/Feature";
import Image from "mui-image";
import JobsContainer from "../../container/Home/JobsContainer";
import Loading from "../../component/State/Loading/Loading";
import OwlCarousel from "react-owl-carousel";
import Placeholder from "../../component/State/Loading/Placeholder";
import React, { useContext, useEffect, useRef, useState } from "react";
import SearchBox from "../../component/Home/SearchBox";
import Text, { Bold } from "../../component/App/Text";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { AppContext } from "../../App";
import { AxiosInstance } from "../../api/AxiosInstance.mjs";
import { colors, regularButton } from "../../common/theme.mjs";
import { getImageFullUrl } from "../../common/utils.mjs";
import SignIn from "../../component/User/SignIn";
import { useNavigate } from "react-router-dom";
import ContactUs from "../../component/Home/ContactUs";
import { Fonts } from "../../common/fonts.mjs";

// import ImageLoading from "../../component/State/Loading/ImageLoading";

// Import Swiper styles

const Home = (props) => {
	const top = 15;
	const bottom = 15;
	const { t, isMobile, isLogin } = useContext(AppContext);
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);

	const [signInState, setSignInState] = React.useState(false);
	const [anchorEl2, setAnchorEl2] = React.useState(null);

	const navigate = useNavigate();

	function getData() {
		setLoading(true);
		AxiosInstance.get("web/get-home")
			.then((response) => {
				setLoading(false);
				setData(response.data);
			})
			.catch((err) => {
				setLoading(false);
			});
	}
	useEffect(() => {
		getData();
	}, []);
	const tJobsRef = useRef();
	// const getImageSize = () => {
	//   try {
	//     let height = tJobsRef.current.height;
	//     let width = tJobsRef.current.width;
	//     return {
	//       width: width,
	//       height: height,
	//     };
	//   } catch (err) {
	//     return {
	//       width: "600px",
	//       height: "600px",
	//     };
	//   }
	// };

	function showSignIn() {
		setSignInState(true);
		handleClose2();
	}

	const handleClose2 = () => {
		setAnchorEl2(null);
	};

	return (
		<div>
			{loading ? (
				<Loading sx={{ height: "100vh" }} />
			) : (
				<div>
					<Grid
						container
						alignItems={"flex-start"}
						justifyContent={"space-between"}
					>
						<Grid item xs={12} sm={12} md={6}>
							<Stack
								spacing={4}
								direction={"column"}
								sx={{ mt: isMobile ? 5 : 8 }}
							>
								<Bold
									value={t("home_title")}
									sx={{ fontSize: "35px" }}
								/>
								<Text
									value={t("home_desc")}
									sx={{ fontSize: "18px" }}
								/>
								{!isLogin && (
									<Button
										variant={"contained"}
										sx={{
											...regularButton,
											color: "custom.alwaysWhite",
											width: 150,
											fontSize: "16px",
										}}
										onClick={() => {
											showSignIn();
										}}
									>
										{t("start")}
									</Button>
								)}

								<Typography
									color={"custom.notActive"}
									sx={{ fontSize: "16px" }}
								>
									{t("sponsors")}
								</Typography>

								<Swiper
									spaceBetween={50}
									slidesPerView={4}
									loop
									autoplay={{
										delay: 2500,
										disableOnInteraction: false,
									}}
									modules={[Autoplay, Pagination, Navigation]}
								>
									{data
										? data.agencyList.map((item, i) => {
											return (
												<SwiperSlide
													key={`slide-agency-${i}`}
												>
													<img
														alt={"slide-imagee"}
														src={getImageFullUrl(
															item.imageUrl
														)}
														className={
															"sponsor-image"
														}
													/>
												</SwiperSlide>
											);
										})
										: null}
								</Swiper>
							</Stack>
						</Grid>

						<Grid item xs={12} sm={12} md={6}>
							{isMobile ? null : (
								<div className="hearts_wrapper">
									<div className="heart-cyrcle heart-cyrcle-1">
										{/* <Image
											style={{
												width: "50px",
												height: "50px",
											}}
											showLoading={<Placeholder />}
											src="/images/cyrcle.svg"
										/> */}
										<img
											src="/images/cyrcle.svg"
											style={{
												width: "50px",
												height: "50px",
											}}
											alt="web design icon"
										/>
									</div>
									<div className="heart-cyrcle heart-cyrcle-2">
										{/* <Image
											style={{
												width: "150px",
												height: "150px",
											}}
											showLoading={<Placeholder />}
											src="/images/cyrcle.svg"
										/> */}
										<img
											src="/images/cyrcle.svg"
											style={{
												width: "150px",
												height: "150px",
											}}
											alt="game design icon"
										/>
									</div>
									<div className="heart-cyrcle heart-cyrcle-3">
										{/* <Image
											style={{
												width: "200px",
												height: "200px",
											}}
											showLoading={<Placeholder />}
											src="/images/cyrcle.svg"
										/> */}
										<img
											src="/images/cyrcle.svg"
											style={{
												width: "200px",
												height: "200px",
											}}
											alt="game dev icon"
										/>
									</div>
									<div className="heart-cyrcle heart-cyrcle-4">
										{/* <Image
											style={{
												width: "100px",
												height: "100px",
											}}
											showLoading={<Placeholder />}
											src="/images/cyrcle.svg"
										/> */}
										<img
											src="/images/cyrcle.svg"
											style={{
												width: "100px",
												height: "100px",
											}}
											alt="ui-ux icon"
										/>
									</div>

									<div className="heart heart-1">
										<Image
											showLoading={<Placeholder />}
											src="/images/avatar/one.png"
											style={{
												width: '100px',
												height: '100px',
												borderRadius: '50%',
												animation: 'child-anim 5s linear infinite',
												boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.1), 0px 1px 10px 0px rgba(0,0,0,0.0)'
											}}
										/>
										{/* <img
											src="/images/avatar/one.png"
											alt="app icon"
										/> */}
									</div>
									<div className="heart heart-2">
										<Image
											showLoading={<Placeholder />}
											src="/images/avatar/two.png"
											style={{
												width: '50px',
												height: '50px',
												borderRadius: '50%',
												animation: 'child-anim-2 5s linear infinite',
												boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.1), 0px 1px 10px 0px rgba(0,0,0,0.0)'
											}}
										/>
										{/* <img
											src="/images/avatar/two.png"
											alt="blockchain icon"
										/> */}
									</div>
									<div className="heart heart-3">
										<Image
											showLoading={<Placeholder />}
											src="/images/avatar/three.png"
											style={{
												width: '150px',
												height: '150px',
												borderRadius: '50%',
												animation: 'child-anim 5s linear infinite',
												boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.1), 0px 1px 10px 0px rgba(0,0,0,0.0)'
											}}
										/>
										{/* <img
											src="/images/avatar/three.png"
											alt="ar-vr icon"
										/> */}
									</div>
									<div className="heart heart-4">
										<Image
											showLoading={<Placeholder />}
											src="/images/avatar/four.png"
											style={{
												width: '150px',
												height: '150px',
												borderRadius: '50%',
												animation: 'child-anim-2 5s linear infinite',
												boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.1), 0px 1px 10px 0px rgba(0,0,0,0.0)'
											}}
										/>
										{/* <img
											src="/images/avatar/four.png"
											alt="artificial intelligence icon"
										/> */}
									</div>

									<div className="center-logo">
										<img
											src="/images/avatar/main.png"
											alt="logo"
										/>
									</div>
								</div>
							)}
						</Grid>
					</Grid>

					{isMobile ? null : (
						<Paper
							elevation={2}
							sx={{
								borderRadius: "5px",
								padding: "20px",
								marginTop: "160px",
								mb: 5,
								boxShadow: `0px 0px 30px rgba(192, 192, 192, 0.25)`,
							}}
						>
							<SearchBox />
						</Paper>
					)}

					{/* ----------------Category section start---------------- */}
					<Box marginTop={'80px'}>
						<Stack
							sx={{ width: "100%" }}
							direction={"row"}
							alignItems={"center"}
							justifyContent={"space-between"}
						>
							<Stack
								sx={{ width: isMobile ? "50%" : "65%" }}
								direction={"row"}
								alignItems={"center"}
								justifyContent={
									isMobile ? "flex-start" : "flex-end"
								}
							>
								<Bold
									sx={{
										fontSize: isMobile ? "22px" : "30px",
									}}
									value={t("search_by_category")}
								/>
							</Stack>

							<Stack
								sx={{ width: isMobile ? "50%" : "35%" }}
								direction={"row"}
								alignItems={"center"}
								justifyContent={"flex-end"}
							>
								<Button
									sx={{ ...regularButton, color: "primary" }}
									onClick={() => navigate("/category")}
								>
									{t("see_all")}
								</Button>
							</Stack>
						</Stack>

						<CategoryContainer
							list={
								data
									? data.bigCategories
										? data.bigCategories
										: []
									: []
							}
						/>
					</Box>
					{/* ----------------Category section end---------------- */}

					{/* ---------------------------Jobs today section start---------------------------*/}
					<Box mt={top} mb={bottom}>
						<Grid container alignItems={"flex-start"} spacing={4}>
							<Grid item xs={12} sm={12} md={5.5}>
								{isMobile ? null : (
									<Image
										style={{
											width: "100%",
											borderRadius: "10px",
										}}
										showLoading={<Placeholder />}
										src={"/images/avatar/office_img.png"}
									/>
								)}
							</Grid>
							<Grid item ref={tJobsRef} xs={12} sm={12} md={6.5}>
								<Stack
									sx={{ width: "100%" }}
									justifyContent={"space-between"}
								>
									<Stack>
										<Bold
											value={t("today_jobs")}
											sx={{
												fontSize: isMobile
													? "25px"
													: "34px",
											}}
										/>
										<JobsContainer //list props gidipdir
											list={
												data
													? data?.submittedToday
														?.length > 0
														? data.submittedToday
														: []
													: []
											}
										/>
									</Stack>
									{/* ------------------------------------------------------------------------- */}
									<Button
										variant={"contained"}
										sx={{
											...regularButton,
											color: "custom.alwaysWhite",
											width: 150,
											fontSize: "16px",
											mt: 5,
										}}
										onClick={() => navigate("/jobs")}
									>
										{t("more")}
									</Button>
									{/* ------------------------------------------------------------------------- */}
								</Stack>
							</Grid>
						</Grid>
					</Box>
					{/* ---------------------------Jobs today section end---------------------------*/}

					{/*  ---------------------------Apps section start--------------------------- */}
					<Box mt={top} mb={bottom}>
						<Grid container spacing={6}>
							<Grid item xs={12} sm={12} md={6}>
								<Stack spacing={4}>
									<Bold
										value={t("app_title")}
										sx={{
											width: "100%",
											fontSize: isMobile
												? "22px"
												: "28px",
											textAlign: "center",
										}}
									/>
									{isMobile ? (
										<img
											style={{ width: "100%", objectFit: 'fill' }}
											src={"/images/phones.png"}
										/>
									) : null}
									<Text
										value={t("app_desc")}
										sx={{
											width: "100%",
											fontSize: isMobile
												? "16px"
												: "22px",
											textAlign: "center",
										}}
									/>
									<Divider color={colors.PRIMARY} />
									<Stack
										direction={"row"}
										sx={{ width: "100%" }}
										spacing={2}
										alignItems={"center"}
										justifyContent={"center"}
									>
										<a
											href="https://play.google.com/store/apps/details?id=com.my.wakant&hl=en&gl=US"
											target="_blank"
										>
											<Image
												style={{ width: "160px" }}
												wrapperStyle={{
													width: "160px",
												}}
												showLoading={<Placeholder />}
												src={"/images/play_google.png"}
											/>
										</a>
										<a
											href="https://apps.apple.com/tm/app/wakant/id6446488724"
											target="_blank"
										>
											<Image
												style={{ width: "160px" }}
												showLoading={<Placeholder />}
												wrapperStyle={{
													width: "160px",
												}}
												src={"/images/app_store.png"}
											/>
										</a>
									</Stack>
								</Stack>
							</Grid>

							<Grid item xs={12} sm={12} md={6}>
								{isMobile ? null : (
									<img
										style={{ width: "100%", objectFit: 'fill' }}
										src={"/images/phones.png"}
									/>
								)}
							</Grid>
						</Grid>
					</Box>
					{/*  ---------------------------Apps section end---------------------------  */}

					{/* ---------------------------Jobs section start--------------------------- */}
					<Box mt={top} mb={bottom}>
						<Grid container alignItems={"flex-start"} spacing={4}>
							<Grid item ref={tJobsRef} xs={12} sm={12} md={6.5}>
								<Stack
									sx={{ width: "100%" }}
									justifyContent={"space-between"}
								>
									<Stack>
										<Bold
											value={t("immediately")}
											sx={{
												fontSize: isMobile
													? "25px"
													: "34px",
											}}
										/>
										<JobsContainer // shugunkilerden props alyp goydym
											list={
												data
													? data?.officeJobs?.length >
														0
														? data.officeJobs
														: []
													: []
											}
										/>
									</Stack>
									<Button
										variant={"contained"}
										sx={{
											...regularButton,
											color: "custom.alwaysWhite",
											width: 150,
											fontSize: "16px",
											mt: 5,
										}}
										onClick={() => navigate("/jobs")}
									>
										{t("more")}
									</Button>
								</Stack>
							</Grid>
							<Grid item xs={12} sm={12} md={5.5}>
								{isMobile ? null : (
									<Image
										style={{
											width: "100%",
											borderRadius: "10px",
										}}
										showLoading={<Placeholder />}
										src={"/images/avatar/imm_jobs.png"}
									/>
								)}
							</Grid>
						</Grid>
					</Box>
					{/*  ---------------------------Jobs section end---------------------------  */}

					{/* ---------------------------Features start--------------------------- */}
					{isMobile ? null : (
						<Box mt={top} mb={8}
							sx={{ zoom: '1.1' }}
						>
							<div>
								{/*<img src={'/images/line.svg'} alt={'line'} style={{*/}
								{/*    position:'absolute',maxWidth:'100%',zIndex:"-9",*/}
								{/*    width:''*/}
								{/*}}/>*/}
								<Stack
									// style={{ zIndex: 9 }}
									sx={{
										width: "100%",
										backgroundImage: `url(/images/line.svg)`,
										backgroundRepeat: "no-repeat",
										backgroundSize: "70%",
										backgroundPosition: "60% 0%",
										zIndex: "9",
									}}
									spacing={3}
									direction={"row"}
									justifyContent={"space-between"}
								>
									<Feature
										image={"/images/icon/state-1.svg"}
										title={t("state_1_title")}
										desc={t("state_1_desc")}
									/>
									<Feature
										image={"/images/icon/state-2.svg"}
										title={t("state_2_title")}
										desc={t("state_2_desc")}
									/>
									<Feature
										image={"/images/icon/state-3.svg"}
										title={t("state_3_title")}
										desc={t("state_3_desc")}
									/>
								</Stack>
							</div>
						</Box>
					)}
					{/* ---------------------------Features end--------------------------- */}

					{/* Sponsered By ............................................................ */}
					<Typography
						sx={{
							color: colors.TEXT_COLOR,
							fontFamily: Fonts.REGULAR,
							fontWeight: "400",
							lineHeight: "43px",
							fontSize: "25px",
							textAlign: "center",
							paddingLeft: isMobile ? '' : '90px'
						}}
					>
						{t("sponseredByText")}
					</Typography>
					<br />
					<br />
					<Stack direction="row" justifyContent="center">
						<Stack spacing={2} alignItems="center">
							<img
								src="/images/USAID.svg"
								style={{ maxWidth: "500px", width: '100%' }}
								alt="USAID"
							/>
						</Stack>
					</Stack>
					<br />
					<br />
					<br />
					<Divider color={colors.PRIMARY} />
					<br />
					<br />
					{/* <br />
          <br />
          <br /> */}

					{/*  ---------------------------Comments start---------------------------  */}
					<Box top={top} mb={bottom}>
						<Stack spacing={3} alignItems={"center"}>
							<Bold
								value={t("user_comment")}
								sx={{
									width: "100%",
									textAlign: "center",
									fontSize: isMobile ? "25px" : "34px",
								}}
							/>
							<Text
								sx={{
									fontSize: "18px",
									width: isMobile ? "100%" : "40%",
									textAlign: "center",
									color: "custom.notActive",
								}}
								value={t("user_comment_desc")}
							/>
							<OwlCarousel
								className="owl-theme"
								margin={20}
								dotsClass={"owl-dots-class2"}
								dotClass={"slider-cat-item"}
								dots={true}
								items={isMobile ? 2 : 4}
								autoplay={true}
							>
								{data?.comments.map((item) => {
									return (
										<Comment item={item} key={item?.id} />
									);
								})}
							</OwlCarousel>
						</Stack>
					</Box>
					{/*  ---------------------------Comments end--------------------------- */}

					{/*  ---------------------------Contact us start---------------------------  */}
					<ContactUs />
					{/*  ---------------------------Contact us end---------------------------  */}
					<SignIn
						open={signInState}
						onClose={() => setSignInState(false)}
					/>
				</div>
			)}
		</div>
	);
};

export default Home;
