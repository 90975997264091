import React, { useState, useEffect, useContext } from "react";
import { Box, Stack, TextField } from "@mui/material";
import { AppContext } from "../../App";
import { Fonts } from "../../common/fonts.mjs";
import { colors } from "../../common/theme.mjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
	useNavigate,
	useSearchParams,
	createSearchParams,
} from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const selectStyle = {
	fontFamily: Fonts.REGULAR,
	fontSize: "16px",
	width: "100%",
	border: "none",
	outline: "none",
	background: "transparent",
	color: colors.NOT_ACTIVE,
};

const MobTopSec = (props) => {
	const { isMobile, t, appLanguage } = useContext(AppContext);

	const [selectedLocation, setSelectedLocation] = useState(null);
	const [selectedDate, setSelectedDate] = React.useState(dayjs());
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const onClickSearch = (item, newValue) => {
		const search = {};
		if (searchParams.get("searchQuery"))
			search["searchQuery"] = searchParams.get("searchQuery");
		if (searchParams.get("selectedCategory"))
			search["selectedCategory"] = searchParams.get("selectedCategory");
		if (searchParams.get("genderIds"))
			search["genderIds"] = searchParams.get("genderIds");
		if (searchParams.get("categoryIds"))
			search["categoryIds"] = searchParams.get("categoryIds");
		if (searchParams.get("agenstwoIds"))
			search["agenstwoIds"] = searchParams.get("agenstwoIds");

		if (searchParams.get("selectedLocation") && item !== "selectedLocation")
			search["selectedLocation"] = searchParams.get("selectedLocation");
		if (searchParams.get("selectedDate") && item !== "selectedDate")
			search["selectedDate"] = searchParams.get("selectedDate");

		if (item === "selectedLocation") search[item] = newValue;
		if (item === "selectedDate") search[item] = newValue;

		navigate(
			{
				pathname: "/jobs",
				search: createSearchParams(search)?.toString(),
			},
			{
				replace: true,
			}
		);
	};

	useEffect(() => {
		if (searchParams.get("selectedLocation"))
			setSelectedLocation(searchParams.get("selectedLocation"));
		if (searchParams.get("selectedDate"))
			setSelectedDate(dayjs(searchParams.get("selectedDate")) || dayjs());
	}, [
		searchParams.get("selectedLocation"),
		searchParams.get("selectedDate"),
	]);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Stack
				sx={{
					width: "100%",
					p: 2,
					borderRadius: "5px",
					backgroundColor: "custom.notActiveBlue",
				}}
				alignItems={"center"}
				direction={"row"}
				spacing={2}
			>
				<img
					src={"/images/icon/location.svg"}
					alt={"category"}
					style={{ width: "26px" }}
				/>

				<select
					style={{ ...selectStyle }}
					value={selectedLocation}
					onChange={({ target: { value } }) => {
						setSelectedLocation(value);
						onClickSearch("selectedLocation", value);
					}}
				>
					<option value="">{t("location")}</option>
					{props.params
						? props.params.addressList.map((e, i) => {
							return (
								<option value={e.id} key={`region-${i}`}>
									{appLanguage === "ru"
										? e.nameRu
										: e.name}
								</option>
							);
						})
						: null}
				</select>
			</Stack>
			<Box height={12} />
			<Stack
				sx={{
					mt: 2,
					width: "100%",
					p: 2,
					borderRadius: "5px",
					backgroundColor: "custom.notActiveBlue",
				}}
				alignItems={"center"}
				direction={"row"}
				spacing={2}
				className="date-input"
			>
				<LocalizationProvider
					disableScrollLock={true}
					dateAdapter={AdapterDayjs}
				>
					<DatePicker
						sx={{
							"& .MuiInputBase-root": {
								padding: 0,
								"& .MuiInputBase-input": {
									padding: 0,
									paddingLeft: 0,
									paddingTop: 0.3,
									paddingBottom: 0.3,
								},
								"& .MuiOutlinedInput-notchedOutline": {
									border: "none",
								},
							},
						}}
						slotProps={{ textField: { fullWidth: true } }}
						value={selectedDate}
						onChange={(newValue) => {
							setSelectedDate(newValue);
							onClickSearch(
								"selectedDate",
								newValue.format("YYYY-MM-DD")
							);
						}}
						format="DD.MM.YYYY"
					/>
				</LocalizationProvider>
			</Stack>
		</div>
	);
};

export default MobTopSec;
