import dayjs from "dayjs";

export const convertTimeStampToDate = (s, lang) => {
    const receivedDate = dayjs(s);
    const dateNow = dayjs();
    let hours = dateNow.diff(receivedDate, 'hours');
    const differenceOfDays = Math.floor(hours / 24);
    hours = hours - (differenceOfDays * 24);

    if (lang === 'ru') {
        if (hours <= 1) {
            return "Недавно";
        } else if (hours <= 2) {
            return "Час назад";
        } else if (hours <= 3) {
            return "2 часа назад";
        } else if (hours <= 4) {
            return "3 часа назад";
        } else if (hours <= 5) {
            return "4 часа назад";
        } else if (hours <= 6) {
            return "5 часов назад";
        } else if (hours <= 7) {
            return "6 часов назад";
        }
        else if (differenceOfDays < 1) {
            return "Сегодня";
        } else if (differenceOfDays <= 2) {
            return "Вчера";
        } else if (differenceOfDays <= 3) {
            return "2 дня назад";
        } else if (differenceOfDays <= 4) {
            return "3 дня назад";
        } else if (differenceOfDays <= 5) {
            return "4 дня назад";
        } else if (differenceOfDays <= 6) {
            return "5 дня назад";
        } else if (differenceOfDays <= 7) {
            return "6 дня назад";
        } else if (differenceOfDays > 7 && differenceOfDays <= 14) {
            return "1 нед. назад";
        } else if (differenceOfDays > 14) {
            return "2 нед. назад";
        } else {
            return receivedDate.format("DD.MM.YYYY");
        }
    } else {
        if (hours <= 1) {
            return "Ýakynda goýuldy";
        } else if (hours <= 2) {
            return "1 sagat öň";
        } else if (hours <= 3) {
            return "2 sagat öň";
        } else if (hours <= 4) {
            return "3 sagat öň";
        } else if (hours <= 5) {
            return "4 sagat öň";
        } else if (hours <= 6) {
            return "5 sagat öň";
        } else if (hours <= 7) {
            return "6 sagat öň";
        }
        else if (differenceOfDays < 1) {
            return "Şu gün";
        } else if (differenceOfDays <= 2) {
            return "Düýn";
        } else if (differenceOfDays <= 3) {
            return "2 gün öň";
        } else if (differenceOfDays <= 4) {
            return "3 gün öň";
        } else if (differenceOfDays <= 5) {
            return "4 gün öň";
        } else if (differenceOfDays <= 6) {
            return "5 gün öň";
        } else if (differenceOfDays <= 7) {
            return "6 gün öň";
        } else if (differenceOfDays > 7 && differenceOfDays <= 14) {
            return "1 hepde öň";
        } else if (differenceOfDays > 14) {
            return "2 hepde öň";
        } else {
            return receivedDate.format("DD.MM.YYYY");
        }
    }
}

export const convertTimeStampToTime = (s) => {
    let d = new Date(s);
    return `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
}

export const getImageFullUrl = (path) => {
    return `http://wakant.com.tm:8001/${path}`;
}

export function isNull(value) {
    return typeof value === 'undefined' || value === null
}

export const getRegionById = (list, id) => {
    if (isNull(list) || list.length <= 0 || isNull(id)) {
        return "";
    } else {
        try {
            return list.find(it => Number(it.id) === Number(id));
        } catch (e) {
            return "";
        }
    }
}

export const setMaxLengthLines = (count) => {
    return {
        overflow: "hidden",
        display: "-webkit-box",
        // lineClamp: '2',
        WebkitBoxOrient: "vertical",
    }
}

export function getLanguageValue(key, item, language) {
    try {
        if (!item || typeof item === "undefined" || item == null) {
            return "";
        }
        if (language === 'ru') {
            let value = item[`${key}Ru`];
            if (value && typeof value !== 'undefined' && value != null) {
                return value;
            } else {
                return item[`${key}`];
            }
        }
        return item[`${key}`];
    } catch (err) {
        return "";
    }
}

export function numberWithSpace(x) {
    return x?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
}

export function createArrayOfNumbers(start, end) {
    let result = [];
    for (let i = start; i <= end; i++) {
        result.push(i);
    }
    return result;
}