import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useSaveAnceta = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (body) => AxiosInstance.patch('users/questionnaires/edit', {
            ...body
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["userCVNotApproved"]);
            },
        }
    );
};

export default useSaveAnceta;
