import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useCreateAnceta = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (body) => {
            const formData = new FormData();
            formData.append("name", String(body.name));
            formData.append("surname", String(body.surname));
            formData.append("profession", String(body.profession));
            formData.append("sex", String(body.sex));
            formData.append("birth_date", String(body.birth_date));
            formData.append("phone_number", String(body.phone_number));
            formData.append("address", String(body.address));
            formData.append("e_mail", String(body.e_mail));
            formData.append("skills", String(body.skills));
            formData.append("language", String(body.language));
            formData.append("education", String(body.education));
            formData.append("experience", String(body.experience));
            formData.append("readTermOfUse", String(body.readTermOfUse));
            formData.append("canBeProcessed", String(body.canBeProcessed));
            body.files.forEach(item => {
                formData.append('files', item);
            });
            return AxiosInstance.post('users/questionnaires/add', formData)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["userCVNotApproved"]);
            },
        }
    );
};

export default useCreateAnceta;
