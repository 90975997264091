import Footer from "./../../component/App/Footer";
import Navbar from "../../component/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Container, Typography } from "@mui/material";

const Index = (props) => {
    const location = useLocation();
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [location]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else setShowButton(false);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return (
        <>
            <div className="wrapper" style={{ overflowX: location.pathname === '/' ? 'hidden' : 'visible' }}>
                <Navbar />
                <Container sx={{ mt: 10, maxWidth: '1400px !important', minHeight: '100vh' }} className="main">
                    <Outlet />
                </Container>
                <footer className="footer">
                    <Footer />
                </footer>
            </div>
            {
                showButton
                && (
                    <img
                    className="group-btn"
                        src="/images/up.svg"
                        onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth', // for smoothly scrolling
                            });
                        }}
                    />
                )
            }
        </>
    )
}

export default Index;