import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useDeleteOwnJob = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (id) => AxiosInstance.get(`users/jobs/delete/${id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["myJobs"]);
                queryClient.invalidateQueries(["jobs"]);
            },
        },
    );
};

export default useDeleteOwnJob;
