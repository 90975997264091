import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useGetJobComments = (id) => {
	return useQuery(
		["jobComment", id],
		() => AxiosInstance.get(`public/jobs/comments/${id}`).then(res => res.data),
	);
};

export default useGetJobComments;
