import React, { useContext } from 'react';
// components
import {
   Box,
   Stack,
   Grid,
   Button,
} from '@mui/material';
import Text, { Bold, Light, SemiBold } from "../App/Text";
import Image from "mui-image";
// context
import { AppContext } from '../../App';
// form controller
import { useFormik } from 'formik';
import * as Yup from 'yup';
// styles
import { Fonts } from "../../common/fonts.mjs";
import { colors } from "../../common/theme.mjs";
import { AxiosInstance } from '../../api/AxiosInstance.mjs';
import { toast } from "react-toastify";

const inputStyle = {
   width: "100%",
   border: "none",
   outline: "none",
   backgroundColor: colors.NOT_ACTIVE_BLUE,
   fontSize: "18px",
   padding: "16px",
   borderRadius: "5px",
   fontFamily: Fonts.REGULAR,
};

const linkStyle = {
   cursor: "pointer",
   "&:hover": {
      color: colors.PRIMARY,
   },
};

function ContactUs() {
   const top = 15;
   const bottom = 15;
   const { t, isMobile } = useContext(AppContext);

   return (
      <Box
         mt={top}
         mb={bottom}
      >
         <Stack spacing={4}>
            <Bold
               value={t("contact")}
               sx={{ fontSize: isMobile ? "25px" : "34px" }}
            />
            <Text
               value={t("home_contact_desc")}
               sx={{
                  width: isMobile ? "100%" : "70%",
                  fontSize: isMobile ? "14px" : "18px",
               }}
            />

            <Grid
               container
               spacing={0}
            >
               <WriteComment />
               {/* <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
               >
                  <Stack
                     spacing={6}
                     mt={isMobile ? 10 : 0}
                     sx={{ paddingLeft: isMobile ? "0" : "30px" }}
                  >
                     <Stack spacing={2}>
                        <SemiBold
                           value={t("our_address")}
                           sx={{ fontSize: "18px" }}
                        />
                        <Light
                           value={t("our_address_value")}
                           sx={{ fontSize: "16px", ...linkStyle }}
                        />
                     </Stack>

                     <Stack spacing={2}>
                        <SemiBold
                           value={t("our_email")}
                           sx={{ fontSize: "18px" }}
                        />
                        <Light
                           value={t("our_email_value")}
                           sx={{ fontSize: "16px", ...linkStyle }}
                        />
                     </Stack>

                     <Stack spacing={2}>
                        <SemiBold
                           value={t("number")}
                           sx={{ fontSize: "18px" }}
                        />
                        <Light
                           value={t("our_numbers").split(",")[0]}
                           sx={{ fontSize: "16px", ...linkStyle }}
                        />
                        <Light
                           value={t("our_numbers").split(",")[1]}
                           sx={{ fontSize: "16px", ...linkStyle }}
                        />
                     </Stack>
                     {isMobile ? null : (
                        <div>
                           <Stack
                              sx={{ width: "100%" }}
                              direction={"row"}
                              justifyContent={"flex-end"}
                           >
                              <img
                                 alt={"cyrcle"}
                                 src={"/images/cyrcle.svg"}
                                 style={{ width: "100px", height: "100px" }}
                              />
                           </Stack>
                           <Image
                              src={"/images/cyrcle.svg"}
                              wrapperStyle={{ marginTop: "-20px" }}
                              style={{ width: "40px", height: "40px" }}
                           />
                           <div style={{ display: "flex" }}>
                              <Image
                                 src={"/images/cyrcle.svg"}
                                 style={{ width: "70px", height: "70px" }}
                              />
                              <Image
                                 src={"/images/cyrcle.svg"}
                                 style={{ width: "120px", height: "120px" }}
                              />
                              <Image
                                 src={"/images/cyrcle.svg"}
                                 wrapperStyle={{ marginLeft: "-70px" }}
                                 style={{ width: "160px", height: "160px" }}
                              />
                           </div>
                        </div>
                     )}
                  </Stack>
               </Grid> */}
            </Grid>
         </Stack>
      </Box>
   )
}

function WriteComment() {
   const { t, isMobile } = useContext(AppContext);
   // Formik
   const formik = useFormik({
      initialValues: {
         fullName: "",
         companyName: "",
         email: "",
         phoneNumber: "",
         text: "",
      },
      validationSchema: Yup.object({
         fullName: Yup.string().required("Hökmany.."),
         phoneNumber: Yup.number().required("Hökmany..").min(6, "Nädogry belgi"),
         email: Yup.string().email("Nädogry email adress..").required("Hökmany.."),
         text: Yup.string().required("Hökmany.."),
      }),
      onSubmit: (values, { resetForm }) => {
         AxiosInstance.post('web/comments/add-comment/', {
            name: values.fullName,
            email: values.email,
            phone_number: values.phoneNumber,
            text: values.text,
            companyName: values.companyName,
         }).then(res => {
            toast.success(t("success"));
            resetForm();
            console.log(res);
         }).catch(err => {
            toast.error(t("error"))
         });
      }
   });

   return (
      <Grid
         item
         xs={12}
         sm={12}
         md={7}
      >
         <Grid
            item
            xs={12}
         >
            <form onSubmit={formik.handleSubmit}>
               <Grid
                  container
                  spacing={2}
               >
                  <Grid
                     item
                     xs={12}
                     sm={12}
                     md={6}
                  >
                     {/* ------------------------------------------------------------------------ */}
                     <input
                        placeholder={t("fullname")}
                        name="fullName"
                        type={"text"}
                        style={{ ...inputStyle }}
                        onChange={formik.handleChange}
                        value={formik.values.fullName}
                        onBlur={formik.handleBlur}
                     />
                     {formik.touched.fullName && formik.errors.fullName ? (
                        <p
                           style={{
                              color: "red",
                              fontSize: isMobile ? "12px" : "14px",
                              fontWeight: "lighter",
                           }}
                        >
                           {formik.errors.fullName}
                        </p>
                     ) : null}
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     sm={12}
                     md={6}
                  >
                     <input
                        placeholder={t("company")}
                        name="companyName"
                        type={"text"}
                        style={{ ...inputStyle }}
                        onChange={formik.handleChange}
                        value={formik.values.companyName}
                     />
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     sm={12}
                     md={6}
                  >
                     <input
                        placeholder={t("email")}
                        type={"email"}
                        name="email"
                        style={{ ...inputStyle }}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                     />
                     {formik.touched.email && formik.errors.email ? (
                        <p
                           style={{
                              color: "red",
                              fontSize: isMobile ? "12px" : "14px",
                              fontWeight: "lighter",
                           }}
                        >
                           {formik.errors.email}
                        </p>
                     ) : null}
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     sm={12}
                     md={6}
                  >
                     <input
                        placeholder={t("phone_number")}
                        type={"tel"}
                        name="phoneNumber"
                        style={{ ...inputStyle }}
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber}
                        onBlur={formik.handleBlur}
                     />
                     {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                        <p
                           style={{
                              color: "red",
                              fontSize: isMobile ? "12px" : "14px",
                              fontWeight: "lighter",
                           }}
                        >
                           {formik.errors.phoneNumber}
                        </p>
                     ) : null}
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     sm={12}
                     md={12}
                  >
                     <textarea
                        placeholder={t("your_message")}
                        type={"text"}
                        name="text"
                        onChange={formik.handleChange}
                        value={formik.values.text}
                        onBlur={formik.handleBlur}
                        style={{
                           ...inputStyle,
                           maxWidth: "100%",
                           minWidth: "100%",
                           height: "320px  ",
                        }}
                     />
                     {formik.touched.text &&
                        formik.errors.text ? (
                        <p
                           style={{
                              color: "red",
                              fontSize: isMobile ? "12px" : "14px",
                              fontWeight: "lighter",
                           }}
                        >
                           {formik.errors.text}
                        </p>
                     ) : null}
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     sm={12}
                     md={12}
                  >
                     <Button
                        variant="contained"
                        sx={{
                           color: "#FFFFFF",
                           fontFamily: Fonts.REGULAR,
                           fontSize: "18px",
                           textTransform: "none",
                        }}
                        type='submit'
                        fullWidth
                     >
                        {t("send")}
                     </Button>
                  </Grid>
               </Grid>
            </form>
         </Grid>
      </Grid>
   )
}


export default ContactUs;