import * as Yup from "yup";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { AxiosInstance } from "../../api/AxiosInstance.mjs";
import { Fonts } from "../../common/fonts.mjs";
import { colors } from "../../common/theme.mjs";
import { showError, showSuccess } from "../../component/Common/Alert";

import {
	Grid,
	Stack,
	Typography,
	Box,
	Button,
	Slider,
} from "@mui/material";
import {
	AppSelect,
	AppTextArea,
	AppTextField,
} from "../../component/Common/AppComponent";
import TermOfUseModal from "../../component/TermOfUseModal/TermOfUseModal";

export const yupStyle = {
	width: "100%",
	backgroundColor: `${colors.NOT_ACTIVE_BLUE}`,
	color: `${colors.TEXT_COLOR}`,
	outline: "none",
	fontSize: "18px",
	borderRadius: "5px",
	fontfamily: `${Fonts.REGULAR}`,
};
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

function valuetext(value) {
	return `${value} age`;
}

const NewCardAddJob = () => {
	const [agency, setAgency] = useState([]);
	const [experienceList, setExperienceList] = useState([]);
	const [addressList, setAddressList] = useState([]);
	const [genderList, setGenderList] = useState([]);
	const [openReadTermOfUse, setOpenReadTermOfUse] = useState(false);


	const fetchExperienceList = () => {
		AxiosInstance.get("public/get-params").then((response) => {
			console.log(response)
			setExperienceList(response.data.experienceList);
			setGenderList(response.data.genderList);
			setAddressList(response.data.addressList);
		});
	};

	const fetchAgencyList = () => {
		AxiosInstance.get("web/agencies").then((response) => {
			setAgency(response.data.data);
		});
	};

	useEffect(() => {
		fetchExperienceList();
		fetchAgencyList();
	}, []);


	const { t, i18n } = useTranslation();
	const [open, setOpen] = React.useState(false);
	const handleOpen = (type) => {
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			agenstwoId: '',
			email: '',
			profession: '',
			experience: '',
			anotherExperienceText: '',
			age_min: 18,
			age_max: 30,
			phone_number: '',
			locationId: '',
			work_start_time: '',
			work_end_time: '',
			address: '',
			salary: '',
			conditions: '',
			about_employer: '',
			isReadTermOfUse: false,
			gender: '',
			isPhoneHidden: false,
			canLeaveComment: false,
		},
		validationSchema: Yup.object({
			agenstwoId: Yup.number().required(t('requiredField')),
			email: Yup.string().email(t("invalidEmail")),
			profession: Yup.string().required(t('requiredField')),
			experience: Yup.number().required(t('requiredField')),
			anotherExperienceText: Yup.string(),
			age_min: Yup.number(),
			age_max: Yup.number(),
			phone_number: Yup.string()
				.min(8, t('writeValidPhoneNumber'))
				.required(t('requiredField')),
			locationId: Yup.number().required(t('requiredField')),
			work_start_time: Yup.string().required(t('requiredField')),
			work_end_time: Yup.string().required(t('requiredField')),
			address: Yup.string().required(t('requiredField')),
			salary: Yup.number().typeError(t('numberRequired')).required(t("requiredField")),
			conditions: Yup.string().required(t('requiredField')),
			about_employer: Yup.string(),
			isReadTermOfUse: Yup.boolean().isTrue(t('requiredField')),
			gender: Yup.string().required(t('requiredField')),
			isPhoneHidden: Yup.boolean(),
			canLeaveComment: Yup.boolean()
		}),
		onSubmit: async (values, { resetForm }) => {
			const body = {
				"agesnstwoId": values.agenstwoId,
				"profession": values.profession,
				"locationId": values.locationId,
				"salary": values.salary,
				"e_mail": values.email,
				"experience": values.experience,
				"anotherExperienceText": values.anotherExperienceText,
				"workday_hours": `${values.work_start_time}-${values.work_end_time}`,
				"conditions": values.conditions,
				"about_employer": values.about_employer,
				"comments": values.canLeaveComment,
				"phone_number_visible": values.isPhoneHidden,
				"minAge": values.age_min,
				"maxAge": values.age_max,
				"gender": values.gender
			}
			AxiosInstance.post("users/jobs/add", body)
				.then((response) => {
					if (!response.data.error) {
						showSuccess(t('success'));
						resetForm();
					} else {
						showError("something went wrong");
					}
				})
				.catch((error) => {
					showError(error + "error");
				});
		},
	});

	return (
		<>
			<div style={{
				display: 'flex',
				justifyContent: 'flex-end',
				marginTop: '25px'
			}}>
				<Button
					variant="contained"
					sx={{ mt: 2, color: "white" }}
				>
					{t("saveJob")}
				</Button>
			</div>
			<form onSubmit={formik.handleSubmit}>
				<Grid container spacing={3} mt={2} mb={4}>
					<Grid item xs={12} sm={6} md={4}>
						<AppSelect
							name="agenstwoId"
							value={formik.values.agenstwoId}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={
								!!(formik.touched.agenstwoId && formik.errors.agenstwoId)
							}
						>
							<option value={""}>{t("job_firm")}</option>
							{agency.map((item, i) => {
								return (
									<option
										value={item.id}
										key={`agency_list_key${i}`}
									>
										{i18n.language === "ru"
											? item.nameRu
											: item.name}
									</option>
								);
							})}
						</AppSelect>
						{
							!!(formik.touched.agenstwoId && formik.errors.agenstwoId) &&
							<p className="error-field">{formik.errors.agenstwoId}</p>
						}
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<AppTextField
							value={
								formik.values.work_start_time &&
									formik.values.work_end_time ?
									`${formik.values.work_start_time} - ${formik.values.work_end_time}`
									:
									''
							}
							placeholder={t("job_hours")}
							onClick={() => {
								handleOpen();
							}}
							onBlur={(e) => {
								formik.setFieldTouched('work_start_time', true);
								formik.setFieldTouched('work_end_time', true);
							}}
						/>
						{
							!!(formik.touched.work_start_time && formik.errors.work_start_time) ||
							!!(formik.touched.work_end_time && formik.errors.work_end_time) &&
							<p className="error-field">{t('requiredField')}</p>
						}
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<Stack
							direction="row"
							alignItems="center"
							style={{
								width: "100%",
								backgroundColor: colors.NOT_ACTIVE_BLUE,
								color: colors.TEXT_COLOR,
								border: `1px solid ${!!(formik.touched.phone_number && formik.errors.phone_number) ? '#FF505F' : 'transparent'}`,
								padding: "12px",
								fontSize: "18px",
								fontFamily: Fonts.REGULAR,
								borderRadius: "5px",
							}}
						>
							+993
							<input
								type="number"
								name="phone_number"
								value={formik.values.phone_number}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								style={{
									width: '100%',
									border: 'none',
									backgroundColor: 'transparent',
									outline: 'none',
									fontSize: "18px",
									fontFamily: Fonts.REGULAR,
								}}
							/>
						</Stack>
						{
							!!(formik.touched.phone_number && formik.errors.phone_number) &&
							<p className="error-field">{formik.errors.phone_number}</p>
						}
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<AppTextField
							placeholder={t("job_name_add")}
							name="profession"
							value={formik.values.profession}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={
								!!(formik.touched.profession && formik.errors.profession)
							}
							type="text"
						/>
						{
							!!(formik.touched.profession && formik.errors.profession) &&
							<p className="error-field">{formik.errors.profession}</p>
						}
					</Grid>

					<Grid item xs={12} sm={6} md={4}>

						<AppSelect
							name="address"
							value={formik.values.address}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={
								!!(formik.touched.address && formik.errors.address)
							}
						>
							<option
								value={''}
							>
								{t("gerekliPropiska")}
							</option>
							{addressList.map((item, i) => {
								return (
									<option
										value={item.id}
										key={`address_list_key${i}`}
									>
										{
											i18n.language === "tm"
												? item.name : item.nameRu
										}
									</option>
								);
							})}
							<option
								value={'Hökman däl'}
							>
								{t("propiskaHokmanDal")}
							</option>
						</AppSelect>
						{
							!!(formik.touched.address && formik.errors.address) &&
							<p className="error-field">{formik.errors.address}</p>
						}
						{/* <AppTextField
							type="text"
							placeholder={t("job_address")}
							name="address"
							value={formik.values.address}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={
								!!(formik.touched.address && formik.errors.address)
							}
						/>
						{
							!!(formik.touched.address && formik.errors.address) &&
							<p className="error-field">{formik.errors.address}</p>
						} */}
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<AppTextField
							placeholder={t("job_mail")}
							name="email"
							type={"email"}
							value={formik.values.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={
								!!(formik.touched.email && formik.errors.email)
							}
						/>
						{
							!!(formik.touched.email && formik.errors.email) &&
							<p className="error-field">{formik.errors.email}</p>
						}
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<AppSelect
							name="experience"
							value={formik.values.experience}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={
								!!(formik.touched.experience && formik.errors.experience)
							}
						>
							{experienceList.map((item, i) => {
								return (
									<option
										value={item.id}
										key={`experince_list_key${i}`}
									>
										{
											i18n.language === "tm" ?
												item.name
												:
												item.nameRu
										}
									</option>
								);
							})}
						</AppSelect>
						{
							!!(formik.touched.experience && formik.errors.experience) &&
							<p className="error-field">{formik.errors.experience}</p>
						}
					</Grid>

					
					<Grid item xs={12} sm={6} md={4}>
						<AppSelect
							name="locationId"
							value={formik.values.locationId}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={
								!!(formik.touched.locationId && formik.errors.locationId)
							}
						>
							<option
								value={''}
							>
								{t("selectLocation")}
							</option>
							{addressList.map((item, i) => {
								return (
									<option
										value={item.id}
										key={`address_list_key${i}`}
									>
										{
											i18n.language === "tm"
												? item.name : item.nameRu
										}
									</option>
								);
							})}
						</AppSelect>
						{
							!!(formik.touched.locationId && formik.errors.locationId) &&
							<p className="error-field">{formik.errors.locationId}</p>
						}
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<AppTextField
							type="number"
							placeholder={t("job_salary")}
							name="salary"
							value={formik.values.salary}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={
								!!(formik.touched.salary && formik.errors.salary)
							}
						/>
						{
							!!(formik.touched.salary && formik.errors.salary) &&
							<p className="error-field">{formik.errors.salary}</p>
						}
					</Grid>
					{
						formik.values.experience == 7 &&
						<Grid item xs={12} sm={6} md={4}>
							<AppTextField
								type="text"
								placeholder={t("anotherExperienceText")}
								name="anotherExperienceText"
								value={formik.values.anotherExperienceText}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isError={
									!!(formik.touched.anotherExperienceText && formik.errors.anotherExperienceText)
								}
							/>
							{
								!!(formik.touched.anotherExperienceText && formik.errors.anotherExperienceText) &&
								<p className="error-field">{formik.errors.anotherExperienceText}</p>
							}
						</Grid>
					}


					<Grid item xs={12} sm={6} md={4}>
						<Typography>{`${t("job_age")}`}
							<span style={{
								color: '#458700',
								paddingLeft: '10px',
								fontWeight: 'bold'
							}}>
								{`${formik.values.age_min}-${formik.values.age_max}`}
							</span>
						</Typography>
						<Slider
							min={0}
							max={100}
							getAriaLabel={() => t("job_age")}
							valueLabelDisplay="auto"
							getAriaValueText={valuetext}
							value={[
								formik.values.age_min,
								formik.values.age_max
							]}
							onChange={(event, newValue) => {
								formik.setFieldValue('age_min', newValue[0]);
								formik.setFieldValue('age_max', newValue[1]);
							}}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<FormControl>
							<FormLabel
								id="demo-radio-buttons-group-label"
								sx={{ color: "primary.main" }}
							>
								{t("job_gender_add")}
							</FormLabel>
							<RadioGroup
								name="gender"
								value={formik.values.gender}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							>
								{
									genderList.map((item, i) => {
										return (
											<>
												<FormControlLabel
													key={`gender_lest_key${i}`}
													value={item.id}
													control={<Radio />}
													label={
														i18n.language === "ru"
															? item.nameRu
															: item.name
													}
												/>
											</>
										);
									})
								}
							</RadioGroup>
							{
								!!(formik.touched.gender && formik.errors.gender) &&
								<p className="error-field">{formik.errors.gender}</p>
							}
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<FormControlLabel
							name="isReadTermOfUse"
							value={formik.values.isReadTermOfUse}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							control={
								<Checkbox />
							}
							label={
								<span
									onClick={() => setOpenReadTermOfUse(true)}
									className="hoverUnderLine"
								>
									{t("job_read_1")}
								</span>
							}
						/>
						{
							!!(formik.touched.isReadTermOfUse && formik.errors.isReadTermOfUse) &&
							<p className="error-field">{formik.errors.isReadTermOfUse}</p>
						}
						<FormControlLabel
							name="isPhoneHidden"
							value={formik.values.isPhoneHidden}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							control={
								<Checkbox />
							}
							label={
								t("job_read_2")
							}
						/>
						{
							!!(formik.touched.isPhoneHidden && formik.errors.isPhoneHidden) &&
							<p className="error-field">{formik.errors.isPhoneHidden}</p>
						}
						<FormControlLabel
							name="canLeaveComment"
							value={formik.values.canLeaveComment}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							control={
								<Checkbox />
							}
							label={t("job_read_3")}
						/>
						{
							!!(formik.touched.canLeaveComment && formik.errors.canLeaveComment) &&
							<p className="error-field">{formik.errors.canLeaveComment}</p>
						}
					</Grid>
				</Grid>
				<div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
					<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
						<AppTextArea
							style={{
								height: "150px"
							}}
							placeholder={t("job_condition")}
							type="text"
							name="conditions"
							value={formik.values.conditions}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={
								!!(formik.touched.conditions && formik.errors.conditions)
							}
						/>
						{
							!!(formik.touched.conditions && formik.errors.conditions) &&
							<p className="error-field">{formik.errors.conditions}</p>
						}
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
						<AppTextArea
							style={{
								height: "150px",
							}}
							type="text"
							placeholder={t("job_about")}
							name="about_employer"
							value={formik.values.about_employer}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={
								!!(formik.touched.about_employer && formik.errors.about_employer)
							}
						/>
						{
							!!(formik.touched.about_employer && formik.errors.about_employer) &&
							<p className="error-field">{formik.errors.about_employer}</p>
						}
					</div>
				</div>
				<LoadingButton
					fullWidth
					variant="contained"
					sx={{ color: "white", mt: 2, mb: 2 }}
					type="submit"
				>
					{t("add_job_button")}
				</LoadingButton>

				<Box sx={{ mb: 4 }} />

				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<Stack spacing={1} sx={{ mt: 2 }}>
							<Typography>{t("start_date")}</Typography>
							<AppTextField
								type="time"
								name="work_start_time"
								value={formik.values.work_start_time}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isError={
									!!(formik.touched.work_start_time && formik.errors.work_start_time)
								}
							/>
							{
								!!(formik.touched.work_start_time && formik.errors.work_start_time) &&
								<p className="error-field">{formik.errors.work_start_time}</p>
							}

							<Typography>{t("end_date")}</Typography>
							<AppTextField
								type="time"
								name="work_end_time"
								value={formik.values.work_end_time}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isError={
									!!(formik.touched.work_end_time && formik.errors.work_end_time)
								}
							/>
							{
								!!(formik.touched.work_end_time && formik.errors.work_end_time) &&
								<p className="error-field">{formik.errors.work_end_time}</p>
							}
						</Stack>
						<Button
							fullWidth
							onClick={() => setOpen(false)}
							variant="contained"
							sx={{ mt: 2, color: "white" }}
						>
							{t("ok")}
						</Button>
					</Box>
				</Modal>
				{
					openReadTermOfUse &&
					<TermOfUseModal
						openReadTermOfUse={openReadTermOfUse}
						setOpenReadTermOfUse={setOpenReadTermOfUse}
					/>
				}
			</form >
		</>
	);
};

export default NewCardAddJob;
