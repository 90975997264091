import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useGetMyJobs = () => {
    return useQuery(
        ["myJobs"],
        () => AxiosInstance.get("users/jobs/my-jobs").then(res => res.data),
        {
            enabled: !!window.localStorage.getItem("token")
        }
    );
};

export default useGetMyJobs;
