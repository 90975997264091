import { Fonts } from "./fonts.mjs";
import { createTheme } from "@mui/material";

export const colors = {
    PRIMARY: "#2058D4",
    PRIMARY_LIGHT: "#6884ff",
    PRIMARY_DARK: "#0030a2",
    TEXT_COLOR: "#000000",
    BLACK: "#000000",
    GREEN_1: "#219653",
    GREEN_2: "#27AE60",
    GREEN_LIGHT: "#5bc880",
    DARK_GREEN: "#006729",
    WHITE: "#FFFFFF",
    SECOND_BLUE: "rgba(32, 88, 212, 0.7)",
    NOT_ACTIVE_BLUE: "rgba(32, 88, 212, 0.05)",
    NOT_ACTIVE: "rgba(0, 0, 0, 0.54)"
}

export const placeholderColors = [
    "#E6DFF8",
    "#E6DFF8",
    "#FFEE9F",
    "#F3D3FE",
    "#CBCCFF",
    "#FDC3BE",
    "#E0F5FE",
    "#BCF68C",
    "#EDDFC9",
    "#E9DFFE",
    "#A7F1B6"
];

export const regularButton = {
    fontFamily: Fonts.REGULAR,
    color: 'custom.notActive',
    textTransform: 'none'
}

export const semiBoldButton = {
    fontFamily: Fonts.SEMI_BOLD,
    color: 'custom.notActive',
    textTransform: 'capitalize'
}


export const lightTheme = createTheme({
    components: {
        MuiTypography: {
            defaultProps: {
                fontFamily: Fonts.REGULAR,
                color: "custom.textColor",
            },
        },
        MuiButton: {
            defaultProps: {
                color: "button",
                fontFamily: Fonts.REGULAR,
            },
        },
    },
    palette: {
        mode: "light",
        primary: {
            main: colors.PRIMARY,
            light: colors.PRIMARY_LIGHT,
            lighter: colors.PRIMARY_LIGHT,
            dark: colors.PRIMARY_DARK,
            darker: colors.PRIMARY_DARK,
        },
        button: {
            main: "rgba(32, 88, 212, 0.7)",
            light: colors.PRIMARY_LIGHT,
            lighter: colors.PRIMARY_LIGHT,
            dark: colors.PRIMARY,
            darker: colors.PRIMARY,
        },
        card: {
            main: "rgba(32, 88, 212, 0.05)",
            light: "#FFFFFF",
            lighter: "#FFFFFF",
            dark: "#000000",
            darker: "#000000",
        },
        secondary: {
            main: colors.PRIMARY,
            light: colors.PRIMARY_LIGHT,
            lighter: colors.PRIMARY_LIGHT,
            dark: colors.PRIMARY_DARK,
            darker: colors.PRIMARY_DARK,
        },
        info: {
            main: colors.PRIMARY,
            light: colors.PRIMARY_LIGHT,
            lighter: colors.PRIMARY_LIGHT,
            dark: colors.PRIMARY_DARK,
            darker: colors.PRIMARY_DARK,
        },
        success: {
            main: "#61A272",
            light: "#70b382",
            lighter: "#7db88d",
            dark: "#4d825b",
            darker: "#3d6948",
        },
        warning: {
            main: "#FB8200",
            light: "#ffb343",
            lighter: "#ffb343",
            dark: "#c15300",
            darker: "#c15300",
        },
        error: {
            main: "#E17171",
            light: "#FFA2A2",
            lighter: "#FFC5C5",
            dark: "#CD5D5D",
            darker: "#C35353",
        },
        grey: {
            100: "#F9FAFB",
            200: "#F4F6F8",
            300: "#DFE3E8",
            400: "#C4CDD5",
            500: "#919EAB",
            600: "#637381",
            700: "#454F5B",
            800: "#212B36",
            900: "#161C24",
        },
        custom: {
            light: "#FFFFFF",
            main: "#FFFFFF",
            dark: "#000000",
            textColor: "rgba(0, 0, 0, 0.87)",
            notActive: "rgba(0, 0, 0, 0.54)",
            notActiveBlue: "rgba(32, 88, 212, 0.05)",
            alwaysWhite: "#FFFFFF",
            alwaysBlack: "#000000",
            strokeColor: "rgba(0, 0, 0, 0.23)",
        },
        typography: {
            fontFamily: [
                'Open Sans',
                'sans-serif'
            ],
        },
    },
});
