import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Image from "mui-image";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useEffect, useMemo, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Text, { Bold, SemiBold } from "./Text";
import { Button, Card, CardContent, Grid, IconButton, Stack } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { colors, regularButton } from "../../common/theme.mjs";
import {
	convertTimeStampToDate,
	getImageFullUrl,
	numberWithSpace,
} from "../../common/utils.mjs";
import useGetAppliedJobs from "../../api/query/useGetAppliedJobs";
import useApplyJob from "../../api/mutation/useApplyJob";
import useGetCV from '../../api/query/useGetCV';
import SignIn from "../User/SignIn";
import { toast } from "react-toastify";
// icons
import CheckIcon from '@mui/icons-material/Check';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import useDeleteSavedJob from "../../api/mutation/useDeleteSavedJob";
import useAddSavedJob from "../../api/mutation/useAddSavedJob";
import useGetSavedJobs from "../../api/query/useGetSavedJobs";
import useGetMyJobs from "../../api/query/useGetMyJobs";
import { Delete } from "@mui/icons-material";
import useDeleteOwnJob from "../../api/mutation/useDeleteOwnJob";

const ItemDesktop = (props) => {
	const { isMobile, t, appLanguage, getAddressById, isLogin } = useContext(AppContext);
	const { item } = props;
	const [isHover, setIsHover] = useState(false);
	const [signInState, setSignInState] = useState(false);

	const handleMouseEnter = () => {
		setIsHover(true);
	};
	const handleMouseLeave = () => {
		setIsHover(false);
	};

	const cardStyle = {
		width: "100%",
		backgroundColor: isHover ? colors.WHITE : colors.NOT_ACTIVE_BLUE,
		cursor: "pointer",
		padding: "16px",
		paddingBottom: "25px",
		borderRadius: "10px",
		transition: "0.5s",
		boxShadow: isHover ? `0px 0px 20px rgba(192, 192, 192, 0.25)` : "",
		position: 'relative'
	};

	const navigate = useNavigate();

	function changeRoute(path) {
		navigate(path);
	}

	// query
	const {
		data: appliedJobs,
	} = useGetAppliedJobs();
	const {
		data: userCV,
	} = useGetCV();
	const {
		data: myJobs,
	} = useGetMyJobs();
	// mutation
	const {
		mutate: applyJob,
		isLoading: isApplingToJob
	} = useApplyJob();

	// query
	const {
		data: savedJobs,
	} = useGetSavedJobs();
	// mutation
	const {
		mutate: saveJob,
		isLoading: savingJob
	} = useAddSavedJob();
	const {
		mutate: deleteSavedJob,
		isLoading: deletingSavedJob
	} = useDeleteSavedJob();
	const {
		mutate: deleteOwnJob,
		isLoading: deletingOwnJob
	} = useDeleteOwnJob();

	function makeFav(e) {
		e.stopPropagation();
		if (isLogin)
			if (isSavedJob)
				deleteSavedJob({ jobId: item?.id }, {
					onSuccess: () => {
						toast.success(t('success'));
					}
				});
			else
				saveJob({ jobId: item?.id }, {
					onSuccess: () => {
						toast.success(t('success'));
					}
				});
		else
			setSignInState(true)
	}

	const handleSubmitJob = (event) => {
		event?.stopPropagation();

		if (isLogin) {
			if (userCV) {
				applyJob({
					to: item.id
				})
				toast.success(t('applied'))
			}
			else
				toast.info(t('noCV'))
		}
		else {
			setSignInState(true)
		}
	}
	const isAppliedJob = item?.apply;
	const isSavedJob = savedJobs?.data && savedJobs?.data?.find(saved => saved.id === item?.id);
	const isOwnJob = myJobs?.find(job => job.id === item?.id);

	return (
		<>
			{
				signInState &&
				<SignIn
					open={signInState}
					onClose={() => setSignInState(false)}
				/>
			}
			<div
				style={{ ...cardStyle }}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={() => changeRoute(`/view-job/${item.uuid}`)}
			>

				{
					!isOwnJob ?
						<IconButton
							aria-label="favorite"
							style={{
								position: 'absolute',
								top: 15,
								right: 15,
							}}
							onClick={makeFav}
							disabled={savingJob || deletingSavedJob}
						>
							{
								isSavedJob ?
									<BookmarkIcon />
									:
									<BookmarkBorderIcon />
							}
						</IconButton>
						:
						<IconButton
							color="error"
							style={{
								position: 'absolute',
								top: 15,
								right: 15,
							}}
							onClick={(e) => {
								e.stopPropagation();
								deleteOwnJob(item?.uuid);
							}}
							disabled={deletingOwnJob}
						>
							<Delete />
						</IconButton>
				}
				<Stack spacing={2}>
					<Grid container sx={{ width: "100%" }}>
						<Grid item xs={1}>
							<Image
								showLoading={
									<Skeleton
										sx={{ width: "52px", height: "52px" }}
										variant={"rounded"}
										animation={"wave"}
									/>
								}
								src={getImageFullUrl(item.imageUrl)}
								fit={"cover"}
								style={{
									width: "52px",
									height: "52px",
									borderRadius: "6px",
								}}
								wrapperStyle={{ height: "52px", width: "52px" }}
							/>
						</Grid>
						<Grid item xs={10.5}>
							<SemiBold
								value={
									appLanguage === "ru" ? item.category?.nameRu : item.category?.name
								}
								sx={{ fontSize: "18px", fontWeight: 'bold' }}
							/>
							<Stack
								direction={"row"}
								spacing={1}
								alignItems={"center"}
							>
								<LocationOnIcon
									sx={{
										width: "18px",
										color: "custom.notActive",
									}}
								/>
								<Text
									value={getAddressById(item.locationId)}
									sx={{
										fontSize: "16px",
										color: "custom.notActive",
									}}
								/>
							</Stack>
						</Grid>
					</Grid>
					<Bold
						value={
							appLanguage === "ru"
								? item.professionRu
								: item.profession
						}
						sx={{ mt: 2, fontSize: "30px" }}
					/>

					<Stack direction={"row"} alignItems={"center"} spacing={4}>
						<Stack direction={"row"} spacing={1} alignItems={"center"}>
							<BusinessCenterIcon
								sx={{ width: "20px", color: "custom.notActive" }}
							/>
							<Text
								value={item.workday_hours}
								sx={{ fontSize: "17px", color: "custom.notActive" }}
							/>
						</Stack>

						<Stack direction={"row"} spacing={1} alignItems={"center"}>
							<AccessTimeFilledIcon
								sx={{ width: "20px", color: "custom.notActive" }}
							/>
							<Text
								value={convertTimeStampToDate(item.createdAt, appLanguage)}
								sx={{ fontSize: "17px", color: "custom.notActive" }}
							/>
						</Stack>
					</Stack>

					<Text
						value={appLanguage == 'ru' ? item.conditionsRu : item.conditions}
						sx={{
							fontSize: "17px",
							color: "custom.notActive",
							height: "100%",
						}}
						className='oneLine'
					/>

					<br />
					<Stack
						direction={"row"}
						alignItems={"center"}
						justifyContent={"space-between"}
						sx={{
							width: "100%",
							mt: 2,
						}}
					>
						<SemiBold
							value={`${numberWithSpace(item.salary)} TMT`}
							sx={{ fontSize: "20px", color: "primary.main" }}
						/>
						<Button
							variant={"contained"}
							color={isAppliedJob ? "success" : undefined}
							sx={{
								...regularButton,
								color: "#FFFFFF",
								pointerEvents: isAppliedJob ? 'none' : undefined,
								fontSize: '14px'
							}}
							startIcon={
								!isAppliedJob ?
									<img
										src="/images/icon/paper_plane.svg"
										width={15}
									/>
									:
									<CheckIcon />
							}
							onClick={handleSubmitJob}
							disabled={isApplingToJob}
						>
							{t("submit_job")}
						</Button>
					</Stack>
				</Stack>
			</div>
		</>
	);
};

export default ItemDesktop;
