import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useAcceptToWork = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (id) => AxiosInstance.patch(`users/links/accept-to-work/${id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["link"]);
            },
        },
    );
};

export default useAcceptToWork;
