import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropsTypes from "prop-types";
import PersonIcon from "@mui/icons-material/Person";
import {
  BoldTypography,
  FontSizes,
  RegularTypography,
} from "../Common/MuiComponent";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { Fonts } from "../../common/fonts.mjs";
import LockIcon from "@mui/icons-material/Lock";
import CountdownTimer from "./CountdownTimer";
import { AxiosInstance } from "../../api/AxiosInstance.mjs";
import { showError, showSuccess } from "../Common/Alert";
import TermOfUseModal from "../TermOfUseModal/TermOfUseModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "700px",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  px: 4,
  borderRadius: "5px",
};
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
const SignIn = (props) => {
  const { t } = useTranslation();
  const [openReadTermOfUse, setOpenReadTermOfUse] = useState(false);

  const [page, setPage] = useState(0);

  const [gPhone, setGPhone] = useState("");
  const [gMail, setGMail] = useState("");

  const [isPhoneLogin, setIsPhoneLogin] = useState(true);
  const [loginData, setLoginData] = useState();

  function FirstPage() {
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");
    const [phone, setPhone] = useState("+993");
    const [email, setEmail] = useState("");
    const [checked, setChecked] = useState(false);
    const [l, setL] = useState(false);

    function checkPhoneNumber(next) {
      if (!phone.startsWith("+993")) {
        setError(true);
        setHelperText(t("phone_error_1"));
      } else if (phone.length > 12) {
        setError(true);
        setHelperText(t("phone_error_2"));
      } else {
        next?.();
      }
    }
    function checkMailAddress(next) {
      if (email !== '' && !validateEmail(email)) {
        setError(true);
        setHelperText(t("mail_error"));
      }
      else {
        setError(false);
        setHelperText('');
        next?.();
      }
    }

    useEffect(() => {
      if (isPhoneLogin)
        checkPhoneNumber(() => {
          setError(false);
          setHelperText("");
        });
      else
        checkMailAddress();
    }, [phone]);

    function login() {
      if (isPhoneLogin) {
        let p = phone.substring(4);
        setL(true);
        AxiosInstance.post("/users/post-code", {
          phone_number: p,
        })
          .then((response) => {
            if (response.data.success) {
              setPage(page + 1);
              setGPhone(p);
              showSuccess(t("confirm_desc"));
            } else {
              showError(t("error"));
            }
            setL(false);
          })
          .catch((err) => {
            showError(t("error"));
            setL(false);
          });
      } else {
        AxiosInstance.post("/users/login-email", {
          email: email,
        }).then((res) => {
          if (res.data) {
            setLoginData(res.data)
            setPage(page + 1);
            setGMail(email);
            showSuccess(t("confirm_descMail"));
          } else {
            showError(t("error"));
          }
          setL(false);
        })
          .catch((err) => {
            showError(t("error"));
            setL(false);
          });
      }
    }

    const next = () => {
      if (isPhoneLogin)
        checkPhoneNumber(() => {
          if (phone.length !== 12) {
            setError(true);
            setHelperText(t("phone_error_2"));
          } else {
            if (checked)
              login();
            else {
              setError(true);
              setHelperText(t("accept_use_terms"));
            }
          }
        });
      else
        checkMailAddress(() => {
          if (checked) login()
          else {
            setError(true);
            setHelperText(t("accept_use_terms"));
          }
        })
    };

    return (
      <Stack
        alignItems={"center"}
        sx={{ width: "100%" }}
        spacing={2}
      >
        <Stack
          direction={"row"}
          sx={{ width: "100%" }}
          justifyContent={"flex-end"}
        >
          <IconButton onClick={props.onClose}>
            <CloseIcon color={"primary"} />
          </IconButton>
        </Stack>
        <img src="/images/profile.svg" alt="Person icon" />
        <BoldTypography sx={{ fontSize: FontSizes.title, fontWeight: 'bold' }}>
          {t("sign_in_to")}
        </BoldTypography>

        <RegularTypography
          sx={{ fontSize: FontSizes.body, textAlign: "center" }}
        >
          {t("sign_in_desc")}
        </RegularTypography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <span
            style={{
              color: '#2058D4',
              textDecoration: isPhoneLogin ? "underline" : "none",
              cursor: 'pointer',
            }}
            onClick={() => {
              setIsPhoneLogin(true);
              setError(false);
              setHelperText('');
            }}
          >
            {t("phone")}
          </span>
          <span
            style={{
              color: '#2058D4',
              textDecoration: !isPhoneLogin ? "underline" : "none",
              cursor: 'pointer',
            }}
            onClick={() => {
              setIsPhoneLogin(false);
              setError(false);
              setHelperText('');
            }}
          >
            {t("mailAddress")}
          </span>
        </div>
        {
          isPhoneLogin ?
            <TextField
              label={t("your_phone")}
              type={"phone"}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              helperText={helperText}
              variant={"filled"}
              error={error}
              fullWidth
              sx={{ m: 2 }}
            />
            :
            <TextField
              label={t("your_mail")}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              helperText={helperText}
              variant={"filled"}
              error={error}
              fullWidth
              sx={{ m: 2 }}
            />
        }
        <br />
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={(e) => setChecked(e.currentTarget.value)} />}
          label={
            <span
              className="hoverUnderLine"
              onClick={() => setOpenReadTermOfUse(true)}
            >
              {t("accept_sign_in")}
            </span>
          }
        />

        <LoadingButton
          onClick={next}
          loading={l}
          variant={"contained"}
          sx={{
            fontSize: FontSizes.button,
            color: "custom.light",
            fontFamily: Fonts.REGULAR,
            textTransform: "none",
            width: "120px",
            mt: 2,
            mb: 2,
          }}
        >
          {t("next")}
        </LoadingButton>
      </Stack>
    );
  }

  function ConfirmPage() {
    const [code, setCode] = useState("");
    const [codeFromMail, setCodeFromMail] = useState("");
    const [errorCode, setErrorCode] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [l, setL] = useState(false);

    useEffect(() => {
      if (isPhoneLogin)
        setDisabled(code.length !== 5);
      else
        setDisabled(codeFromMail.length !== 4);
    }, [code, codeFromMail]);

    const SECONDS_IN_MS = 60 * 1000;
    const NOW_IN_MS = new Date().getTime();

    const dateTimeAfterThreeDays = NOW_IN_MS + SECONDS_IN_MS;

    function reSend() {
      if (isPhoneLogin) {
        AxiosInstance.post("/users/post-code", {
          phone_number: gPhone,
        })
          .then((response) => {
            if (response.data.success) {
              showSuccess(t("confirm_desc"));
            } else {
              showError(t("error"));
            }
          })
          .catch((err) => {
            showError(t("error"));
          });
      } else {
        AxiosInstance.post("/users/login-email", {
          email: gMail,
        })
          .then(() => {
            showSuccess(t("confirm_descMail"));
          })
          .catch(() => {
            showError(t("error"));
          });
      }
    }

    function signIn() {
      if (isPhoneLogin) {
        AxiosInstance.post("/users/login/", {
          phone_number: gPhone,
          fcm_token: "fcm_token",
        })
          .then((response) => {
            if (response.data.token) {
              showSuccess(t("success"));
              props.onClose();
              window.location.reload();
              window.localStorage.setItem("token", response.data.token);
              window.localStorage.setItem(
                "user",
                JSON.stringify(response.data.data.user),
              );
            } else {
              showError(t("error"));
            }
            setL(false);
          })
          .catch((err) => {
            showError(t("error"));
            setL(false);
          });
      }
    }

    function checkCode() {
      if ((isPhoneLogin && code.length === 5) || (!isPhoneLogin && codeFromMail.length === 4)) {
        setL(true);
        if (isPhoneLogin) {
          AxiosInstance.post("/users/verify-code", {
            phone_number: gPhone,
            code: code,
          })
            .then((response) => {
              if (response.data.success) {
                signIn();
              } else {
                setL(false);
                showError(t("error"));
              }
            })
            .catch((err) => {
              setL(false);
              showError(t("error"));
            });
        } else {
          AxiosInstance.post("/users/verify-email", {
            email: gMail,
            code: codeFromMail,
          })
            .then((response) => {
              if (response.data.success) {
                if (loginData) {
                  showSuccess(t("success"));
                  props.onClose();
                  window.localStorage.setItem("token", loginData.token);
                  window.localStorage.setItem(
                    "user",
                    JSON.stringify(loginData.data.user),
                  );
                  window.location.reload();
                }
              } else {
                setL(false);
                showError(t("error"));
              }
            })
            .catch((err) => {
              setL(false);
              showError(t("error"));
            });
        }
      }
    }

    return (
      <Stack
        alignItems={"center"}
        sx={{ width: "100%" }}
        spacing={2}
      >
        <Stack
          direction={"row"}
          sx={{ width: "100%" }}
          justifyContent={"flex-end"}
        >
          <IconButton onClick={props.onClose}>
            <CloseIcon color={"primary"} />
          </IconButton>
        </Stack>
        <LockIcon
          color={"primary"}
          sx={{ fontSize: 80 }}
        />
        <BoldTypography sx={{ fontSize: FontSizes.title }}>
          {t("confirm_code")}
        </BoldTypography>

        <RegularTypography
          sx={{ fontSize: FontSizes.body, textAlign: "center" }}
        >
          {isPhoneLogin ? t("confirm_desc") : t("confirm_descMail")}
        </RegularTypography>

        <TextField
          label={t("code")}
          type={"number"}
          value={isPhoneLogin ? code : codeFromMail}
          onChange={(e) => {
            if (isPhoneLogin)
              setCode(e.target.value)
            else
              setCodeFromMail(e.target.value)
          }}
          helperText={errorMessage}
          variant={"filled"}
          error={errorCode}
          fullWidth
          inputProps={{ maxLength: 5 }}
          sx={{ m: 2 }}
        />

        <CountdownTimer
          targetDate={dateTimeAfterThreeDays}
          click={reSend}
        />

        <LoadingButton
          variant={"contained"}
          disabled={disabled}
          onClick={checkCode}
          loading={l}
          sx={{
            fontSize: FontSizes.button,
            color: "custom.light",
            fontFamily: Fonts.REGULAR,
            textTransform: "none",
            width: "120px",
            mt: 2,
            mb: 2,
          }}
        >
          {t("next")}
        </LoadingButton>
      </Stack>
    );
  }

  return (
    <>
      {
        openReadTermOfUse &&
        <TermOfUseModal
          openReadTermOfUse={openReadTermOfUse}
          setOpenReadTermOfUse={setOpenReadTermOfUse}
        />
      }
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock={true}
      >
        <Box sx={style}>{page === 0 ? <FirstPage /> : <ConfirmPage />}</Box>
      </Modal>
    </>
  );
};

SignIn.propTypes = {
  open: PropsTypes.bool.isRequired,
  onClose: PropsTypes.func.isRequired,
};

export default SignIn;
