import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import { AxiosInstance } from '../../api/AxiosInstance.mjs';
// helpers
import { toast } from 'react-toastify';
// hooks
import { useParams } from 'react-router-dom';
// styles
import styles from './BigCategoryJobs.module.scss';
// components
import MainItemMobile from "../../component/App/MainItemMobile";
import ItemDesktop from "../../component/App/ItemDesktop";
import Loading from '../../component/State/Loading/Loading';


function BigCategoryJobs() {
	const { t, appLanguage, isMobile } = useContext(AppContext);
	const { uuid } = useParams();

	const [data, setData] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);


	useEffect(() => {
		setIsLoading(true)
		AxiosInstance(`public/main-categories/jobs/${uuid}`)
			.then(res => {
				setData(res.data);
			})
			.catch(err => {
				toast.error(t('error'));
				setIsError(true);
			})
			.finally(() => {
				setIsLoading(false)
			});
	}, []);


	if (isLoading)
		return <Loading sx={{ height: "100vh" }} />

	else if (isError)
		return "Error";

	console.log(data);

	return (
		<div className={styles.wrapper}>
			<h1
				className={styles.bigCategoryCard}
			>
				{appLanguage === 'ru' ? data?.category?.nameRu : data?.category?.name}
			</h1>

			<div
				className={styles.category}
			>
				{data?.jobs?.map((item, i) => {
					return (
						<div
							className={styles.category__item}
						>
							{
								isMobile ? (
									<MainItemMobile
										key={`job-mobile-${i}`}
										item={item}
									/>
								) : (
									<ItemDesktop
										key={`job-desktop-${i}`}
										item={item}
									/>
								)
							}
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default BigCategoryJobs;