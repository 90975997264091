import { Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Fonts } from "../../common/fonts.mjs";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Resume from "../../page/Profile/Resume.jsx";
import useGetNotApprovedCV from "../../api/query/useGetNotApprovedCV.js";
import Loading from "../State/Loading/Loading.jsx";
import { AppContext } from "../../App.js";
import useDeleteAnceta from '../../api/mutation/useDeleteAnceta.js';
import { useNavigate } from "react-router-dom";

const ProfileContent = () => {
  const { appLanguage, t } = useContext(AppContext);

  const navigate = useNavigate();

  // query
  const {
    data: notApprovedCV,
    isLoading: loading,
    isError: error
  } = useGetNotApprovedCV();
  // mutation
  const {
    mutate: deleteAnceta,
    isLoading: deletingAnceta
  } = useDeleteAnceta();

  const getName = () => {
    let name = '';
    if (appLanguage == 'ru')
      name = `${notApprovedCV?.quest?.nameRu || ''} ${notApprovedCV?.quest?.surnameRu || ''}`
    else
      name = `${notApprovedCV?.quest?.name || ''} ${notApprovedCV?.quest?.surname || ''}`
    return name;
  }

  return (
    <>

      {
        !error ?
          !loading ?
            <Grid container>
              <Grid item md={6} xs={12}>
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 0.87)",
                    fontFamily: Fonts.REGULAR,
                    fontWeight: "700",
                    fontSize: { md: "28px", xs: "24px" },
                  }}
                >
                  {getName()}
                </Typography>
                <Stack direction="row" spacing={8} mt={2} mb={1}>
                  <img
                    style={{ width: "100px", alignSelf: 'start' }}
                    src={notApprovedCV?.quest?.sex == 2 ?
                      "/images/Woman.png"
                      :
                      "/images/ef0f7e6e-5a0d-4a74-8607-2709d48614a2 1.png"
                    }
                    alt=""
                  />
                  <Stack>
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.87)",
                        fontFamily: Fonts.REGULAR,
                        fontSize: "22px",
                      }}
                    >
                      {
                        appLanguage == 'ru' ?
                          notApprovedCV?.quest?.professionRu
                          :
                          notApprovedCV?.quest?.profession
                      }
                    </Typography>
                    <Stack>
                      <Stack direction="row" alignItems="center">
                        <IconButton>
                          {
                            notApprovedCV?.quest?.sex == 2 ?
                              <FemaleIcon sx={{ fontSize: "16px" }} />
                              :
                              <MaleIcon sx={{ fontSize: "16px" }} />
                          }
                        </IconButton>
                        <Typography
                          sx={{
                            color: "rgba(0, 0, 0, 0.54)",
                            fontFamily: Fonts.REGULAR,
                          }}
                        >
                          {
                            notApprovedCV?.quest?.sex == 1 ?
                              t('male')
                              :
                              notApprovedCV?.quest?.sex == 2 ?
                                t('female')
                                :
                                `${t('male')}/${t('female')}`
                          }
                        </Typography>
                      </Stack>
                      <Stack alignItems="center" direction="row">
                        <IconButton>
                          <LocationOnIcon sx={{ fontSize: "16px" }} />
                        </IconButton>
                        <Typography
                          sx={{
                            color: "rgba(0, 0, 0, 0.54)",
                            fontFamily: Fonts.REGULAR,
                          }}
                        >
                          {
                            appLanguage == 'ru' ?
                              notApprovedCV?.quest?.addressRu
                              :
                              notApprovedCV?.quest?.address
                          }
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
                <br />
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 0.54)",
                    fontFamily: Fonts.REGULAR,
                  }}
                >
                  {
                    appLanguage == 'ru' ?
                      notApprovedCV?.quest?.skillsRu
                      :
                      notApprovedCV?.quest?.skills
                  }
                </Typography>
              </Grid>

              <Grid item md={6} xs={12} sm={12}>
                <Grid container>
                  <Grid item md={8} xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={2}
                      mt={{ md: "17vh", xs: "5vh" }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          fontFamily: Fonts.REGULAR,
                          color: "#FFFFFF",
                          background: "rgba(32, 88, 212, 0.7)",
                        }}
                        onClick={() => navigate('/profile/anceta')}
                      >
                        {t('fixAnceta')}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          fontFamily: Fonts.REGULAR,
                          color: "#FFFFFF",
                          background: "rgba(32, 88, 212, 0.7)",
                        }}
                        onClick={() => deleteAnceta(notApprovedCV?.quest?.uuid)}
                        disabled={deletingAnceta}
                      >
                        {t('delete')}
                      </Button>
                    </Stack>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      mt={{ md: 0, xs: 2 }}
                    >
                      <Typography sx={{ color: "#199F17", fontFamily: Fonts.REGULAR }}>
                        {
                          notApprovedCV?.quest?.status == "PENDING" ?
                            t('pending')
                            :
                            t('active')
                        }
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      mt={{ md: "22vh", xs: 0 }}
                      mb={{ md: 0, xs: 3 }}
                      justifyContent="flex-end"
                    >
                      <Resume />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            :
            <Loading />
          :
          <span style={{
            fontSize: '16px',
            fontFamily: 'Helvetica',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            {t('fillAnceta')}
          </span>
      }
    </>
  );
};

export default ProfileContent;
