import React, { useContext } from 'react'
// components
import { Box, Breadcrumbs, Stack, Typography } from '@mui/material';
import Loading from '../../component/State/Loading/Loading';
import ItemDesktop from '../../component/App/ItemDesktop';
import MainItemMobile from "../../component/App/MainItemMobile";
import { Link } from 'react-router-dom';
// styles
import styles from './SavedJobs.module.scss';
import { Fonts } from '../../common/fonts.mjs';
// context
import { AppContext } from '../../App';
// react-query hooks
import useGetSavedJobs from '../../api/query/useGetSavedJobs';
import useDeleteSavedJob from '../../api/mutation/useDeleteSavedJob';
import BookmarkIcon from '@mui/icons-material/Bookmark';

function SavedJobs() {
   const {
      t,
      isMobile
   } = useContext(AppContext);

   const breadcrumbs = [
      <Link
         underline="hover"
         key="2"
         color="primary"
         sx={{ fontFamily: Fonts.REGULAR, fontSize: "18px", cursor: "pointer" }}
         to="/"
         style={{
            textDecoration: 'none',
            color: 'inherit'
         }}
      >
         {t('home_page')}
      </Link>,
      <Typography
         sx={{ fontSize: "18px" }}
         key="3"
         color="custom.textColor"
      >
         {t('savedJobs')}
      </Typography>,
   ];

   // query
   const {
      data: savedJobs,
      isLoading: loading
   } = useGetSavedJobs();
   // mutations
   const {
      mutate: deleteSavedJob,
      isLoading: deletingSavedJob,
   } = useDeleteSavedJob()

   return (
      <Box sx={{ pt: 3, pb: 3, maxWidth: "100%", width: "100%" }}>
         <Breadcrumbs
            separator="›"
            color={"primary"}
            aria-label="breadcrumb"
            style={{
               marginBottom: '10px'
            }}
         >
            {breadcrumbs}
         </Breadcrumbs>
         {loading ?
            <Stack
               sx={{ width: "100%", height: "70vh" }}
               alignItems={"center"}
               justifyContent={"center"}
            >
               <Loading />
            </Stack>
            :
            savedJobs?.data?.length ?
               <div
                  className={styles.category}
               >
                  {savedJobs?.data?.map((item, i) => {
                     return (
                        <div
                           className={styles.category__item}
                        >
                           {
                              isMobile ? (
                                 <MainItemMobile
                                    key={`job-mobile-${i}`}
                                    item={item}
                                 />
                              ) : (
                                 <ItemDesktop
                                    key={`job-desktop-${i}`}
                                    item={item}
                                 />
                              )
                           }
                        </div>
                     )
                  })}
               </div>
               :
               <div
                  style={{
                     display: 'flex',
                     flexDirection: 'column',
                     justifyContent: 'center',
                     alignItems: 'center',
                     margin: '100px'
                  }}
               >
                  <BookmarkIcon sx={{ fontSize: '300px', color: '#ccc' }} />
                  <h3
                     style={{
                        fontSize: '20px',
                        fontWeight: 'normal',
                        fontFamily: 'Helvetica'
                     }}
                  >{t('noSavedJobs')}</h3>
               </div>
         }
      </Box>
   )
}

export default SavedJobs;
