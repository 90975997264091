import React, { useRef, useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { yupStyle } from "../Jobs/AddJob";
import * as Yup from "yup";
import { FieldArray, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { AppSelect } from "../../component/Common/AppComponent";
import { Fonts } from "../../common/fonts.mjs";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { colors } from "../../common/theme.mjs";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { AxiosInstance } from "../../api/AxiosInstance.mjs";
import { Add, Delete, Remove } from "@mui/icons-material";
import useGetNotApprovedCV from "../../api/query/useGetNotApprovedCV";
import useCreateAnceta from '../../api/mutation/useCreateAnceta';
import useSaveAnceta from '../../api/mutation/useSaveAnceta';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from "dayjs";
import { AppContext } from "../../App";
import { createArrayOfNumbers } from "../../common/utils.mjs";

const stepTitle = {
  color: "rgba(0, 0, 0, 0.54)",
  fontFamily: Fonts.REGULAR,
  fontWeight: "700",
  fontSize: "20px",
};

const nextButton = {
  textTransform: "none",
  fontFamily: Fonts.REGULAR,
  fontWeight: "400",
  color: "#2058D4",
};
const againAddBtn = {
  textTransform: "none",
  fontFamily: Fonts.Regular,
  fontWeight: "400",
  background: "rgba(0, 0, 0, 0.23)",
  color: "#fff",

  "&:hover": {
    background: "rgba(0, 0, 0, 0.23)",
  },
};

// const steps = [
//   {
//     label: "Select campaign settings",
//     description: `For each ad campaign that you create, you can control how much
//               you're willing to spend on clicks and conversions, which networks
//               and geographical locations you want your ads to show on, and more.`,
//   },
//   {
//     label: "Create an ad group",
//     description:
//       "An ad group contains one or more ads which target a shared set of keywords.",
//   },
//   {
//     label: "Create an ad",
//     description: `Try out different ad text to see what brings in the most customers,
//               and learn how to enhance your ads using features like ad extensions.
//               If you run into any problems with your ads, find out how to tell if
//               they're running and how to resolve approval issues.`,
//   },
// ];

const ProfileStepper = () => {
  const { appLanguage } = useContext(AppContext);
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [languageLabelsList, setLanguageLabelList] = useState([]);
  const [languagesList, setlanguagesList] = useState([]);
  const [educationList, seteducationList] = useState([]);
  const navigate = useNavigate();

  const fetcheducationListList = () => {
    AxiosInstance.get("public/get-params").then((response) => {
      seteducationList(response.data.educationList);
      const langLabelList = response.data.educationList;
    });
  };

  useEffect(() => {
    fetcheducationListList();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const {
    data: notApprovedCV
  } = useGetNotApprovedCV();

  //   Yup form
  const validationSchema = Yup.object({
    // shahsy maglumat
    name: Yup.string().required(t("requiredField")),
    surname: Yup.string().required(t("requiredField")),
    profession: Yup.string().required(t("requiredField")),
    sex: Yup.number().required(t('requiredField')),
    birth_date: Yup.string().required(t("requiredField")),

    // habarlashmak
    phone_number: Yup.string().required(t("requiredField")),
    address: Yup.string().required(t("requiredField")),
    e_mail: Yup.string().email(),

    // skills
    skills: Yup.string(),

    // language
    language: Yup.array().of(Yup.object().shape({
      languageId: Yup.number(),
      level: Yup.number()
    })),

    education: Yup.array().of(Yup.object().shape({
      education: Yup.number(),
      country: Yup.string(),
      major: Yup.string(),
      duration: Yup.object().shape({
        start: Yup.string(),
        end: Yup.string()
      }),
      HEI: Yup.string(),
      location: Yup.string(),
    })),
    experience: Yup.array().of(Yup.object().shape({
      office_name: Yup.string(),
      profession: Yup.string(),
      location: Yup.string(),
      duration: Yup.object().shape({
        start: Yup.string(),
        end: Yup.string()
      }),
    })),
    readTermOfUse: Yup.boolean().isTrue(t('requiredField')),
    canBeProcessed: Yup.boolean().isTrue(t('requiredField'))
  });

  const getDuration = (duration) => {
    try {
      const parsed = JSON.parse(duration);
      return {
        start: parsed?.start || '',
        end: parsed?.end || ''
      }
    } catch {
      return {
        start: '',
        end: ''
      }
    }
  }

  const {
    mutate: saveAnceta,
    isLoading: savingAnceta,
  } = useSaveAnceta()
  const {
    mutate: createAnceta,
    isLoading: creatingAnceta,
  } = useCreateAnceta();

  const formik = useFormik({
    enableReinitialize: true,
    initialTouched: {
      name: true,
      surname: true,
      profession: true,
      sex: true,
      birth_date: true,
      phone_number: true,
      address: true,
      e_mail: true,
      skills: true,
      language: true,
      education: true,
      experience: true,
      files: true,
      readTermOfUse: true,
      canBeProcessed: true,
    },
    initialValues: {
      name: notApprovedCV?.quest?.name || '',
      surname: notApprovedCV?.quest?.surname || '',
      profession: notApprovedCV?.quest?.profession || '',
      sex: notApprovedCV?.quest?.sex || '',
      birth_date: notApprovedCV?.quest?.birth_date || '',
      phone_number: notApprovedCV?.quest?.phone_number || '',
      address: notApprovedCV?.quest?.address || '',
      e_mail: notApprovedCV?.quest?.e_mail || '',
      skills: notApprovedCV?.quest?.skills || '',
      language: notApprovedCV?.quest?.quest_language?.map(item => {
        return {
          languageId: item.languageId || '',
          level: item.level || ''
        }
      }) || [
        {
          languageId: '',
          level: ''
        }
      ],
      education: notApprovedCV?.quest.quest_education?.map(item => {
        return {
          education: item.education || '',
          country: item.country || '',
          major: item.major || '',
          duration: getDuration(item.duration),
          HEI: item.HEI || '',
          location: item.location || '',
        }
      }) || [{
        education: '',
        country: '',
        major: '',
        duration: {
          start: '',
          end: ''
        },
        HEI: '',
        location: '',
      }],
      experience: notApprovedCV?.quest?.quest_experience?.map(item => {
        return {
          office_name: item.office_name || '',
          profession: item.profession || '',
          location: item.location || '',
          duration: getDuration(item.duration),
        }
      }) || [
        {
          office_name: '',
          profession: '',
          location: '',
          duration:  {
            start: '',
            end: ''
          },
        }
      ],
      files: [],
      readTermOfUse: false,
      canBeProcessed: false,
    },
    validationSchema, // shorthand used here
    onSubmit: (values, { resetForm }) => {
      const { education, experience, language, ...rest } = values;
      const body = {
        ...rest,
        education: education.map(item => {
          if (item.education)
            return {
              ...item,
              duration: JSON.stringify(item.duration)
            }
          return null
        }).filter(item => item !== null),
        experience: experience.map(item => {
          if (item.office_name)
            return {
              ...item,
              duration: JSON.stringify(item.duration)
            }
          return null
        }).filter(item => item !== null),
        language: language
      }
      if (notApprovedCV)
        saveAnceta(body, {
          onSuccess: () => {
            toast.success(t('success'));
            navigate('/profile');
          },
          onError: () => {
            toast.error(t('error'));
          },
        })
      else
        createAnceta(body, {
          onSuccess: () => {
            toast.success(t('success'));
            navigate('/profile');
          },
          onError: () => {
            toast.error(t('error'));
          },
        })
    },
  });

  const fetchlanguageLabelsList = () => {
    AxiosInstance.get("public/get-params").then((response) => {
      setLanguageLabelList(response.data.languageLabels);
      const langLabelList = response.data.languageLabels;
    });
  };

  useEffect(() => {
    fetchlanguageLabelsList();
  }, []);

  const fetchlanguagesListList = () => {
    AxiosInstance.get("public/get-params").then((response) => {
      setlanguagesList(response.data.languagesList);
      const langsList = response.data.languagesList;
    });
  };

  useEffect(() => {
    fetchlanguagesListList();
  }, []);

  useEffect(() => {
    formik.setFieldTouched('name', true)
  }, [activeStep])

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ maxWidth: 700 }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step sx={{ maxWidth: 450 }}>
              <StepLabel
                error={
                  Boolean(formik.errors.surname) ||
                  Boolean(formik.errors.name) ||
                  Boolean(formik.errors.profession) ||
                  Boolean(formik.errors.birth_date) ||
                  Boolean(formik.errors.sex)
                }
                onMouseDown={() => setActiveStep(0)}
              >
                <Typography sx={stepTitle}>{t('personalData')}</Typography>
              </StepLabel>
              <StepContent>
                <Stack spacing={2}>
                  <TextField
                    sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                    name="surname"
                    label={t('lastName')}
                    id="surname"
                    value={formik.values.surname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik.errors.surname)
                    }
                    helperText={formik.errors.surname}
                    placeholder={t('surname')}
                    type={"text"}
                  />
                  <TextField
                    sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                    name="name"
                    label={t('firstName')}
                    id="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.name)}
                    helperText={formik.errors.name}
                    placeholder={t('firstName')}
                    type={"text"}
                  />
                  <TextField
                    sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                    name="profession"
                    label={t('profession')}
                    id="profession"
                    value={formik.values.profession}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik.errors.profession)
                    }
                    helperText={formik.errors.profession}
                    placeholder={t('profession')}
                    type={"text"}
                  />
                  <FormControl
                    name="sex"
                    value={formik.values.sex}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      sx={{ color: "primary.main" }}
                    >
                      {t('gender')}
                    </FormLabel>
                    <RadioGroup
                      name="sex"
                      value={formik.values.sex}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label={t('male')}
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label={t('female')}
                      />
                    </RadioGroup>
                    {
                      !!(formik.errors.sex) &&
                      <p className="error-field">{formik.errors.sex}</p>
                    }
                  </FormControl>

                  <LocalizationProvider
                    disableScrollLock={true}
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      value={dayjs(formik.values.birth_date, "DD.MM.YYYY")}
                      onChange={(newValue) => {
                        formik.setFieldValue("birth_date", newValue.format('DD.MM.YYYY'));
                      }}
                      format="DD.MM.YYYY"
                      label={t("birthDay")}
                    />
                  </LocalizationProvider>

                </Stack>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Stack direction="row" justifyContent="flex-end">
                      <Button
                        onClick={handleNext}
                        sx={{ ...nextButton, mt: 1, mr: 1 }}
                        endIcon={<ArrowForwardIosIcon sx={{ width: "10px" }} />}
                      >
                        {t('next')}
                      </Button>
                    </Stack>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step sx={{ maxWidth: 450 }}>
              <StepLabel
                error={
                  Boolean(formik.errors.phone_number) ||
                  Boolean(formik.errors.address) ||
                  Boolean(formik.errors.e_mail)
                }
                onMouseDown={() => setActiveStep(1)}
              >
                <Typography sx={stepTitle}>{t('contactData')}</Typography>
              </StepLabel>
              <StepContent>
                <Stack spacing={2}>
                  <TextField
                    sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                    name="phone_number"
                    label={t('phone_number')}
                    id="phone_number"
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik.errors.phone_number)
                    }
                    helperText={
                      formik.errors.phone_number
                    }
                    placeholder={t('phone_number')}
                    type={"number"}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+993</InputAdornment>,
                    }}
                  />
                  <TextField
                    sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                    name="address"
                    label={t('address')}
                    id="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.address)}
                    helperText={formik.errors.address}
                    placeholder={t('address')}
                    type={"text"}
                  />
                  <TextField
                    sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                    name="e_mail"
                    label={t('e_mail')}
                    id="e_mail"
                    value={formik.values.e_mail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.e_mail)}
                    helperText={formik.errors.e_mail}
                    placeholder={t('email')}
                    type={"text"}
                  />
                </Stack>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Stack direction="row" justifyContent="flex-end">
                      <Button
                        onClick={handleBack}
                        sx={{ ...nextButton, mt: 1, mr: 1 }}
                        startIcon={<ArrowBackIosIcon sx={{ width: "10px" }} />}
                        type="button"
                      >
                        {t('back')}
                      </Button>
                      <Button
                        onClick={handleNext}
                        sx={{ ...nextButton, mt: 1, mr: 1 }}
                        endIcon={<ArrowForwardIosIcon sx={{ width: "10px" }} />}
                        type="button"
                      >
                        {t('next')}
                      </Button>
                    </Stack>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step sx={{ maxWidth: 450 }}>
              <StepLabel
                error={Boolean(formik.errors.skills)}
                onMouseDown={() => setActiveStep(2)}
              >
                <Typography sx={stepTitle}>{t('skils')}</Typography>
              </StepLabel>
              <StepContent>
                <Stack spacing={2}>
                  <TextField
                    multiline
                    rows={5}
                    sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                    name="skills"
                    id="skills"
                    value={formik.values.skills}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik.errors.skills)
                    }
                    helperText={formik.errors.skills}
                    placeholder={t('skils')}
                    type={"text"}
                  />
                </Stack>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Stack direction="row" justifyContent="flex-end">
                      <Button
                        onClick={handleBack}
                        sx={{ ...nextButton, mt: 1, mr: 1 }}
                        startIcon={<ArrowBackIosIcon sx={{ width: "10px" }} />}
                        type="button"
                      >
                        {t('back')}
                      </Button>
                      <Button
                        onClick={handleNext}
                        sx={{ ...nextButton, mt: 1, mr: 1 }}
                        endIcon={<ArrowForwardIosIcon sx={{ width: "10px" }} />}
                        type="button"
                      >
                        {t('next')}
                      </Button>
                    </Stack>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel
                onMouseDown={() => setActiveStep(3)}
              >
                <Typography sx={stepTitle}>{t('foreignLanguages')}</Typography>
              </StepLabel>
              <StepContent>
                <Stack spacing={2} direction="column">
                  {
                    formik.values.language && formik.values.language.length > 0 &&
                    formik.values.language.map((language, index) => (
                      <div key={index}>
                        <Stack spacing={2} direction="row">
                          <AppSelect
                            name={`language[${index}].languageId`}
                            value={formik.values.language?.[index]?.languageId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isError={
                              !!(
                                formik.errors.language?.[index]?.languageId)
                            }
                          >
                            <option>{t('selectLanguage')}</option>
                            {languagesList.map((item, i) => {
                              return (
                                <option
                                  key={`language${i}`}
                                  value={item.id}
                                >
                                  {appLanguage == "ru" ? item.nameRu : item.name}
                                </option>
                              );
                            })}
                          </AppSelect>
                          <AppSelect
                            name={`language[${index}].level`}
                            value={formik.values.language?.[index]?.level}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isError={
                              !!(
                                formik.errors.language?.[index]?.level)
                            }
                          >
                            <option>{t('selectLevelOfLanguage')}</option>
                            {languageLabelsList.map((item, i) => {
                              return (
                                <option
                                  key={`level${i}`}
                                  value={item.id}
                                >
                                  {appLanguage == "ru" ? item.nameRu : item.name}
                                </option>
                              );
                            })}
                          </AppSelect>

                        </Stack>
                      </div>
                    ))
                  }
                  <Stack spacing={1} direction='row'>
                    <Button
                      sx={againAddBtn}
                      variant="contained"
                      type="button"
                      onClick={() =>
                        formik.setFieldValue(`language`, [
                          ...formik.values.language,
                          {
                            languageId: '',
                            level: ''
                          }
                        ])
                      }
                    >
                      {t('add')}
                    </Button>
                  </Stack>
                  < Box sx={{ mb: 2 }}>
                    <div>
                      <Stack direction="row" justifyContent="flex-end">
                        <Button
                          onClick={handleBack}
                          sx={{ ...nextButton, mt: 1, mr: 1 }}
                          startIcon={<ArrowBackIosIcon sx={{ width: "10px" }} />}
                          type="button"
                        >
                          {t('back')}
                        </Button>
                        <Button
                          onClick={handleNext}
                          sx={{ ...nextButton, mt: 1, mr: 1 }}
                          endIcon={<ArrowForwardIosIcon sx={{ width: "10px" }} />}
                          type="button"
                        >
                          {t('next')}
                        </Button>
                      </Stack>
                    </div>
                  </Box>
                </Stack>
              </StepContent>
            </Step>
            <Step sx={{ maxWidth: 700 }}>
              <StepLabel
                onMouseDown={() => setActiveStep(4)}
              >
                <Typography sx={stepTitle}>{t('education')}</Typography>
              </StepLabel>
              <StepContent>
                <Stack
                  direction="column"
                  spacing={2}
                >
                  {
                    formik.values.education && formik.values.education.length > 0 &&
                    formik.values.education.map((education, index) => (
                      <div key={index}>
                        <Stack spacing={2} width="100%">
                          <AppSelect
                            name={`education[${index}].education`}
                            value={formik.values.education?.[index]?.education}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value=''>{t('education')}</option>
                            {educationList.map((item, i) => {
                              return (
                                <option
                                  key={`language_label_list_key${i}`}
                                  value={item.id}
                                >
                                  {
                                    appLanguage == "ru" ?
                                      item.nameRu
                                      :
                                      item.name
                                  }
                                </option>
                              );
                            })}
                          </AppSelect>
                          <TextField
                            sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                            name={`education[${index}].major`}
                            label={t('speciality')}
                            id="major"
                            value={formik.values.education?.[index]?.major}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              Boolean(formik.errors.education?.[index]?.major)
                            }
                            helperText={formik.errors.education?.[index]?.major}
                            placeholder={t('speciality')}
                          />
                          <TextField
                            sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                            name={`education[${index}].location`}
                            label={t('location')}
                            id="location"
                            value={formik.values.education?.[index]?.location}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              Boolean(formik.errors.education?.[index]?.location)
                            }
                            helperText={
                              formik.errors.education?.[index]?.location
                            }
                            placeholder={t('location')}
                            type={"text"}
                          />
                          <Stack direction="row" spacing={1}>
                            {/* <LocalizationProvider
                              disableScrollLock={true}
                              dateAdapter={AdapterDayjs}
                            >
                              <DatePicker
                                value={dayjs(formik.values.education?.[index]?.duration?.start, "YYYY")}
                                onChange={(newValue) => {
                                  formik.setFieldValue(
                                    `education[${index}].duration.start`,
                                    newValue.format('YYYY')
                                  );
                                }}
                                format="YYYY"
                                label={t('educationStartDate')}
                                openTo="year"
                                
                              />
                              <DatePicker
                                value={dayjs(formik.values.education?.[index]?.duration?.end, "YYYY")}
                                onChange={(newValue) => {
                                  formik.setFieldValue(
                                    `education[${index}].duration.end`,
                                    newValue.format('YYYY')
                                  );
                                }}
                                format="YYYY"
                                label={t('educationEndDate')}
                                openTo="year"
                              />
                            </LocalizationProvider> */}


                            <AppSelect
                              name={`education[${index}].duration.start`}
                              value={dayjs(formik.values.education?.[index]?.duration?.start, "YYYY").format("YYYY")}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option value=''>{t('educationStartDate')}</option>
                              {createArrayOfNumbers(1900, dayjs().format("YYYY")).map((item, i) => {
                                return (
                                  <option
                                    key={`educationStartDate${i}`}
                                    value={item}
                                  >
                                    {item}
                                  </option>
                                );
                              })}
                            </AppSelect>
                            <AppSelect
                              name={`education[${index}].duration.end`}
                              value={dayjs(formik.values.education?.[index]?.duration?.end, "YYYY").format("YYYY")}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option value=''>{t('educationEndDate')}</option>
                              {createArrayOfNumbers(1900, dayjs().format("YYYY")).map((item, i) => {
                                return (
                                  <option
                                    key={`educationEndDate${i}`}
                                    value={item}
                                  >
                                    {item}
                                  </option>
                                );
                              })}
                              <option
                                value={dayjs().format("YYYY")}
                              >
                                {
                                  t('sinceToday')
                                }
                              </option>
                            </AppSelect>

                            {/* <TextField
                              sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                              name={`education[${index}].duration.start`}
                              label={t('educationStartDate')}
                              id="startDate"
                              value={formik.values.education?.[index]?.duration?.start}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                Boolean(formik.errors.education?.[index]?.duration?.start)
                              }
                              helperText={
                                formik.errors.education?.[index]?.duration?.start
                              }
                              placeholder={t('educationStartDate')}
                              type="text"
                            /> 
                            <TextField
                              sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                              name={`education[${index}].duration.end`}
                              label={t('educationEndDate')}
                              id="endDate"
                              value={formik.values.education?.[index]?.duration?.end}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                Boolean(formik.errors.education?.[index]?.duration?.end)
                              }
                              helperText={
                                formik.errors.education?.[index]?.duration?.end
                              }
                              placeholder={t('educationEndDate')}
                              type={"text"}
                            />*/}
                          </Stack>
                          <Stack width="100%">
                            <TextField
                              multiline
                              rows={5}
                              sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                              name={`education[${index}].HEI`}
                              label={t('schoolName')}
                              id="HEI"
                              value={formik.values.education?.[index]?.HEI}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                Boolean(formik.errors.education?.[index]?.HEI)
                              }
                              helperText={
                                formik.errors.education?.[index]?.HEI
                              }
                              placeholder={t('schoolName')}
                              type={"text"}
                            />
                          </Stack>
                        </Stack>
                      </div>
                    ))
                  }
                  <Button
                    sx={againAddBtn}
                    variant="contained"
                    type="button"
                    onClick={() => {
                      formik.setFieldValue('education', [
                        ...formik.values.education,
                        {
                          education: '',
                          country: '',
                          major: '',
                          duration: {
                            start: '',
                            end: ''
                          },
                          HEI: '',
                          location: '',
                        }
                      ])
                    }}
                  >
                    {t('add')}
                  </Button>
                </Stack>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Stack direction="row" justifyContent="flex-end">
                      <Button
                        onClick={handleBack}
                        sx={{ ...nextButton, mt: 1, mr: 1 }}
                        startIcon={<ArrowBackIosIcon sx={{ width: "10px" }} />}
                        type="button"
                      >
                        {t('back')}
                      </Button>
                      <Button
                        onClick={handleNext}
                        sx={{ ...nextButton, mt: 1, mr: 1 }}
                        endIcon={<ArrowForwardIosIcon sx={{ width: "10px" }} />}
                        type="button"
                      >
                        {t('next')}
                      </Button>
                    </Stack>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step sx={{ maxWidth: 450 }}>
              <StepLabel
                onMouseDown={() => setActiveStep(5)}
              >
                <Typography sx={stepTitle}>{t('experience')}</Typography>
              </StepLabel>
              <StepContent>
                <Stack spacing={2}>
                  {
                    formik.values.experience && formik.values.experience.length > 0 &&
                    formik.values.experience.map((education, index) => (
                      <div key={index}>
                        <Stack spacing={2} width="100%">
                          <TextField
                            sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                            name={`experience[${index}].office_name`}
                            label={t('organizationName')}
                            id="office_name"
                            value={formik.values.experience?.[index]?.office_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              Boolean(formik.errors.experience?.[index]?.office_name)
                            }
                            helperText={
                              formik.errors.experience?.[index]?.office_name
                            }
                            placeholder={t('organizationName')}
                            type={"text"}
                          />
                          <TextField
                            sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                            name={`experience[${index}].profession`}
                            label={t('jobTitle')}
                            id="profession"
                            value={formik.values.experience?.[index]?.profession}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.errors.experience?.[index]?.profession)}
                            helperText={formik.errors.experience?.[index]?.profession}
                            placeholder={t('jobTitle')}
                            type={"text"}
                          />
                          <TextField
                            sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                            name={`experience[${index}].location`}
                            label={t('location')}
                            id="location"
                            value={formik.values.experience?.[index]?.location}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              Boolean(formik.errors.experience?.[index]?.location)
                            }
                            helperText={formik.errors.experience?.[index]?.location}
                            placeholder={t('location')}
                            type={"text"}
                          />
                          <Stack direction="row" spacing={1}>

                            {/* <LocalizationProvider
                              disableScrollLock={true}
                              dateAdapter={AdapterDayjs}
                            >
                              <DatePicker
                                value={dayjs(formik.values.experience?.[index]?.duration?.start, "YYYY")}
                                onChange={(newValue) => {
                                  formik.setFieldValue(
                                    `experience[${index}].duration.start`,
                                    newValue.format('YYYY')
                                  );
                                }}
                                format="YYYY"
                                label={t('jobStartDate')}
                                openTo="year"
                              />
                              <DatePicker
                                value={dayjs(formik.values.experience?.[index]?.duration?.end, "YYYY")}
                                onChange={(newValue) => {
                                  formik.setFieldValue(
                                    `experience[${index}].duration.end`,
                                    newValue.format('YYYY')
                                  );
                                }}
                                format="YYYY"
                                label={t('jobEndDate')}
                                openTo="year"
                              />
                            </LocalizationProvider> */}
                            <AppSelect
                              name={`experience[${index}].duration.start`}
                              value={dayjs(formik.values.experience?.[index]?.duration?.start, "YYYY").format("YYYY")}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option value=''>{t('jobStartDate')}</option>
                              {createArrayOfNumbers(1900, dayjs().format("YYYY")).map((item, i) => {
                                return (
                                  <option
                                    key={`jobStartDate${i}`}
                                    value={item}
                                  >
                                    {item}
                                  </option>
                                );
                              })}
                            </AppSelect>
                            <AppSelect
                              name={`experience[${index}].duration.end`}
                              value={dayjs(formik.values.experience?.[index]?.duration?.end, "YYYY").format("YYYY")}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option value=''>{t('jobEndDate')}</option>
                              {createArrayOfNumbers(1900, dayjs().format("YYYY")).map((item, i) => {
                                return (
                                  <option
                                    key={`jobEndDate${i}`}
                                    value={item}
                                  >
                                    {item}
                                  </option>
                                );
                              })}
                              <option
                                value={dayjs().format("YYYY")}
                              >
                                {
                                  t('sinceToday')
                                }
                              </option>
                            </AppSelect>
                            {/* <TextField
                              sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                              name={`experience[${index}].duration.start`}
                              label={t('jobStartDate')}
                              id="startDate"
                              value={formik.values.experience?.[index]?.duration?.start}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                Boolean(formik.errors.experience?.[index]?.duration?.start)
                              }
                              helperText={
                                formik.errors.experience?.[index]?.duration?.start
                              }
                              placeholder={t('jobStartDate')}
                              type={"text"}
                            />
                            <TextField
                              sx={{ ...yupStyle, "& fieldset": { border: "none" } }}
                              name={`experience[${index}].duration.end`}
                              label={t('jobEndDate')}
                              id="endDate"
                              value={formik.values.experience?.[index]?.duration?.end}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                Boolean(formik.errors.experience?.[index]?.duration?.end)
                              }
                              helperText={formik.errors.experience?.[index]?.duration?.end}
                              placeholder={t('jobEndDate')}
                              type={"text"}
                            /> */}
                          </Stack>
                        </Stack>
                      </div>
                    ))
                  }

                  <Button
                    sx={againAddBtn}
                    variant="contained"
                    type="button"
                    onClick={() => {
                      formik.setFieldValue('experience', [
                        ...formik.values.experience,
                        {
                          office_name: '',
                          profession: '',
                          location: '',
                          duration: {
                            start: '',
                            end: ''
                          },
                        }
                      ])
                    }}
                  >
                    {t('add')}
                  </Button>
                </Stack>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Stack direction="row" justifyContent="flex-end">
                      <Button
                        onClick={handleBack}
                        sx={{ ...nextButton, mt: 1, mr: 1 }}
                        startIcon={<ArrowBackIosIcon sx={{ width: "10px" }} />}
                        type="button"
                      >
                        {t('back')}
                      </Button>
                      <Button
                        onClick={handleNext}
                        sx={{ ...nextButton, mt: 1, mr: 1 }}
                        endIcon={<ArrowForwardIosIcon sx={{ width: "10px" }} />}
                        type="button"
                      >
                        {t('next')}
                      </Button>
                    </Stack>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step sx={{ maxWidth: 450 }}>
              <StepLabel
                onMouseDown={() => setActiveStep(6)}
              >
                <Typography sx={stepTitle}>{t('diplomaAndCertification')}</Typography>
              </StepLabel>
              <StepContent>
                <Stack spacing={2} mt={3}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px'
                    }}
                  >
                    {
                      formik.values?.files?.map((item) => {
                        return (
                          <div style={{
                            border: `1px solid #E1E1E1`,
                            padding: '10px',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}>
                            <span
                              style={{
                                maxWidth: '250px',
                              }}
                              className="oneLine"
                            >
                              {item.name}
                            </span>
                            <IconButton
                              onClick={() => {
                                formik.setFieldValue(
                                  'files',
                                  formik.values.files.filter(
                                    file => file.name !== item.name
                                  )
                                )
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </div>
                        )
                      })
                    }
                  </div>
                  <Box
                    p={1}
                    pl={2}
                    pr={2}
                    sx={{
                      background: "rgba(32, 88, 212, 0.05)",
                      boxShadow: "0px 0px 4px #000000",
                      borderRadius: "5px",
                      cursor: "pointer",
                      position: 'relative'
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <IconButton>
                        <AddAPhotoIcon />
                      </IconButton>
                      <input
                        id="file"
                        type="file"
                        style={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          opacity: 0,
                          cursor: 'pointer'
                        }}
                        name="files"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "files",
                            [
                              ...formik.values.files,
                              event.currentTarget.files[0]
                            ]
                          );
                        }}
                      />
                    </Stack>
                  </Box>
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                  >
                    <FormControlLabel
                      checked={formik.values.readTermOfUse}
                      name="readTermOfUse"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      control={
                        <Checkbox />
                      }
                      label={t("accept_use_terms")}
                    />
                    {
                      <p className="error-field">{formik.errors.readTermOfUse}</p>
                    }
                  </Stack>
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                  >
                    <FormControlLabel
                      checked={formik.values.canBeProcessed}
                      name="canBeProcessed"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      control={
                        <Checkbox />
                      }
                      label={t("canBeProcessed")}
                    />
                    {
                      <p className="error-field">{formik.errors.canBeProcessed}</p>
                    }
                  </Stack>
                </Stack>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Stack mt={3}>
                      <Button
                        variant="contained"
                        sx={{
                          background: " rgba(32, 88, 212, 0.7)",
                          color: "#fff",
                        }}
                        type="submit"
                        disabled={savingAnceta || creatingAnceta}
                      >
                        {t('createAnceta')}
                      </Button>
                    </Stack>
                  </div>
                  <Stack direction="row" justifyContent="flex-end">
                    <Button
                      onClick={handleBack}
                      sx={{ ...nextButton, mt: 1, mr: 1 }}
                      startIcon={<ArrowBackIosIcon sx={{ width: "10px" }} />}
                      type="button"
                    >
                      {t('back')}
                    </Button>
                  </Stack>
                </Box>
              </StepContent>
            </Step>
          </Stepper>
          {/* {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>All steps completed - you&apos;re finished</Typography>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                {t('reset')}
              </Button>
            </Paper>
          )} */}
        </Box>
      </form >
    </>
  );
};

export default ProfileStepper;
