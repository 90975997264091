import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddJobCard from "./AddJobCard";
import ItemDesktop from "../../component/App/ItemDesktop";
import MainItemMobile from "../../component/App/MainItemMobile";
import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { AxiosInstance } from "../../api/AxiosInstance.mjs";
import { Fonts } from "../../common/fonts.mjs";
import { colors } from "../../common/theme.mjs";
// styles
import styles from './SavedJobs.module.scss';
// query
import useGetMyJobs from '../../api/query/useGetMyJobs';

export const yupStyle = {
	width: "100%",
	backgroundColor: `${colors.NOT_ACTIVE_BLUE}`,
	color: `${colors.TEXT_COLOR}`,
	outline: "none",
	fontSize: "18px",
	borderRadius: "5px",
	fontfamily: `${Fonts.REGULAR}`,
};

const AddJob = (props) => {
	const navigate = useNavigate();
	const { isMobile } = useContext(AppContext);

	const { t } = useTranslation();

	function changeRoute(path) {
		navigate(path);
	}

	const {
		data: jobs,
	} = useGetMyJobs();

	return (
		<Box sx={{ maxWidth: "100%", width: "100%" }}>
			<Box sx={{ mt: 12, mb: 4 }} />

			<Stack direction="row" mt={3} justifyContent="flex-end">
				<Button
					onClick={() => changeRoute("/add-job-new-card")}
					variant="contained"
					sx={{
						textTransform: "none",
						fontFamily: Fonts.BOLD,
						fontWeight: 'bold',
						zIndex: 0,
						color: "#fff",
						fontSize: '16px'
					}}
					startIcon={<AddCircleOutlineIcon />}
				>
					{t("putVacancy")}
				</Button>
			</Stack>


			{
				jobs?.length ?

					<div
						className={styles.category}
					>
						{jobs?.map((item, i) => {
							return (
								<div
									className={styles.category__item}
								>
									{
										isMobile ? (
											<MainItemMobile
												key={`job-mobile-${i}`}
												item={item}
											/>
										) : (
											<ItemDesktop
												key={`job-desktop-${i}`}
												item={item}
											/>
										)
									}
								</div>
							)
						})}
					</div>
					:
					<div
						style={{
							display: 'flex',
							justifyContent:'center',
							marginTop: '50px'
						}}
					>
						<img 
							src="/images/add_job.svg"
							style={{
								width: '100%',
								maxWidth: '600px'
							}}
						 />
					</div>
			}
		</Box>
	);
};

export default AddJob;
