import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "../AxiosInstance.mjs";

const useGetSavedJobs = () => {
	return useQuery(
		["savedList"],
		() => AxiosInstance.get(`users/favorites/job`).then(res => res.data),
		{
			enabled: !!window.localStorage.getItem("token")
		}
	);
};

export default useGetSavedJobs;
